<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
export default {
name: "BaseMain"
}
</script>

<style scoped>

</style>