const BEM_SEPARATOR = {
    element: '__',
    modifier: '--'
}

class Bem {
    static formElementName(block, element) {
        return block + BEM_SEPARATOR.element + element
    }

    static nameWithModifier(name, modifier) {
        return name + BEM_SEPARATOR.modifier + modifier
    }

    static formClassObject(name, modifiers = {}) {
        const modifiersEntries = Object.entries(modifiers)
        const result = { [name]: true }

        return modifiersEntries.reduce((result, modifier) => {
            const [modifierName, modifierValue] = modifier
            const className = Bem.nameWithModifier(name, modifierName)
            result[className] = modifierValue

            return result
        }, result )
    }
}

const bemMixin = {
    computed: {
        __blockName() {
            return this.$options.blockName
        }
    },
    methods: {
        b(modifiers) {
            const name = this.__blockName
            return Bem.formClassObject(name, modifiers)
        },

        e(name, modifiers) {
            name = Bem.formElementName(this.__blockName, name)
            return Bem.formClassObject(name, modifiers)
        }
    }
}

export default bemMixin