<template>
  <div class="case" v-if="isCaseLoaded">
    <div class="page-section-wrapper case-content ">
      <div class="page-section-inner case-content-inner ">
        <div class="case-header">
          <div class="case-header-image">
            <img :src="curCase.generic.photo" alt="">
          </div>
          <h1 class="case-header-title">{{getTranslateFromItem(curCase.translation, 'title')}}</h1>
        </div>
        <div class="case-comment">
          {{getTranslateFromItem(curCase.translation, 'comment')}}
        </div>
        <div class="case-manager">
          <div class="case-manager-name">
            {{getTranslateFromItem(curCase.translation, 'manager')}}
          </div>
          <div class="case-manager-position">
            {{getTranslateFromItem(curCase.translation, 'position')}}
          </div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper" id="hotel-section-wrapper">
      <div class="page-section-inner" id="hotel-section">
        <div id="hotel-section-image">
          <img id="hotel-section-image-inner" :src="curCase.generic.hotel_image"  alt="">
        </div>
        <div id="hotel-section-info">
          <div v-if="lang === 'ru'" id="hotel-section-info-title">
            Особенности нашего Отеля
          </div>
          <div v-if="lang === 'en'" id="hotel-section-info-title">
            Features of our hotel
          </div>
          <div id="hotel-section-info-description">
            {{getTranslateFromItem(curCase.translation, 'desc')}}
          </div>
        </div>
      </div>
    </div>
    <div class="case">
      <div class="page-section-wrapper case-content ">
        <div class="page-section-inner case-content-inner ">
          
            <div class="tree">
              <div class="descOne">
                <div class="descOne-title">
                  {{getTranslateFromItem(curCase.translation, 'result_one_title')}}
                </div>
                {{getTranslateFromItem(curCase.translation, 'result_one_desc')}}
              </div>
              <div class="descTwo">
                <div class="descTwo-title">
                  {{getTranslateFromItem(curCase.translation, 'result_two_title')}}
                </div>
                {{getTranslateFromItem(curCase.translation, 'result_two_desc')}}
              </div>
            </div>
            <div class="descThree">
              <div class="descThree-title">
                {{getTranslateFromItem(curCase.translation, 'result_three_title')}}
              </div>
              <div class="descThree-desc">
                {{getTranslateFromItem(curCase.translation, 'result_three_desc')}}
              </div>
            </div>
            <div class="case-content-inner-results">
              <div class="case-content-inner-results-title">
                {{getTranslateFromItem(curCase.translation, 'gif_title')}}
              </div>
            </div>
          <div class="case-content-inner-text" v-html="description"></div>
          <div class="bottom">
            <div class="bottom-demo">
              <router-link :to="{name: 'Demo',}">
                <BaseButton filled>
                  {{getTranslate('buttonForADemo')}}
                </BaseButton>
              </router-link>
            </div>
              <div class="bottom-description">
                  {{getTranslate('underButtonPhrase')}}
              </div>
          </div>
        </div>
      </div>
      <div class="page-section-wrapper" v-if="caseId === 10">
        <div class="page-section-inner">
          <div class="page-section-title-center">
              {{getTranslate('demoCallToActionTitle')}}
          </div>
          <div id="hubspotForm-wrapper">
            <div id="hubspotForm" ref="hubspot-form1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Case",
  watch: {
    lang() {
      this.appendScript();
    },
  },
  computed: {
    ...mapGetters([
      'getTranslate',
      'getTranslateFromItem',
      'lang',
      'cases',
      'getCaseById'
    ]),
    curCase() {
      return this.getCaseById(this.$route.params.id)[0];
    },
    description() {
      return this.getTranslateFromItem(this.curCase.translation, 'description');
    }
  },
  components: {
    BaseButton,
  },
  mounted() {
    if (this.caseId === 10) {
      this.appendScript();
    }
  },
  methods: {
    appendScript() {
      let formId = this.lang === 'ru'
          ? "0a9d92ca-7d25-45ae-9f24-5d8160db10b9"
          : "cfc3ee0b-7825-48d2-a73a-3bee2e2be5e5"
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId,
            target: '#hubspotForm',
          });
        }
      })
    },
  },
  created() {
    if (this.cases.filter(e => +e.id === +this.$route.params.id).length !== 0) {
      this.isCaseLoaded = true;
    } else {
      this.$router.push({name: 'Home',})
    }
  },
  data() {
    return {
      caseId: +this.$route.params.id,
      isCaseLoaded: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.tree {
  display: flex;
  justify-content: space-between;

  background-repeat: no-repeat;
  background-size: 18% 100%;
  background-position: center;
  background-image: url("../assets/images/case/tree.png") ;
  
  @media (max-width: $breakpoint-tablet) {
    background-image: none;
    display: block;
  }

}

.descOne {
  position: relative;
  top: 0;
  left: 0;
  width: 30%;
  margin-top: 45px;
  padding-left: 60px;
  line-height: 30px;
  color: #2F3238;

  &-title {
    font-size: 38px;
    line-height: 55px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  
  @media (max-width: $breakpoint-desktop) {
    &-title {
      margin-top: 20px;
      font-size: 32px;
      line-height: 50px;
    }

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      padding: 20px 150px 15px 15px;
  
      &-title {
        font-size: 32px;
        line-height: 42px;
        color: rgba(127,126,186,255);
      }
    }
  }
}

.descTwo {
  width: 32%;
  padding-right: 5%;
  margin-top: 9%;
  line-height: 30px;
  color: #2F3238;

  &-title {
    font-size: 38px;
    line-height: 55px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  @media (max-width: $breakpoint-desktop) {
    margin-top: 13%;

    &-title {
      font-size: 32px;
      line-height: 50px;
    }
    
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      padding: 20px 150px 15px 15px;
  
      &-title {
        font-size: 32px;
        line-height: 42px;
        color: rgba(127,126,186,255);
      }
    }
  }
}

.descThree {
  width: 35%;
  padding-top: 10px;
  padding-left: 40px;
  margin: auto;
  margin-top: 25px;
  line-height: 30px;
  color: #2F3238;
  

  &-title {
    font-size: 38px;
    line-height: 55px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  @media (max-width: $breakpoint-desktop) {
    &-title {
      font-size: 32px;
      line-height: 50px;
    }
    
    @media (max-width: $breakpoint-tablet) { 
      width: 100%;
      padding: 20px 150px 15px 15px;
      margin-top: 90px;
  
      &-title {
        font-size: 32px;
        line-height: 42px;
        color: rgba(127,126,186,255);
      }
    }
  }
 
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  &-demo {
    width: 210px;
  }

  &-description {
    color: #2F3238;
    font-weight: 500;
    width: 50%;
    align-items: center;
    font-size: 32px;
    text-align: center;
    line-height: 52px;
    padding-top: 20px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 20px;
      line-height: 28px;

      @media (max-width: $breakpoint-tablet-v) {
        font-size: 20px;
        line-height: 28px;

        @media (max-width: $breakpoint-mobile) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}

#hotel-section-wrapper {
  background-color: $primary;
  color: white;

  #hotel-section {
    display: flex;
    align-items: center;
    padding-left: 10%;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      padding: 20px 10px;
      background-color: rgba(0, 0, 0, 0.03);
      border-radius: 20px;
    }

    &-image {
      background-repeat: no-repeat;
      background-size: contain;
      width: 50%;
      align-self: center;
      overflow: hidden;
      box-sizing: border-box;
      max-width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: calc((6/20) * 100%);
      position: relative;

      @media (max-width: $breakpoint-desktop) {

        @media (max-width: $breakpoint-tablet) {

          @media (max-width: $breakpoint-tablet) {
            align-self: center;
            padding: 0;
            max-width: 100%;
            width: 200px;
            height: 200px;
            border-radius: 600px;
            margin-bottom: 10px;

            @media (max-width: $breakpoint-mobile) {
              margin-bottom: 20px;
              width: 150px;
              height: 150px;
            }
          }
        }
      }

      &-inner {
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    &-info {
      width: 40%;
      padding-left: 30px;
      transition: .3s;
      box-sizing: border-box;

      @media (max-width: $breakpoint-desktop) {
        padding-left: 60px;

        @media (max-width: $breakpoint-tablet) {
          padding-left: 40px;

          @media (max-width: $breakpoint-tablet) {
            padding: 20px;
            width: 100%;

            @media (max-width: $breakpoint-mobile) {
              padding: 0;
            }
          }
        }
      }

      &-title {
        font-weight: 900;
        font-size: 38px;
        margin-bottom: 20px;
      }

      &-description {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        box-sizing: border-box;
        font-weight: 400;

        @media (max-width: $breakpoint-desktop) {
          font-size: 16px;
          line-height: 24px;
          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
            line-height: 24px;
          }
        }

      }
    }
  }
}


#case-loading-failed {
  width: 100%;
  min-height: 300px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-description {
    color: #25272B;
    font-weight: 400;
    opacity: .5;
    max-width: 200px;
    text-align: center;
    margin: 10px 0 20px;
    font-size: 20px;
  }

  svg {
    margin-top: -30px;
    opacity: .5;
    width: 50px;
    height: 50px;
    fill: #25272B;
  }
}

.case-header {
  padding: 0 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;

  &-image {
    width: 250px;
    height: 200px;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;

    @media (max-width: $breakpoint-tablet-v) {
      width: 150px;
      height: 100px;
      margin-bottom: 20px;
    }

    img {
      width: 100%;
    }
  }

  &-title {
    font-size: 38px;
    line-height: 56px;
    max-width: 700px;
    text-align: center;
    font-weight: 700;
    transition: .3s;
    color: #25272B;
    margin-bottom: 40px;

    @media (max-width: $breakpoint-desktop) {
      margin-bottom: 25px;
      font-size: 34px;
      max-width: 600px;
      line-height: 46px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 30px;
        line-height: 36px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 20px;

          @media (max-width: $breakpoint-mobile) {
            margin-bottom: 15px;
            text-align: center;
            max-width: 380px;
          }
        }
      }
    }
  }
}

.case-comment {
  font-style: italic;
  margin-bottom: 50px;
  padding: 0px 60px;
}

.case-manager {
  padding: 0px 60px;
  
  &-name {
    color: rgba(127,126,186,255);
    font-weight: 700;
    font-size: 24px;
  }

  &-position {
    margin-top: 20px;

  }
}

.case-content {
  padding: 60px $padding-edge-large-desktop 50px;

  @media (max-width: $breakpoint-desktop) {
    padding: 60px $padding-edge-desktop 60px;

    @media (max-width: $breakpoint-tablet) {
      padding: 40px $padding-edge-tablet 60px;

      @media (max-width: $breakpoint-tablet-v) {
        padding: 20px $padding-edge-tablet 40px;
      }
    }
  }

  &-inner {
    font-size: 20px;
    line-height: 26px;

    @media (max-width: $breakpoint-tablet-v) {
      font-size: 18px;
      line-height: 24px;
    }

    &-results {
      padding-top: 100px;

      &-title {
        font-size: 38px;
        line-height: 40px;
        text-align: center;
        font-weight: 700;
        color: #2F3238;
        margin-bottom: 40px;
      }
    }
  }
}
</style>
