<template>
  <!--  TODO: 'САБЛИНКС ДРОПДАУН'-->
  <div
      @click="goTo(to)"
      class="menu-link-mobile-wrapper"
  >
    <div class="menu-link-mobile">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuLinkMobile",
  props: {
    to: {
      type: Object,
      required: true,
    },
    goTo: {
      type: Function,
      required: true,
    },
    sublinks: Array,
  },
}
</script>

<style scoped>
.menu-link-mobile-wrapper {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  color: black;
}

.menu-link-mobile {
  color: black;
}
</style>