<template>
  <div :class="b({ rtl })">
    <div :class="e('info', { light })">
      <h2
        :class="e('info-title')"
        v-text="title"
      />
      <div
        v-if="description"
        :class="e('info-description')"
        v-text="description"
      />
    </div>
    <div
      :class="e('image', {
        circle: circleImage,
        cover: imageCover,
        'large-image': largeImage
      })"
    >
      <img
        :class="e('image-source')"
        :src="imageUrl"
        alt="image"
      >
    </div>
  </div>
</template>

<script>
import {bemMixin} from '@/mixins'

export default {
  name: "ImageWithInfo",
  blockName: 'image-with-info',
  mixins: [bemMixin],
  props: {
    // * DATA *
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    imageUrl: {
      type: String,
      required: true
    },
    // * MODIFIERS *
    // Right to left
    rtl: {
      type: Boolean,
      default: false
    },
    // White text
    light: {
      type: Boolean,
      default: false
    },
    circleImage: {
      type: Boolean,
      default: false,
    },
    imageCover: {
      type: Boolean,
      default: false,
    },
    largeImage: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
@mixin aspect-ratio($width, $height, $inner) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    display: block;
    content: "";
    color: $inner;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  #{$inner} {
    position: absolute;
  }
}

.image-with-info {
  $block: &;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: 64px;

  &__info {
    $info: &;

    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 45%;
    gap: 48px;

    &-title {
      margin: 0;
      line-height: 1.25;

      font: {
        size: 44px;
        weight: bold;
      }

      color: $primary;

      @include adaptiveValue(
          font-size,
          38px,
          36px,
          32px,
          32px,
          28px
      );
    }

    &-text {
      line-height: 1.5;
    }

    &--light {
      color: white;

      #{$info}-title {
        color: white;
      }
    }

    @include adaptiveTabletV() {
      gap: 12px;
      text-align: center;
    }
  }

  &__image {
    $image: &;
    flex: 0 0 45%;
    overflow: hidden;
    @include aspect-ratio(16, 10, '.image-with-info__image-source');

    &-source {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &--cover {
      #{$image}-source {
        object-fit: cover;
      }
    }

    &--large-image {
      flex: 0 0 55%;
    }

    &--circle {
      max-width: 300px;
      border-radius: 50%;
      @include aspect-ratio(1, 1, '.image-with-info__image-source');

      @include adaptiveTabletV() {
        max-width: 150px;
      }
    }
  }

  @include adaptiveTabletV() {
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 auto;
    gap: 32px;
    width: 80%;

    &__info, &__image {
      width: 100%;
    }

    @include adaptiveMobile() {
      width: 100%;
    }
  }

  &--rtl {
    flex-direction: row-reverse;

    @include adaptiveTabletV() {
      flex-direction: column-reverse;
    }
  }
}
</style>
