<template>
  <div id="page-404">
    <div id="page-404-title">404</div>
    <div id="page-404-subtitle">{{ getTranslate('notFoundSubtitle') }}</div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NotFound",
  computed: {
    ...mapGetters([
        'getTranslate'
    ]),
  },
}
</script>

<style lang="scss" scoped>
#page-404 {
  display: flex;
  width: 100%;
  flex-grow: 1;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  color: #5d5ca8;
  padding: 20px;
  
  &-title {
    font-size: 160px;
    line-height: 160px;
    font-weight: 900;
    margin-bottom: 10px;
    
    @media (max-width: $breakpoint-mobile) {
      font-size: 100px;
      line-height: 100px;
    }
  }
  
  &-subtitle {
    color: black;
    font-weight: 500;
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
  }
}
</style>