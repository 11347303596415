var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"pricing"}},[_c('div',{staticClass:"page-section-wrapper"},[_c('div',{staticClass:"page-section-inner"},[_c('div',{staticClass:"pricing-section-title page-section-title-center",attrs:{"id":"pricing-summary"}},[_vm._v("Select your hotellab pricing plan for better performance")]),_c('div',{staticClass:"pricing-grid"},_vm._l((_vm.tariffs),function(e){return _c('div',{key:e.id,staticClass:"pricing-grid-item"},[(e.id === 1)?_c('div',{staticClass:"pricing-grid-item-badge"},[_vm._v("Most popular")]):_vm._e(),_c('div',{staticClass:"pricing-grid-item-info"},[_c('div',{staticClass:"pricing-grid-item-desc-title"},[_vm._v(" "+_vm._s(e.title)+" ")]),_c('div',{staticClass:"pricing-grid-item-desc-text"},[_vm._v(" "+_vm._s(e.description)+" ")])]),_c('div',{staticClass:"pricing-grid-item-desc"},[_c('div',{staticClass:"pricing-grid-item-desc-price"},[_vm._v(" from "),_c('span',{staticClass:"pricing-grid-item-desc-price-value"},[_vm._v("€"+_vm._s(e.generic.price_from))]),_vm._v(" / month ")]),_c('div',{staticClass:"pricing-grid-item-desc-more"},[_c('router-link',{attrs:{"to":{name: 'Demo',}}},[_c('BaseButton',[_vm._v(" Select ")])],1)],1),_c('div',{staticClass:"pricing-grid-item-desc-setup"},[_vm._v(" Setup fee from €"+_vm._s(e.generic.setup_fee)+" ")])])])}),0)])]),_c('div',{staticClass:"page-section-wrapper"},[_c('div',{staticClass:"page-section-inner"},[_c('div',{staticClass:"page-section-title-center"},[_vm._v("Compare the plans")]),_vm._l(([
          { id: 1, label: 'Included in pricing plan' },
          { id: 2, label: 'Can be added to pricing plan at an extra charge' },
        ]),function(item){return _c('div',{key:item.id,staticClass:"pricing-legend-compare"},[_c('div',{staticClass:"pricing-legend-compare-wrapper"},[_c('img',{attrs:{"width":"30px","src":require("@/assets/icons/checkmark.svg"),"alt":"checkmark"}}),(item.id === 2)?_c('span',{staticClass:"pricing-legend-compare-item-additional"},[_vm._v("+")]):_vm._e()]),_c('span',{staticClass:"pricing-legend-compare-label"},[_vm._v(_vm._s(item.label))])])}),_c('div',{staticClass:"pricing-above-compare"},[_c('button',{staticClass:"pricing-above-compare-button",class:{
            'pricing-above-compare-button__open': _vm.isCollapseAll,
          },on:{"click":function($event){return _vm.changeVisibility('all', null, null, !_vm.isCollapseAll)}}},[_vm._v(" "+_vm._s(_vm.isCollapseAll ? 'Collapse all' : 'Expand all')+" ")])]),_c('div',{staticClass:"pricing-compare"},[_c('div',{staticClass:"pricing-compare-row"},_vm._l((['Summary', 'Start', 'Pro', 'Enterprise']),function(e,ind){return _c('div',{key:ind,staticClass:"pricing-compare-item pricing-compare-item-title"},[(ind === 0)?_c('a',{staticClass:"pricing-compare-item-title-link",attrs:{"href":"#pricing-summary"}},[_vm._v(" "+_vm._s(e)+" "),_c('img',{attrs:{"width":"8px","src":require("@/assets/icons/arrow-top.svg"),"alt":"top"}})]):_c('span',[_vm._v(_vm._s(e))])])}),0),_vm._l((_vm.plansData),function(e,indE){return _c('div',{key:e.id},[_c('div',{staticClass:"pricing-compare-title"},[_c('span',[_vm._v(_vm._s(e.title))])]),_vm._l((e.data),function(el,indEl){return [_c('div',{key:el.id,staticClass:"pricing-compare-row"},[_c('div',{staticClass:"pricing-compare-item"},[(el.data.length)?_c('button',{staticClass:"pricing-compare-button",class:{
                    'pricing-compare-button__open': el.visible,
                  },on:{"click":function($event){return _vm.changeVisibility('current', indE, indEl)}}}):_vm._e(),_c('span',{staticClass:"pricing-compare-item-subtitle"},[_vm._v(_vm._s(el.subtitle))])]),_vm._l((_vm.planItems),function(item,indItem){return _c('div',{key:indItem,staticClass:"pricing-compare-item"},[_vm._v(" "+_vm._s(typeof(el[item]) !== 'boolean' ? el[item]: '')+" "),(typeof(el[item]) === 'boolean' && el[item])?_c('img',{attrs:{"width":"30px","src":require("@/assets/icons/checkmark.svg"),"alt":"checkmark"}}):_vm._e(),(el[`${item}WithAdd`])?_c('span',{staticClass:"pricing-compare-item-additional"},[_vm._v("+")]):_vm._e()])})],2),_vm._l((el.data),function(subEl){return (el.visible)?_c('div',{key:`${e.id}${el.id}${subEl.id}`,staticClass:"pricing-compare-row"},[_c('div',{staticClass:"pricing-compare-item"},[_c('span',{staticClass:"pricing-compare-item-subsubtitle"},[_vm._v(_vm._s(subEl.subsubtitle))])]),_vm._l((_vm.planItems),function(item,indItem){return _c('div',{key:indItem,staticClass:"pricing-compare-item"},[_vm._v(" "+_vm._s(typeof(subEl[item]) !== 'boolean' ? subEl[item] : '')+" "),(typeof(subEl[item]) === 'boolean' && subEl[item])?_c('img',{attrs:{"width":"30px","src":require("@/assets/icons/checkmark.svg"),"alt":"checkmark"}}):_vm._e(),(subEl[`${item}WithAdd`])?_c('span',{staticClass:"pricing-compare-item-additional"},[_vm._v("+")]):_vm._e()])})],2):_vm._e()})]}),_c('button',{staticClass:"pricing-compare-button-more",class:{
              'pricing-compare-button-more__open': _vm.checkCollapseInd(indE),
            },on:{"click":function($event){_vm.changeVisibility('by_ind', indE, null, !_vm.checkCollapseInd(indE))}}},[_vm._v(" "+_vm._s(_vm.checkCollapseInd(indE) ? 'see less features': 'see more features')+" ")])],2)})],2)],2)]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"bottom-demo"},[_c('router-link',{attrs:{"to":{name: 'Demo',}}},[_c('BaseButton',{attrs:{"filled":""}},[_vm._v(" "+_vm._s(_vm.getTranslate('buttonForADemo'))+" ")])],1)],1),_c('div',{staticClass:"bottom-description"},[_vm._v(" "+_vm._s(_vm.getTranslate('underButtonPhrase'))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }