<template>
  <div id="webinar">
    <div class="demo-main-photo" :style="{background: `url('${webinarImage}') 50% 50%`, backgroundSize: 'cover',}">
      <div class="demo-main-photo-title">
        {{ getTranslateFromItem(main.translation, 'title') }}
        <div class="demo-main-photo-title-date">
          {{ getTranslateFromItem(main.translation, 'date') }}
        </div>
      </div>
      <div class="page-section-reg-wrapper">
        <a href="#regSection">
          <BaseButton filled>Зарегистрироваться</BaseButton>
        </a>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslateFromItem(description.translation, 'title') }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0px',
            whiteSpace: 'pre-wrap',
          }"
        >
          <div v-html="descriptionBefore"></div>
          <ul>
            <li v-for="e in description.list">{{ getTranslateFromItem(e.translate, 'text') }}</li>
          </ul>
          <div>{{ getTranslateFromItem(description.translation, 'after') }}</div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslateFromItem(program.translation, 'title') }}</div>
        <div v-for="e in program.days" class="programme-item">
          <div class="programme-item-day">
            <div>{{ getTranslateFromItem(e.translation, 'title') }}</div>
            <div>{{ getTranslateFromItem(e.translation, 'time') }}</div>
          </div>
          <div class="programme-item-content">
            <ol
                type="1"
            >
              <li
                v-for="(item, i) in e.programme"
                class="programme-item-content-item"
              >
                <div class="programme-item-content-item-title">{{ getTranslateFromItem(item.translation, 'title') }}</div>
                <div class="programme-item-content-item-speaker" v-if="item.general.speaker">{{ getTranslateFromItem(item.translation, 'speaker') }}</div>
                <div class="programme-item-content-item-modules">{{ getTranslateFromItem(item.translation, 'modules') }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="e in targets"
        class="page-section-wrapper marketing-section-analytics-wrapper"
        :class="{
          'marketing-section-analytics-wrapper__reverse': e.general.reverse,
        }"
    >
      <div
          class="page-section-inner marketing-section-analytics"
      >
        <div class="marketing-section-analytics-image">
          <img :src="e.general.photo" alt="">
        </div>
        <div class="marketing-section-analytics-info">
          <div class="marketing-section-analytics-info-title">
            {{ getTranslateFromItem(e.translation, 'title') }}
          </div>
          <div class="marketing-section-analytics-info-description" v-html="getTranslateFromItem(e.translation, 'description')"></div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarSpeakersTitle') }}</div>
        <CarouselWebinar :speakers="speakers"></CarouselWebinar>
      </div>
    </div>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('webinarPartnersTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item" v-for="e in partners">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
      </div>
    </div>
    <div id="regSection" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarFormTitle') }}</div>
        <div id="hubspotForm" ref="hubspot-form1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import webinarImage from '@/assets/images/webinar/webinar.jpg';
import seoImage from '@/assets/images/webinar/feb22.png';
import speakerGonchImage from '@/assets/images/speakers/gonch.png';
import speakerKotchImage from '@/assets/images/speakers/kotch.jpg';
import speakerKorolevaImage from '@/assets/images/speakers/koroleva.png';
import speakerMoskvinaImage from '@/assets/images/speakers/moskvina.jpg';
import speakerAnatoliyImage from '@/assets/images/speakers/anatoliy.jpg';
import speakerAktemirovaImage from '@/assets/images/speakers/aktemirova.jpg';
import speakerEfimovImage from '@/assets/images/speakers/efimov.jpeg';
import speakerGolovImage from '@/assets/images/speakers/golov.png';
import travellineImage from '@/assets/images/partners/travelline.png';
import otaImage from '@/assets/images/partners/ota.png';
import sovrImage from '@/assets/images/partners/sovr.png';
import hrImage from '@/assets/images/partners/hotelreport.png';
import srImage from '@/assets/images/partners/sr.jpg';
import afImage from '@/assets/images/partners/af.png';
import etgImage from '@/assets/images/partners/etg.png';
import wrkhImage from '@/assets/images/partners/wrkh.jpg';
import tvilImage from '@/assets/images/partners/tvil.png';
import {mapGetters} from "vuex";
import CarouselWebinar from "@/components/CarouselWebinar";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Webinar",
  computed: {
    ...mapGetters([
        'getTranslate',
        'getTranslateFromItem',
        'lang',
        'integrations',
    ]),
    descriptionBefore() {
      return this.getTranslateFromItem(this.description.translation, 'before');
    },
  },
  watch: {
    lang() {
      this.appendForm();
    },
  },
  mounted() {
    this.appendForm();
  },
  methods: {
    appendForm() {
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "6cfa76d4-e4c1-4435-9616-e879ee52ec4a",
            target: '#hubspotForm',
          });
        }
      })
    },
  },
  components: {
    CarouselWebinar,
    BaseButton
  },
  data: () => ({
    webinarImage,
    main: {
      translation: {
        ru: {
          title: 'WEB Revenue Workshop',
          date: 'Для отелей номерным фондом 20 и более номеров \n Онлайн | 23 июня | 15:00-17:00 мск'
        },
        en: {
          title: 'WEB Revenue Workshop',
          date: 'Для отелей номерным фондом 20 и более номеров \n Онлайн | 23 июня | 15:00-17:00 мск'
        },
      },
    },
    description: {
      list: [
      ],
      translation: {
        ru: {
          title: 'О чём  наш воркшоп',
          before: '<p>Проводят партнеры: hotellab, Emerging,Travel Group и TravelLine</p>' +
          '<p>Тема. Три месяца, как ушел booking. Кто вышел "на замену". Какие изменения произошли  в ценообразовании и продажах отелей.</p>' +
          '<p>Воркшоп посвящен работе гостиничного бизнеса в условиях глобальных изменений и новым решениям автоматизации в управлении доходами.</p>' +
          '<p>Вы узнаете:</p>' +
          '<ol><li>Текущий анализ спроса в онлайн и оффлайн: “результаты”  Москвы, Санкт-Петербурга, Краснодарского края и региональных городов.</li>' +
          '<li>Какие российские  ОТА оказались "быстрее, выше, сильнее".</li>' +
          '<li>Какие стратегии позволят вашему отелю обогнать в " забеге" конкурентов.</li>' +
          '<li>Как RMS инструменты, при правильном использовании, дают отелю <i>"больше очков"</i></li></ol>',
          after: '',
        },
        en: {
          title: 'What is our workshop about',
          before: '<p>Проводят партнеры: hotellab, Emerging,Travel Group и TravelLine</p>' +
          '<p>Тема. Три месяца, как ушел booking. Кто вышел "на замену". Какие изменения произошли  в ценообразовании и продажах отелей.</p>' +
          '<p>Воркшоп посвящен работе гостиничного бизнеса в условиях глобальных изменений и новым решениям автоматизации в управлении доходами.</p>' +
          '<p>Вы узнаете:</p>' +
          '<ol><li>Текущий анализ спроса в онлайн и оффлайн: “результаты”  Москвы, Санкт-Петербурга, Краснодарского края и региональных городов.</li>' +
          '<li>Какие российские  ОТА оказались "быстрее, выше, сильнее".</li>' +
          '<li>Какие стратегии позволят вашему отелю обогнать в " забеге" конкурентов.</li>' +
          '<li></li>Как RMS инструменты, при правильном использовании, дают отелю <i>"больше очков"</i></ol>',
          after: '',
        },
      },
    },
    program: {
      days: [
        {
          general: {
            id: 0,
          },
          programme: [
            {
              general: {
                id: 5,
                speaker: false,
              },
              translation: {
                ru: {
                  title: '15:00 - 15:05',
                  speaker: '',
                  modules: '- Подключение и опрос участников \n'
                },
                en: {
                  title: '15:00 - 15:05',
                  speaker: '',
                  modules: '- Подключение и опрос участников \n',
                },
              },
            },
            {
              general: {
                id: 6,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '15:05 - 15:30',
                  speaker: 'Тренды лета — 2022: время перемен. Яна Королева, эксперт TravelLine по онлайн-продажам',
                  modules: '- Ищем новые рабочие схемы в новых реалиях. \n' +
                  '- Запасаемся мыслетопливом для гипотез и экспериментов. \n' +
                  '- Выводим на максимум прямые продажи. \n' +
                  '- Используем вау-эффект для запуска сарафанного радио. \n' +
                  '- Делаем базовые вещи для максимальных результатов.'
                },
                en: {
                  title: '15:05 - 15:30',
                  speaker: 'Тренды лета — 2022: время перемен. Яна Королева, эксперт TravelLine по онлайн-продажам',
                  modules: '- Ищем новые рабочие схемы в новых реалиях. \n' +
                  '- Запасаемся мыслетопливом для гипотез и экспериментов. \n' +
                  '- Выводим на максимум прямые продажи. \n' +
                  '- Используем вау-эффект для запуска сарафанного радио. \n' +
                  '- Делаем базовые вещи для максимальных результатов.'
                },
              },
            },
            {
              general: {
                speaker: true,
                id: 7,
              },
              translation: {
                ru: {
                  title: '15:30 - 16:00',
                  speaker: 'Дистрибуция 2.0 — аналитика Ostrovok.ru. Арина Актемирова, Руководитель отдела по работе с отелями Ostrovok.ru',
                  modules: '- Аналитика бронирований в России в 2022 году. \n' +
                      '- B2C и B2B: статистика по атрибутам бронирований. \n' +
                      '- Актуальный портрет клиента - как изменились предпочтения пользователей?'
                },
                en: {
                  title: '15:30 - 16:00',
                  speaker: 'Дистрибуция 2.0 — аналитика Ostrovok.ru. Арина Актемирова, Руководитель отдела по работе с отелями Ostrovok.ru',
                  modules: '- Аналитика бронирований в России в 2022 году. \n' +
                      '- B2C и B2B: статистика по атрибутам бронирований. \n' +
                      '- Актуальный портрет клиента - как изменились предпочтения пользователей?'
                },
              },
            },
          ],
          translation: {
            ru: {
              title: 'Онлайн продажи',
              time: '15:00 - 16:00'
            },
            en: {
              title: 'Онлайн продажи',
              time: '15:00 - 16:00'
            },
          },
        },
        {
          general: {
            id: 1,
          },
          programme: [
            {
              general: {
                id: 5,
                speaker: false,
              },
              translation: {
                ru: {
                  title: '16:00 - 16:30',
                  speaker: '',
                  modules: 'Анализ ситуации “на поле” и текущие решения от компании hotellab: \n' +
                  '- Наблюдения и аналитика динамики спроса в разных регионах, в онлайн  и в оффлайн ресурсах \n' +
                  'Важность настройки сегментации в тарифной политике отеля \n' +
                  'Индекс цен. Как часто отели меняют цены в текущих реалиях \n'

                },
                en: {
                  title: '16:00 - 16:30',
                  speaker: '',
                  modules: 'Анализ ситуации “на поле” и текущие решения от компании hotellab: \n' +
                  '- Наблюдения и аналитика динамики спроса в разных регионах, в онлайн  и в оффлайн ресурсах \n' +
                  'Важность настройки сегментации в тарифной политике отеля \n' +
                  'Индекс цен. Как часто отели меняют цены в текущих реалиях \n'
                },
              },
            },
            {
              general: {
                id: 6,
                speaker: false,
              },
              translation: {
                ru: {
                  title: '16:30 - 17:00',
                  speaker: '',
                  modules: '— Мнения отельеров. К нам подключатся “полевые игроки”: Revenue Manager и генеральный менеджер отеля. Расскажут о том, как они сегодня справляются с ситуацией. \n'
                },
                en: {
                  title: '16:30 - 17:00',
                  speaker: '',
                  modules: '— Мнения отельеров. К нам подключатся “полевые игроки”: Revenue Manager и генеральный менеджер отеля. Расскажут о том, как они сегодня справляются с ситуацией. \n'
                },
              },
            },
          ],
          translation: {
            ru: {
              title: 'Управление доходами',
              time: '16:00 - 17:00'
            },
            en: {
              title: 'Управление доходами',
              time: '16:00 - 17:00'
            },
          },
        },
      ],
      translation: {
        ru: {
          title: 'Программа',
          description: '',
        },
        en: {
          title: 'Program',
          description: '',
        },
      },
    },
    partners: [
      {
        "id": 2,
        "generic": {
          "photo": travellineImage,
          "url": "https://www.travelline.ru/",
        },
      },
      {
        "id": 3,
        "generic": {
          "photo": "https://hotellab.io/media/hotelier.png",
          "url": "https://hotelier.pro",
        },
      },
      {
        "id": 7,
        "generic": {
          "photo": etgImage,
          "url": "https://ostrovok.ru/",
        },
      },
      {
        "id": 8,
        "generic": {
          "photo": wrkhImage,
          "url": "https://t.me/wrkhotel",
        },
      },
      {
        "id": 9,
        "generic": {
          "photo": hrImage,
          "url": "https://hotel.report/",
        },
      },
    ],
    targets: [
      {
        general: {
          id: 0,
          photo: 'https://hotellab.io/media/webinarForWho.webp',
        },
        translation: {
          ru: {
            title: 'Для кого?',
            description: 'Для отельеров и собственников отелей и апартаментов объёмом 20 номеров и более, которые стремятся быть финансово успешными независимо от периодов турбулентности.',
          },
          en: {
            title: 'For Who?',
            description: 'Для отельеров и собственников отелей и апартаментов объёмом 20 номеров и более, которые стремятся быть финансово успешными независимо от периодов турбулентности.',
          },
        },
      },
      {
        general: {
          reverse: true,
          id: 1,
          photo: 'https://hotellab.io/media/webinarForWhy.jpg',
        },
        translation: {
          ru: {
            title: 'Что даст?',
            description: '<ul><li>Инструменты увеличения продаж на основе IT технологий, которые позволят получать максимум прибыли в любой ситуации.</li>' +
            '<li>Аналитику и прогнозы спроса.</li>' +
            '<li>Возможность быть в тренде и впереди конкурентов.</li></ul>'
          },
          en: {
            title: 'What Can You Get?',
            description: '<ul><li>Инструменты увеличения продаж на основе IT технологий, которые позволят получать максимум прибыли в любой ситуации.</li>' +
            '<li>Аналитику и прогнозы спроса.</li>' +
            '<li>Возможность быть в тренде и впереди конкурентов.</li></ul>'
          },
        },
      },
    ],
    speakers: [
      {
        general: {
          photo: speakerAnatoliyImage,
        },
        translation: {
          ru: {
            name: 'Анатолий Бурдаков',
            description: 'Основатель hotellab.io',
          },
          en: {
            name: 'Anatoly Burdakov',
            description: 'Founder of hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerMoskvinaImage,
        },
        translation: {
          ru: {
            name: 'Мария Москвина',
            description: 'Коммерческий директор hotellab.io',
          },
          en: {
            name: 'Мария Москвина',
            description: 'Коммерческий директор hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerKorolevaImage,
        },
        translation: {
          ru: {
            name: 'Яна Королева',
            description: 'Эксперт TravelLine по онлайн-продажам',
          },
          en: {
            name: 'Яна Королева',
            description: 'Эксперт TravelLine по онлайн-продажам',
          },
        },
      },
      {
        general: {
          photo: speakerAktemirovaImage,
        },
        translation: {
          ru: {
            name: 'Арина Актемирова',
            description: 'Руководитель отдела по работе с отелями Ostrovok.ru',
          },
          en: {
            name: 'Арина Актемирова',
            description: 'Руководитель отдела по работе с отелями Ostrovok.ru',
          },
        },
      },
      {
        general: {
          photo: speakerEfimovImage,
        },
        translation: {
          ru: {
            name: 'Дмитрий Ефимов',
            description: 'Revenue-менеджер отеля Пекин, Москва',
          },
          en: {
            name: 'Дмитрий Ефимов',
            description: 'Revenue-менеджер отеля Пекин, Москва',
          },
        },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.page-section-wrapper.pt-0 {
  padding-top: 0;
}

.page-section-reg-wrapper {
  padding: 30px;
}

.page-section-reg-wrapper span {
  margin-right: 20px;
}

.demo-main-photo-title-date {
  white-space: pre-wrap;
}
.programme-item {
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  background-color: #F9FAFF;

  .page-section-reg-wrapper {
    padding: 30px;
  }

  .page-section-reg-wrapper span {
    margin-right: 20px;
  }


  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }


  &-day {
    width: 40%;
    font-weight: 900;
    font-size: 30px;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    background-color: #5d5ca8;
    color: white;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      text-align: center;
      padding: 25px;
      font-size: 24px;
    }
  }

  &-content {
    white-space: pre-wrap;
    font-size: 21px;
    line-height: 30px;
    padding: 40px;
    flex-grow: 1;

    @media (max-width: $breakpoint-tablet) {
      padding: 25px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }

    ol {
      margin: 0;
    }

    &-item {
      margin-bottom: 20px;

      &-title {
        font-weight: 900;
      }

      &-speaker {
        font-size: 0.9em;
        font-style: italic;
        opacity: .7;
      }

      &-modules {
        margin-top: 10px;
      }

      &::marker {
        font-weight: 900;
      }
    }
  }
}
.demo-main-photo {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(50vh - 80px);
    }
  }

  &-title {
    font-size: 60px;
    line-height: 72px;
    max-width: 90%;
    width: 1000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    color: white;
    padding: 20px 70px;
    background-color: #5d5ca8;

    &-date {
      font-size: 30px;
      line-height: 36px;
      margin-top: 10px;


      @media (max-width: $breakpoint-desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 10px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 22px;
            line-height: 28px;
            margin-top: 5px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
      }
    }


    @media (max-width: $breakpoint-desktop) {
      font-size: 50px;
      line-height: 60px;
      max-width: 700px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 36px;
        line-height: 44px;
        padding: 20px 40px;
        max-width: 500px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 28px;
          line-height: 34px;
          width: 90%;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.webinar-target {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column !important;
  }

  &-title {
    width: 50%;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 900;
    font-size: 60px;
    text-align: left;
    display: flex;
    justify-content: flex-start;

    &-inner {
      background-color: #5d5ca8;
      color: white;
      padding: 10px 30px;
    }
  }

  &-description {
    width: 50%;
    font-size: 25px;
    box-sizing: border-box;
    padding: 40px;
    text-align: right;
    line-height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
  }
}

.webinar-target__active {
  flex-direction: row;

  .webinar-target-title {
    text-align: right;
    justify-content: flex-end;
  }

  .webinar-target-description {
    text-align: left;
  }
}

.marketing-section-analytics-wrapper__reverse {
  background-color: transparent !important;
  .marketing-section-analytics {
    flex-direction: row-reverse;
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }

    &-title {
    }
  }
}

.marketing-section-analytics-wrapper {
  background-color: #F9FAFF;

  .marketing-section-analytics {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: column;
    }

    &-image {
      width: 50%;
      box-sizing: border-box;
      padding-right: 32px;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 20px;
      }

      @media (max-width: $breakpoint-desktop) {
        padding-right: 24px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            width: 70%;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }

    &-info {
      flex: 1;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      flex-shrink: 0;
      font-weight: 900;
      font-size: 40px;
      padding-left: 32px;
      line-height: 52px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        font-size: 34px;
        line-height: 40px;
        padding-left: 24px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 36px;
          padding-left: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;
          margin-bottom: 12px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }

      &-description {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        width: 80%;
        white-space: pre-wrap;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }
  }
}
</style>
