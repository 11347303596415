<template>
  <div id="webinar">
    <div class="demo-main-photo" :style="{background: `url('${webinarImage}') 50% 50%`, backgroundSize: 'cover',}">
      <div class="demo-main-photo-title">
        {{ getTranslateFromItem(main.translation, 'title') }}
        <div class="demo-main-photo-title-date">
          {{ getTranslateFromItem(main.translation, 'date') }}
        </div>
      </div>
      <div class="page-section-reg-wrapper">
        <a href="#regSection">
          <BaseButton filled>Зарегистрироваться</BaseButton>
        </a>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslateFromItem(description.translation, 'title') }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0px',
            whiteSpace: 'pre-wrap',
          }"
        >
          <div v-html="descriptionBefore"></div>
          <ul>
            <li v-for="e in description.list">{{ getTranslateFromItem(e.translate, 'text') }}</li>
          </ul>
          <div>{{ getTranslateFromItem(description.translation, 'after') }}</div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslateFromItem(program.translation, 'title') }}</div>
        <div v-for="e in program.days" class="programme-item">
          <div class="programme-item-day">
            <div>{{ getTranslateFromItem(e.translation, 'title') }}</div>
            <div>{{ getTranslateFromItem(e.translation, 'time') }}</div>
          </div>
          <div class="programme-item-content">
            <ol
                type="1"
            >
              <li
                v-for="(item, i) in e.programme"
                class="programme-item-content-item"
              >
                <div class="programme-item-content-item-title">{{ getTranslateFromItem(item.translation, 'title') }}</div>
                <div class="programme-item-content-item-speaker" v-if="item.general.speaker">{{ getTranslateFromItem(item.translation, 'speaker') }}</div>
                <div class="programme-item-content-item-modules">{{ getTranslateFromItem(item.translation, 'modules') }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="e in targets"
        class="page-section-wrapper marketing-section-analytics-wrapper"
        :class="{
          'marketing-section-analytics-wrapper__reverse': e.general.reverse,
        }"
    >
      <div
          class="page-section-inner marketing-section-analytics"
      >
        <div class="marketing-section-analytics-image">
          <img :src="e.general.photo" alt="">
        </div>
        <div class="marketing-section-analytics-info">
          <div class="marketing-section-analytics-info-title">
            {{ getTranslateFromItem(e.translation, 'title') }}
          </div>
          <div class="marketing-section-analytics-info-description" v-html="getTranslateFromItem(e.translation, 'description')"></div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarSpeakersTitle') }}</div>
        <CarouselWebinar :speakers="speakers"></CarouselWebinar>
      </div>
    </div>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('webinarPartnersTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item" v-for="e in partners">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
      </div>
    </div>
    <div id="regSection" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarFormTitle') }}</div>
        <div id="hubspotForm" ref="hubspot-form1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import webinarImage from '@/assets/images/webinar/webinar.jpg';
import seoImage from '@/assets/images/webinar/feb22.png';
import speakerGonchImage from '@/assets/images/speakers/gonch.png';
import speakerKotchImage from '@/assets/images/speakers/kotch.jpg';
import speakerAndreevaImage from '@/assets/images/speakers/andreeva.jpg';
import speakerVjushinImage from '@/assets/images/speakers/vjushin.jpg';
import speakerAnatoliyImage from '@/assets/images/speakers/anatoliy.jpg';
import speakerGolovImage from '@/assets/images/speakers/golov.png';
import travellineImage from '@/assets/images/partners/travelline.png';
import bronevikImage from '@/assets/images/partners/bronevik.png';
import hotbotImage from '@/assets/images/partners/HotbotBlack.png';
import otaImage from '@/assets/images/partners/ota.png';
import sovrImage from '@/assets/images/partners/sovr.png';
import hrImage from '@/assets/images/partners/hotelreport.png';
import srImage from '@/assets/images/partners/sr.jpg';
import afImage from '@/assets/images/partners/af.png';
import rhaImage from '@/assets/images/partners/rha.png';
import ghcImage from '@/assets/images/partners/ghc.png';
import yandexTravelImage from '@/assets/images/partners/yandex-travel.png';
import etgImage from '@/assets/images/partners/etg.png';
import wrkhImage from '@/assets/images/partners/wrkh.jpg';
import tvilImage from '@/assets/images/partners/tvil.png';
import {mapGetters} from "vuex";
import CarouselWebinar from "@/components/CarouselWebinar";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Webinar",
  computed: {
    ...mapGetters([
        'getTranslate',
        'getTranslateFromItem',
        'lang',
        'integrations',
    ]),
    descriptionBefore() {
      return this.getTranslateFromItem(this.description.translation, 'before');
    },
  },
  watch: {
    lang() {
      this.appendForm();
    },
  },
  mounted() {
    this.appendForm();
  },
  methods: {
    appendForm() {
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/shell.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "f34572bf-f1ac-4cc2-b255-a0db7e4b1ea2",
            target: '#hubspotForm',
          });
        }
      })
    },
  },
  components: {
    CarouselWebinar,
    BaseButton
  },
  data: () => ({
    webinarImage,
    main: {
      translation: {
        ru: {
          title: 'WEB Revenue Workshop',
          date: 'Для отелей от 20 номеров \n Онлайн | 7 Апреля| 11:00-13:00 мск'
        },
        en: {
          title: 'WEB Revenue Workshop',
          date: 'Для отелей от 20 номеров \n Онлайн | 7 Апреля| 11:00-13:00 мск'
        },
      },
    },
    description: {
      list: [
      ],
      translation: {
        ru: {
          title: 'О чём  наш воркшоп',
          before: '<p>7 апреля компания hotellab.io, совместно с партнёрами TravelLine, Hotbot.ai проводит Web Revenue Workshop</p>' +
          '<p>Тема:  Новые вызовы: как цифровые технологии помогают отелям увеличивать доходность. Booking ушел - кто пришел на замену. Анализ ситуации с российскими каналами онлайн - продаж.</p>' +
          '<p>Особенности данного воркшопа:</p>' +
          '<ul><li>короткие выступления(по 15 минут) каждого партнера о текущей ситуации и технологиях. Позволят отельерам получить максимум полезной информации за 2 часа воркшопа</li>' +

          '<li>блок Дистрибуция. В нём ведущие OTA каналы, в рамках коротких выступлений, поделятся ситуацией, сложившейся после отключения Booking</li></ul>' +
          '<p>Чтобы получить ссылку для подключения, необходимо обязательно зарегистрироваться.</p>' +
          '<p>Для отелей номерным фондом 20 номеров и более.</p>' +
          '<p>Воркшоп посвящен работе в условиях турбулентности и взаимодействию с ключевыми ОТА, заменяющими booking.com.</p>' +
          '<p>Опытные эксперты Revenue Management, Ecommerce, и представители ведущих российских ОТА - каналов, в коротких, но содержательных выступлениях,  проанализируют текущую ситуацию в гостиничном бизнесе, познакомят с новыми инструментами, позволяющими увеличивать доходность отелей в периоды нестабильности.</p>',
          after: '',
        },
        en: {
          title: 'What is our workshop about',
          before: '<p>7 апреля компания hotellab.io, совместно с партнёрами TravelLine, Hotbot.ai проводит Web Revenue Workshop</p>' +
          '<p>Тема:  Новые вызовы: как цифровые технологии помогают отелям увеличивать доходность. Booking ушел - кто пришел на замену. Анализ ситуации с российскими каналами онлайн - продаж.</p>' +
          '<p>Особенности данного воркшопа:</p>' +
          '<ul><li>короткие выступления(по 15 минут) каждого партнера о текущей ситуации и технологиях. Позволят отельерам получить максимум полезной информации за 2 часа воркшопа</li>' +

          '<li>блок Дистрибуция. В нём ведущие OTA каналы, в рамках коротких выступлений, поделятся ситуацией, сложившейся после отключения Booking</li></ul>' +
          '<p>Чтобы получить ссылку для подключения, необходимо обязательно зарегистрироваться.</p>' +
          '<p>Для отелей номерным фондом 20 номеров и более.</p>' +
          '<p>Воркшоп посвящен работе в условиях турбулентности и взаимодействию с ключевыми ОТА, заменяющими booking.com.</p>' +
          '<p>Опытные эксперты Revenue Management, Ecommerce, и представители ведущих российских ОТА - каналов, в коротких, но содержательных выступлениях,  проанализируют текущую ситуацию в гостиничном бизнесе, познакомят с новыми инструментами, позволяющими увеличивать доходность отелей в периоды нестабильности.</p>',
          after: '',
        },
      },
    },
    program: {
      days: [
        {
          general: {
            id: 0,
          },
          programme: [
            {
              general: {
                id: 5,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '11:00 - 11:15',
                  speaker: 'Анатолий Бурдаков, hotellab.io',
                  modules: '- Управление доходами, работа с рекламой в период новых вызовов \n' +
                  '- Решения анализа рынка и конкурентов \n',
                },
                en: {
                  title: '11:00 - 11:15',
                  speaker: 'Анатолий Бурдаков, hotellab.io',
                  modules: '- Управление доходами, работа с рекламой в период новых вызовов \n' +
                  '- Решения анализа рынка и конкурентов \n',
                },
              },
            },
            {
              general: {
                id: 6,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '11:15 - 11:30',
                  speaker: 'TravelLine',
                  modules: '- Как выжать максимум из прямых продаж \n' +
                  '- Программа лояльности: от первой брони до постоянного гостя \n' +
                  '- Как получить инструменты TravelLine со скидкой 50% \n'
                },
                en: {
                  title: '11:15 - 11:30',
                  speaker: 'TravelLine',
                  modules: '- Как выжать максимум из прямых продаж \n' +
                  '- Программа лояльности: от первой брони до постоянного гостя \n' +
                  '- Как получить инструменты TravelLine со скидкой 50% \n'
                },
              },
            },
            {
              general: {
                speaker: true,
                id: 7,
              },
              translation: {
                ru: {
                  title: '11:30 - 11:45',
                  speaker: 'Сергей Голованов, Hotbot.ai',
                  modules: '- Продажи дополнительных услуг. На сайте, на стойке \n' +
                  '- Как ведут себя доп. продажи в текущее время \n'
                },
                en: {
                  title: '11:30 - 11:45',
                  speaker: 'Сергей Голованов, Hotbot.ai',
                  modules: '- Продажи дополнительных услуг. На сайте, на стойке \n' +
                  '- Как ведут себя доп. продажи в текущее время \n'
                },
              },
            },
            {
              general: {
                speaker: true,
                id: 8,
              },
              translation: {
                ru: {
                  title: '11:45 - 12:00',
                  speaker: '',
                  modules: 'Ответы на вопросы \n'
                },
                en: {
                  title: '11:45 - 12:00',
                  speaker: '',
                  modules: 'Ответы на вопросы \n'
                },
              },
            },
          ],
          translation: {
            ru: {
              title: 'Доходы и продажи',
              time: '11:00 - 12:00'
            },
            en: {
              title: 'Доходы и продажи',
              time: '11:00 - 12:00'
            },
          },
        },
        {
          general: {
            id: 1,
          },
          programme: [
            {
              general: {
                id: 5,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '12:00 - 12:15',
                  speaker: 'Юрий Вьюшин, Яндекс.Путешествия',
                  modules: '- Анализ текущей ситуации от Яндекс.Путешествий \n' +
                  '- Рекомендации для отельеров \n'
                },
                en: {
                  title: '12:00 - 12:15',
                  speaker: 'Юрий Вьюшин, Яндекс.Путешествия',
                  modules: '- Анализ текущей ситуации от Яндекс.Путешествий \n' +
                  '- Рекомендации для отельеров \n'
                },
              },
            },
            {
              general: {
                id: 6,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '12:15 - 12:30',
                  speaker: 'Марина Гончаренко, Bronevik',
                  modules: '— Рост трафика и бронирований в цифрах \n' +
                  '— Как изменилось соотношение ЦА: B2B \ B2C \n' +
                  '— Планы по развитию сегмента физических лиц \n'
                },
                en: {
                  title: '12:15 - 12:30',
                  speaker: 'Марина Гончаренко, Bronevik',
                  modules: '— Рост трафика и бронирований в цифрах \n' +
                  '— Как изменилось соотношение ЦА: B2B \ B2C \n' +
                  '— Планы по развитию сегмента физических лиц \n'
                },
              },
            },
            {
              general: {
                speaker: true,
                id: 7,
              },
              translation: {
                ru: {
                  title: '12:30 - 12:45',
                  speaker: 'Кочеткова Дарья, B2B.Ostrovok',
                  modules: '- Анализ текущей ситуации от Ostrovok \n' +
                  '- Рекомендации для отельеров \n'
                },
                en: {
                  title: '12:30 - 12:45',
                  speaker: 'Кочеткова Дарья, B2B.Ostrovok',
                  modules: '- Анализ текущей ситуации от Ostrovok \n' +
                  '- Рекомендации для отельеров \n'
                },
              },
            },
            {
              general: {
                speaker: true,
                id: 8,
              },
              translation: {
                ru: {
                  title: '12:45 - 13:00',
                  speaker: '',
                  modules: 'Ответы на вопросы \n'
                },
                en: {
                  title: '12:45 - 13:00',
                  speaker: '',
                  modules: 'Ответы на вопросы \n'
                },
              },
            },
          ],
          translation: {
            ru: {
              title: 'Дистрибуция',
              time: '12:00 - 13:00'
            },
            en: {
              title: 'Дистрибуция',
              time: '12:00 - 13:00'
            },
          },
        },
      ],
      translation: {
        ru: {
          title: 'Программа',
          description: '',
        },
        en: {
          title: 'Program',
          description: '',
        },
      },
    },
    partners: [
      {
        "id": 2,
        "generic": {
          "photo": travellineImage,
          "url": "https://www.travelline.ru/",
        },
      },
      {
        "id": 3,
        "generic": {
          "photo": "https://hotellab.io/media/hotelier.png",
          "url": "https://hotelier.pro",
        },
      },
      {
        "id": 4,
        "generic": {
          "photo": bronevikImage,
          "url": "https://bronevik.com/ru/",
        },
      },
      {
        "id": 5,
        "generic": {
          "photo": hotbotImage,
          "url": "https://hotbot.ai/eng",
        },
      },
      {
        "id": 6,
        "generic": {
          "photo": yandexTravelImage,
          "url": "https://travel.yandex.ru/",
        },
      },
      {
        "id": 7,
        "generic": {
          "photo": etgImage,
          "url": "https://ostrovok.ru/",
        },
      },
      {
        "id": 8,
        "generic": {
          "photo": wrkhImage,
          "url": "https://t.me/wrkhotel",
        },
      },
      {
        "id": 9,
        "generic": {
          "photo": hrImage,
          "url": "https://hotel.report/",
        },
      },
      {
        "id": 10,
        "generic": {
          "photo": rhaImage,
          "url": "https://www.hospitalityawards.ru/",
        },
      },
      {
        "id": 11,
        "generic": {
          "photo": ghcImage,
          "url": "https://globalhospitalityclub.ru/",
        },
      },
    ],
    targets: [
      {
        general: {
          id: 0,
          photo: 'https://hotellab.io/media/webinarForWho.webp',
        },
        translation: {
          ru: {
            title: 'Для кого?',
            description: 'Для отельеров и собственников отелей и апартаментов объемом более 20 номеров, которые хотят увеличить эффективность своего бизнеса и быть в курсе ситуации.',
          },
          en: {
            title: 'For Who?',
            description: 'Для отельеров и собственников отелей и апартаментов объемом более 20 номеров, которые хотят увеличить эффективность своего бизнеса и быть в курсе ситуации.',
          },
        },
      },
      {
        general: {
          reverse: true,
          id: 1,
          photo: 'https://hotellab.io/media/webinarForWhy.jpg',
        },
        translation: {
          ru: {
            title: 'Что даст?',
            description: '<ul><li>Аналитика трендов спроса.</li>' +
            '<li>Анализ текущей ситуации и меры поддержки.</li>' +
            '<li>Инструменты, которые помогут выжать максимум прибыли.</li>' +
            '<li>Возможность быть в тренде и опережать конкурентов.</li></ul>'
          },
          en: {
            title: 'What Can You Get?',
            description: '<ul><li>Аналитика трендов спроса.</li>' +
            '<li>Анализ текущей ситуации и меры поддержки.</li>' +
            '<li>Инструменты, которые помогут выжать максимум прибыли.</li>' +
            '<li>Возможность быть в тренде и опережать конкурентов.</li></ul>'
          },
        },
      },
    ],
    speakers: [
      {
        general: {
          photo: speakerAnatoliyImage,
        },
        translation: {
          ru: {
            name: 'Анатолий Бурдаков',
            description: 'Основатель hotellab.io',
          },
          en: {
            name: 'Anatoly Burdakov',
            description: 'Founder of hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerGolovImage,
        },
        translation: {
          ru: {
            name: 'Сергей Голованов',
            description: 'Директор по продажам hotbot.ai',
          },
          en: {
            name: 'Сергей Голованов',
            description: 'Директор по продажам hotbot.ai',
          },
        },
      },
      {
        general: {
          photo: speakerAndreevaImage,
        },
        translation: {
          ru: {
            name: 'Татьяна Андреева',
            description: 'Ведущий специалист по работе с клиентами в TravelLine',
          },
          en: {
            name: 'Татьяна Андреева',
            description: 'Ведущий специалист по работе с клиентами в TravelLine',
          },
        },
      },
      {
        general: {
          photo: speakerVjushinImage,
        },
        translation: {
          ru: {
            name: 'Юрий Вьюшин',
            description: 'Директор по развитию бизнеса Яндекс Путешествия',
          },
          en: {
            name: 'Юрий Вьюшин',
            description: 'Директор по развитию бизнеса Яндекс Путешествия',
          },
        },
      },
      {
        general: {
          photo: speakerGonchImage,
        },
        translation: {
          ru: {
            name: 'Марина Гончаренко',
            description: 'Руководитель Департамента по работе с отелями Bronevik.com',
          },
          en: {
            name: 'Марина Гончаренко',
            description: 'Руководитель Департамента по работе с отелями Bronevik.com',
          },
        },
      },
      {
        general: {
          photo: speakerKotchImage,
        },
        translation: {
          ru: {
            name: 'Кочеткова Дарья',
            description: 'Региональный директор Emerging Travel Group (Ostrovok.ru)',
          },
          en: {
            name: 'Кочеткова Дарья',
            description: 'Региональный директор Emerging Travel Group (Ostrovok.ru)',
          },
        },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.page-section-wrapper.pt-0 {
  padding-top: 0;
}

.page-section-reg-wrapper {
  padding: 30px;
}

.page-section-reg-wrapper span {
  margin-right: 20px;
}

.demo-main-photo-title-date {
  white-space: pre-wrap;
}
.programme-item {
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  background-color: #F9FAFF;

  .page-section-reg-wrapper {
    padding: 30px;
  }

  .page-section-reg-wrapper span {
    margin-right: 20px;
  }


  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }


  &-day {
    width: 40%;
    font-weight: 900;
    font-size: 30px;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    background-color: #5d5ca8;
    color: white;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      text-align: center;
      padding: 25px;
      font-size: 24px;
    }
  }

  &-content {
    white-space: pre-wrap;
    font-size: 21px;
    line-height: 30px;
    padding: 40px;
    flex-grow: 1;

    @media (max-width: $breakpoint-tablet) {
      padding: 25px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }

    ol {
      margin: 0;
    }

    &-item {
      margin-bottom: 20px;

      &-title {
        font-weight: 900;
      }

      &-speaker {
        font-size: 0.9em;
        font-style: italic;
        opacity: .7;
      }

      &-modules {
        margin-top: 10px;
      }

      &::marker {
        font-weight: 900;
      }
    }
  }
}
.demo-main-photo {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(50vh - 80px);
    }
  }

  &-title {
    font-size: 60px;
    line-height: 72px;
    max-width: 90%;
    width: 1000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    color: white;
    padding: 20px 70px;
    background-color: #5d5ca8;

    &-date {
      font-size: 30px;
      line-height: 36px;
      margin-top: 10px;


      @media (max-width: $breakpoint-desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 10px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 22px;
            line-height: 28px;
            margin-top: 5px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
      }
    }


    @media (max-width: $breakpoint-desktop) {
      font-size: 50px;
      line-height: 60px;
      max-width: 700px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 36px;
        line-height: 44px;
        padding: 20px 40px;
        max-width: 500px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 28px;
          line-height: 34px;
          width: 90%;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.webinar-target {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column !important;
  }

  &-title {
    width: 50%;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 900;
    font-size: 60px;
    text-align: left;
    display: flex;
    justify-content: flex-start;

    &-inner {
      background-color: #5d5ca8;
      color: white;
      padding: 10px 30px;
    }
  }

  &-description {
    width: 50%;
    font-size: 25px;
    box-sizing: border-box;
    padding: 40px;
    text-align: right;
    line-height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
  }
}

.webinar-target__active {
  flex-direction: row;

  .webinar-target-title {
    text-align: right;
    justify-content: flex-end;
  }

  .webinar-target-description {
    text-align: left;
  }
}

.marketing-section-analytics-wrapper__reverse {
  background-color: transparent !important;
  .marketing-section-analytics {
    flex-direction: row-reverse;
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }

    &-title {
    }
  }
}

.marketing-section-analytics-wrapper {
  background-color: #F9FAFF;

  .marketing-section-analytics {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: column;
    }

    &-image {
      width: 50%;
      box-sizing: border-box;
      padding-right: 32px;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 20px;
      }

      @media (max-width: $breakpoint-desktop) {
        padding-right: 24px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            width: 70%;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }

    &-info {
      flex: 1;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      flex-shrink: 0;
      font-weight: 900;
      font-size: 40px;
      padding-left: 32px;
      line-height: 52px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        font-size: 34px;
        line-height: 40px;
        padding-left: 24px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 36px;
          padding-left: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;
          margin-bottom: 12px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }

      &-description {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        width: 80%;
        white-space: pre-wrap;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }
  }
}
</style>
