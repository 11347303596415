<template>
  <BasePage>
    <PageSection
      :max-width="1050"
    >
      <ImageWithInfo
        class="head"
        :image-url="require('@/assets/images/revenue/rms_main.png')"
        :title="getTranslate('rmsMainSlideTitle')"
        :description="getTranslate('rmsMainSlideDesc')"
        large-image
      />
      
      <div class="main-button">
        <a href="#hubspot" class="main-button-button">
          <BaseButton filled>
            {{getTranslate('buttonForSimulator')}}
          </BaseButton>
        </a>
      </div>
    </PageSection>

    <PageSection primary>
      <div id="rms-section-integration">
        <div id="rms-section-integration-image">
          <img src="../assets/images/revenue/rms_purple.png" alt="">
        </div>
        <div id="rms-section-integration-info">
          <div id="rms-section-integration-info-title">
            {{ getTranslateFromItem(integration.translation, 'title') }}
          </div>
          <div id="rms-section-integration-info-description">
            <ul v-html="getTranslateFromItem(integration.translation, 'description')"></ul>
          </div>
        </div>
      </div>
    </PageSection>

    <PageSection :title="getTranslate('rmsKeyTitle')">
      <div class="rms-grid">
        <div v-for="e in technologies" class="rms-grid-item">
          <div class="rms-grid-item-desc">
            <div class="rms-grid-item-desc-title">
              {{ getTranslateFromItem(e.translation, 'title') }}
            </div>
            <div class="rms-grid-item-desc-text">
              {{ getTranslateFromItem(e.translation, 'text') }}
            </div>
          </div>
        </div>
      </div>
    </PageSection>

    <PageSection>
      <div class="page-section-inner rms-advantage" id="points">
        <div class="page-section-inner-title">
          {{ getTranslate('rmsAdvTitle') }}
        </div>
        <div v-for="e in advantages" id="points-item">
          <div id="points-item-image">
            <img :src="e.general.photo" alt="">
          </div>
          <div id="points-item-desc">
            <div id="points-item-desc-title">
              {{ getTranslateFromItem(e.translation, 'title') }}
            </div>
            <div id="points-item-desc-text">
              {{ getTranslateFromItem(e.translation, 'text') }}
            </div>
          </div>
        </div>
      </div>
    </PageSection>

    <div v-if="getTranslate('homeCasesTitle') !== '{{hidden}}'" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslate('homeCasesTitle') }}
        </div>
        <div>
          <CarouselSmallCases></CarouselSmallCases>
        </div>
      </div>
    </div>

    <PageSection>
      <BaseSignUp withTitle></BaseSignUp>
    </PageSection>

    <!-- <PageSection id="hubspot">
        <HubspotForm
            class="hubspotform"
            :form-id="{
            ru: 'cb326461-2353-4b17-91c7-ee02b5baf428',
            en: 'cb326461-2353-4b17-91c7-ee02b5baf428',
            }"
        />
    </PageSection> -->
  </BasePage>
</template>

<script>
import BasePage from "@/components/BasePage";
import HubspotForm from "@/components/HubspotForm";
import PageSection from "@/components/PageSection";
import BaseButton from "@/components/BaseButton";
import ImageWithInfo from "@/components/widgets/ImageWithInfo";
import { cases, revenueSections } from "@/ext";
import {mapGetters} from "vuex";
import CarouselSmallCases from "@/components/CarouselSmallCases";
import BaseSignUp from "@/components/BaseSignUp.vue";
import rmsAdvantagesFirst from '@/assets/images/revenue/1.svg';
import rmsAdvantagesSecond from '@/assets/images/revenue/2.svg';
import rmsAdvantagesThird from '@/assets/images/revenue/3.svg';
import rmsAdvantagesForth from '@/assets/images/revenue/4.svg';

export default {
  name: "Revenue",
  components: {
    CarouselSmallCases,
    BaseButton,
    BasePage,
    HubspotForm,
    PageSection,
    ImageWithInfo,
    BaseSignUp
  },
  computed: {
    ...mapGetters([
      'getTranslate',
      'getTranslateFromItem',
      'lang',
      'cases',
      'carousels',
      'trust',
      'team',
      'images',
      'integrations',
    ]),
  },
  data: () => ({
    integration: {
      translation: {
        ru: {
          title: 'Почему необходимо использовать hotellab:RMS',
          description: '<li>Принятие решений на основе данных о продажах отеля, тарифах конкурентов, бенчмаркинге и маркетинговой аналитике</li> </br>' +
          '<li>Всегда известно , что ожидать от будущего периода. Прогнозы базируются на актуальных тенденциях спроса</li> </br>' +
          '<li>Исключаются риски упущенной выгоды, так как автопилот моментально адаптирует цены под любые  изменения спроса</li>',
        },
        en: {
          title: 'Why it is necessary to use hotellab:RMS',
          description: '<li>Data-driven decisions based on hotel bookings pace, competitor rates, benchmarking, and marketing analytics</li> </br>' +
          '<li>Always knowing what to expect from future periods. Forecasts are based on current demand trends</li> </br>' +
          '<li>Elimination of missed revenue opportunities as the autopilot instantly adjusts prices to any changes in demand</li>',
        },
      },
    },
    technologies: [
      {
        translation: {
          ru: {
            title: 'Сбор цен конкурентов',
            text: 'Анализ цен конкурентов и динамики их изменения в зависимости от глубины и даты заезда. Анализ доступности отелей в городе',
          },
          en: {
            title: 'Machine Learning Pricing',
            text: 'Our advanced algorithm achieves 96% demand prediction accuracy, integrating bookings, competitors, and flight search data',
          },
        },
      },
      {
        translation: {
          ru: {
            title: 'Анализ бронирований',
            text: 'Система анализирует такие показатели, как загрузка, глубина бронирования, сегментация, темпы продаж, RevPAR, за счет изучения данных на уровне каждого бронирования',
          },
          en: {
            title: 'Tailored Algorithm',
            text: 'Customize algorithms to suit your hotel\'s unique needs. Consider over 100 demand parameters for tailored success',
          },
        },
      },
      {
        translation: {
          ru: {
            title: 'Удобная визуализация',
            text: 'Удобная визуализация спроса и рекомендации по динамическому ценообразованию по каждому календарному дню',
          },
          en: {
            title: 'Hybrid Autopilot Rates Updates',
            text: 'Whether you prefer manual, semi-automated, or fully automated pricing, hotellab adapts to your preferences',
          },
        },
      },
      {
        translation: {
          ru: {
            title: 'Глубина бронирования',
            text: 'Отслеживание глубины бронирования на каждую будущую дату по разным источникам, сегментам спроса, категориям номеров и кодов тарифов',
          },
          en: {
            title: 'Business Intelligence',
            text: 'Get a comprehensive view of your hotel\'s performance for informed decisions on revenue growth and market positioning',
          },
        },
      },
      {
        translation: {
          ru: {
            title: 'Прогнозирование загрузки',
            text: 'Алгоритм системы прогнозирует загрузку на ближайшие периоды с точностью до 90%, помогает отелям планировать свою деятельность',
          },
          en: {
            title: 'Yield Management',
            text: 'Fine-tune pricing with advanced tools, maximizing revenue based on real-time demand patterns and market conditions',
          },
        },
      },
      {
        translation: {
          ru: {
            title: 'Машинное обучение',
            text: 'Система анализирует все прошлые и текущие ценовые решения, чтобы находить самые прибыльные цены в режиме онлайн',
          },
          en: {
            title: 'Group Analysis',
            text: 'Ensure all group inquiries pass through displacement to optimize revenue strategies',
          },
        },
      },
    ],
    advantages: [
      {
        general: {
          photo: rmsAdvantagesFirst
        },
        translation: {
          ru: {
            title: 'Точность прогнозирования 96%',
            text: 'Данные в наших алгоритмах обновляются дважды в сутки, что позволяет достичь точности прогнозирования до 96%. Вы сможете более точно управлять ценами в пределах трех месяцев',
          },
          en: {
            title: 'Hybrid autopilot rates',
            text: 'Instant update of open prices through integration with your PMS or channel manager. The ability to set prices on autopilot per period or user-friendly manual updating',
          },
        },
      },
      {
        general: {
          photo: rmsAdvantagesSecond
        },
        translation: {
          ru: {
            title: 'Удобное управление ценами',
            text: 'Удобный интерфейс и кастомизированные настройки алгоритма упростят вашу работу и повысят эффективность. Несколько типов автоматизации, комбинирование ручного режима и автопилота - система полностью настраивается под вас',
          },
          en: {
            title: 'Focus on current trends',
            text: 'Pricing algorithm and forecasting based on current hotel booking’s pace, market demand and other future forward data',
          },
        },
      },
      {
        general: {
          photo: rmsAdvantagesThird
        },
        translation: {
          ru: {
            title: 'Контроль конкурентов',
            text: 'Анализируйте динамику конкурентов для получения дополнительной прибыли. Анализ доступности и открытых цен помогут вам в решении стратегических задач',
          },
          en: {
            title: 'All in one tool',
            text: 'Competitors rates, pick-up reporting, forecasting, displacement analyzes and more advanced tools in one app',
          },
        },
      },
      {
        general: {
          photo: rmsAdvantagesForth
        },
        translation: {
          ru: {
            title: 'Алгоритм, учитывающий более 100 показателей',
            text: 'В нашей системе вы сможете настроить работу алгоритма “под себя”. Более 100 показателей, учитывающихся в нашей системе позволят более точно настроить систему именно под ваши задачи',
          },
          en: {
            title: 'Personalised algorithm',
            text: 'Before integrating algorithm, our team personalizes setup to make sure that all individual factors of a hotel apply in future recommendations',
          },
        },
      },
    ],
  })
}
</script>

<style lang="scss" scoped>
.rms-advantage :nth-child(odd){
  flex-direction: row-reverse;
}

.head {
  color: #5D5CA8;
  line-height: 25px;
  img {
    visibility: hidden;
  }
}

.main-button {
  display: flex;
  justify-content: center;

  &-button {
    width: 210px;
    padding-top: 60px;
    padding-left: 10%;

    @media (max-width: $breakpoint-tablet-v) {
      padding-left: 0;
      margin: auto;
    }
  }
}

.page-section-inner {
  padding-left: 10%;
  padding-right: 10%;

  margin-top: -90px;
  
  @media (max-width: $breakpoint-tablet-v) {
    margin-top: 0;
  }
  
  &-title {
    color: $primary;
    font-weight: 900;
    font-size: 38px;
    text-align: center;
    margin-bottom: 30px;
  }
}

#rms-section-integration {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-tablet-v) {
    flex-direction: column;
  }

  &-image {
    width: 50%;
    box-sizing: border-box;
    padding-right: 32px;

    img {
      width: 100%;
    }

    @media (max-width: $breakpoint-desktop) {
      padding-right: 24px;

      @media (max-width: $breakpoint-tablet) {
        padding-right: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 70%;
          max-width: 400px;
          padding: 0;
          margin-bottom: 20px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
      }
    }
  }

  &-info {
    width: 50%;
    color: white;

    @media (max-width: $breakpoint-tablet-v) {
      width: 100%;
    }

    &-title {
      width: 90%;
      padding-left: 40px;
      line-height: 50px;
      font-weight: 900;
      font-size: 36px;
      margin-bottom: 20px;

      @media (max-width: $breakpoint-desktop) {
        margin-left: -30px;
        width: 95%;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 40px;

          @media (max-width: $breakpoint-tablet-v) {
            text-align: center;
          }
        }
      }
    }

    &-description {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0;
      font-weight: 400;

      @media (max-width: $breakpoint-desktop) {
        font-size: 20px;
        line-height: 30px;
        margin-left: -30px;

        @media (max-width: $breakpoint-tablet) {
          width: 95%;
          font-size: 18px;
          line-height: 28px;

          @media (max-width: $breakpoint-tablet-v) {
            text-align: center;
          }
        }
      }

      ul {
        width: 80%;
        list-style-type: none;

        @media (max-width: $breakpoint-tablet) {
          width: 90%;

          @media (max-width: $breakpoint-tablet-v) {
            width: 100%;
          }      
        }
      }
    }
  }
}

.rms-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &-item {
    position: relative;
    background-color: #f9faff;
    width: calc(85%/3);
    height: 165px;
    top: 50%;
    margin: 15px 10px;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 13px 1px rgb(210, 210, 210);

    @media (max-width: $breakpoint-desktop) {
      flex-direction: column;
      height: inherit;

      @media (max-width: $breakpoint-tablet-v) {
        width: 100%;
        height: 120px;
        margin-left: 0;
        flex-direction: row;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          flex-direction: column;
          height: auto;
        }
      }
    }

    &-desc {
      color: #2F3238;
      text-align: center;
      flex-basis: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 20px 10px;
      flex-direction: column;

      &-title {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 16px;
      }

      &-text {
        @media (max-width: $breakpoint-tablet-v) {
          
        }
      }
    }
  }
}

#points-item {
  display: flex;
  align-items: center;
  
  @media (max-width: $breakpoint-tablet-v) {
    flex-direction: column;
  }

  &-image {
    width: 40%;
    box-sizing: border-box;
    padding-right: 32px;
    display: flex;
    flex-direction: column;

    @media (max-width: $breakpoint-desktop) {
      padding-right: 24px;

      @media (max-width: $breakpoint-tablet) {
        padding-right: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 70%;
          padding: 0;
          margin-bottom: 20px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 400px;
          }
        }
      }
    }
  }

  &-desc {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: 32px;
    padding-right: 60px;
    line-height: 40px;

    @media (max-width: $breakpoint-desktop) {
      line-height: 35px;
      padding-left: 24px;
      padding-bottom: 40px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 24px;
        line-height: 30px;
        padding-left: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 24px;
          line-height: 30px;
          width: 100%;
          max-width: 550px;
          text-align: center;
          flex-direction: column;
          align-items: center;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 24px;
            line-height: 25px;
            padding: 15px 10px;
          }
        }
      }
    }

    &-title {
      color: $primary;
      font-weight: 900;
      line-height: 30px;
      font-size: 20px;
      margin-bottom: 15px;

      @media (max-width: $breakpoint-tablet-v) {
        margin-bottom: 10px;
        max-width: 400px;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          max-width: 350px;
        }
      }
    }

    &-text {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0;
      box-sizing: border-box;
      font-weight: 400;

      @media (max-width: $breakpoint-tablet-v) {

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          padding: 0 5px;
        }
      }
    }
  }
}

.hubspotform {
  margin: auto;
  margin-top: -50px;
  width: 90%;
}
</style>
