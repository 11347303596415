<template>
  <div id="webinar">
    <div class="demo-main-photo" :style="{background: `url('${webinarImage}') 50% 50%`, backgroundSize: 'cover',}">
      <div class="demo-main-photo-title">
        {{ getTranslateFromItem(main.translation, 'title') }}
        <div class="demo-main-photo-title-date">
          {{ getTranslateFromItem(main.translation, 'date') }}
        </div>
      </div>
      <div class="page-section-reg-wrapper">
        <a href="#regSection">
          <BaseButton filled>Зарегистрироваться</BaseButton>
        </a>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslateFromItem(description.translation, 'title') }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0px',
            whiteSpace: 'pre-wrap',
          }"
        >
          <div v-html="descriptionBefore"></div>
          <ul>
            <li v-for="e in description.list">{{ getTranslateFromItem(e.translate, 'text') }}</li>
          </ul>
          <div>{{ getTranslateFromItem(description.translation, 'after') }}</div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslateFromItem(program.translation, 'title') }}</div>
        <div v-for="e in program.days" class="programme-item">
          <div class="programme-item-day">{{ getTranslateFromItem(e.translation, 'title') }}</div>
          <div class="programme-item-content">
            <ol
                type="1"
            >
              <li
                v-for="(item, i) in e.programme"
                class="programme-item-content-item"
              >
                <div class="programme-item-content-item-title">{{ getTranslateFromItem(item.translation, 'title') }}</div>
                <div class="programme-item-content-item-speaker" v-if="item.general.speaker">{{ getTranslateFromItem(item.translation, 'speaker') }}</div>
                <div class="programme-item-content-item-modules">{{ getTranslateFromItem(item.translation, 'modules') }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="e in targets"
        class="page-section-wrapper marketing-section-analytics-wrapper"
        :class="{
          'marketing-section-analytics-wrapper__reverse': e.general.reverse,
        }"
    >
      <div
          class="page-section-inner marketing-section-analytics"
      >
        <div class="marketing-section-analytics-image">
          <img :src="e.general.photo" alt="">
        </div>
        <div class="marketing-section-analytics-info">
          <div class="marketing-section-analytics-info-title">
            {{ getTranslateFromItem(e.translation, 'title') }}
          </div>
          <div class="marketing-section-analytics-info-description" v-html="getTranslateFromItem(e.translation, 'description')"></div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarSpeakersTitle') }}</div>
        <CarouselWebinar :speakers="speakers"></CarouselWebinar>
      </div>
    </div>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('webinarPartnersTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item" v-for="e in partners">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
      </div>
    </div>
    <div id="regSection" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarFormTitle') }}</div>
        <div id="hubspotForm" ref="hubspot-form1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import webinarImage from '@/assets/images/webinar/webinar.jpg';
import seoImage from '@/assets/images/webinar/feb22.png';
import speakerMatveevaImage from '@/assets/images/speakers/matveeva.jpg';
import speakerBezbImage from '@/assets/images/speakers/bezborodova.jpeg';
import speakerKladImage from '@/assets/images/speakers/kladovikova.jpg'
import speakerAnatoliyImage from '@/assets/images/speakers/anatoliy.jpg';
import travellineImage from '@/assets/images/partners/travelline.png';
import frontdeskImage from '@/assets/images/partners/frontdesk.png';
import hrImage from '@/assets/images/partners/hotelreport.png';
import ostrovokImage from '@/assets/images/partners/ostrovok.png';
import wtImage from '@/assets/images/partners/wt.png';
import horecaImage from '@/assets/images/partners/horeca.png';
import {mapGetters} from "vuex";
import CarouselWebinar from "@/components/CarouselWebinar";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Webinar",
  computed: {
    ...mapGetters([
        'getTranslate',
        'getTranslateFromItem',
        'lang',
        'integrations',
    ]),
    descriptionBefore() {
      return this.getTranslateFromItem(this.description.translation, 'before');
    },
  },
  watch: {
    lang() {
      this.appendForm();
    },
  },
  mounted() {
    this.appendForm();
  },
  methods: {
    appendForm() {
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "e83e8384-3418-4178-b513-5d7c207d36c3",
            target: '#hubspotForm',
          });
        }
      })
    },
  },
  components: {
    CarouselWebinar,
    BaseButton
  },
  data: () => ({
    webinarImage,
    main: {
      translation: {
        ru: {
          title: 'Web Revenue Workshop',
          date: 'Для отелей от 20 номеров \n Онлайн | 10 ноября | 15:00 - 17:00'
        },
        en: {
          title: 'Web Revenue Workshop',
          date: 'Для отелей от 20 номеров \n Онлайн | 10 ноября | 15:00 - 17:00'
        },
      },
    },
    description: {
      list: [
      ],
      translation: {
        ru: {
          title: 'О чем наша встреча',
          before: '<p>Подробная аналитика осеннего спроса. Новые вызовы: тренды, последствия и  возможности. Как сегодня важна своевременная реакция на изменения. Как извлечь необходимые полезности из любого периода неопределенности. Практические  инструменты и решения, которые помогут отелям развиваться, а не выживать. Новый Год и прогнозы — станут ли в 2022 году праздники, как всегда, палочкой-выручалочкой для отелей. И что нужно сделать, чтобы палочка-выручалочка сработала.</p>' +
          '<p>10 ноября hotellab, совместно с TravelLine и Ostrovok.ru, проводит воркшоп, посвященный аналитике спроса, находящегося под давлением последних турбулентностей на гостиничном рынке. Будут представлены: подробный анализ показателей рынка в осенние месяцы, сравнительное поведение спроса за последние годы, новые инструменты и практические решения в помощь отельерам.</p>' +
          '<p>Тема: Осенняя аналитика спроса. Последние тренды: сравнительный анализ 2022 года и предшествующих лет. Адаптация под сложившуюся ситуацию — новые IT - инструменты и решения.</p>' +
          '<p>Для отелей номерным фондом 20 номеров и более.</p>' +
          '<p>Организаторы: hotellab.io, TravelLine, Ostrovok.ru</p>' +
          '<p>Вы узнаете:</p>' +
          '<ul><li>аналитические данные о развитии ситуации с поведением спроса в свете текущих триггерных событий</li>' +
          '<li>анализ поведения основных показателей отельной индустрии в последний трехлетний период</li>' +
          '<li>самые актуальные тренды онлайн-продаж</li>' +
          '<li>обзор прогнозов на зиму и новогодние праздники</li></ul>',
          after: ''
        },
        en: {
          title: 'О чем наша встреча',
          before: '<p>Подробная аналитика осеннего спроса. Новые вызовы: тренды, последствия и  возможности. Как сегодня важна своевременная реакция на изменения. Как извлечь необходимые полезности из любого периода неопределенности. Практические  инструменты и решения, которые помогут отелям развиваться, а не выживать. Новый Год и прогнозы — станут ли в 2022 году праздники, как всегда, палочкой-выручалочкой для отелей. И что нужно сделать, чтобы палочка-выручалочка сработала.</p>' +
          '<p>10 ноября hotellab, совместно с TravelLine и Ostrovok.ru, проводит воркшоп, посвященный аналитике спроса, находящегося под давлением последних турбулентностей на гостиничном рынке. Будут представлены: подробный анализ показателей рынка в осенние месяцы, сравнительное поведение спроса за последние годы, новые инструменты и практические решения в помощь отельерам.</p>' +
          '<p>Тема: Осенняя аналитика спроса. Последние тренды: сравнительный анализ 2022 года и предшествующих лет. Адаптация под сложившуюся ситуацию — новые IT - инструменты и решения.</p>' +
          '<p>Для отелей номерным фондом 20 номеров и более.</p>' +
          '<p>Организаторы: hotellab.io, TravelLine, Ostrovok.ru</p>' +
          '<p>Вы узнаете:</p>' +
          '<ul><li>аналитические данные о развитии ситуации с поведением спроса в свете текущих триггерных событий</li>' +
          '<li>анализ поведения основных показателей отельной индустрии в последний трехлетний период</li>' +
          '<li>самые актуальные тренды онлайн-продаж</li>' +
          '<li>обзор прогнозов на зиму и новогодние праздники</li></ul>',
          after: ''
        },
      },
    },
    program: {
      days: [
        {
          general: {
            id: 0,
          },
          programme: [
            {
              general: {
                id: 0,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '15:00 - 15:40 мск Аналитика тенденций спроса. Осень 2022 в Москве, Санкт-Петербурге, Краснодарском крае и других регионах',
                  speaker: 'Татьяна Матвеева, менеджер развития клиентов hotellab',
                  modules: 'Основные тезисы: \n' +
                  '- Изменение темпов продаж и отмен, глубины бронирования после 21 сентября 2022 \n' +
                  '- Показатели отелей за октябрь текущего года в сравнении с 2019 и 2021 годами \n' +
                  '- Тенденции поведения рынка на ноябрь-декабрь 2022 и новогодние праздники'
                },
                en: {
                  title: '15:00 - 15:40 мск Аналитика тенденций спроса. Осень 2022 в Москве, Санкт-Петербурге, Краснодарском крае и других регионах',
                  speaker: 'Татьяна Матвеева, менеджер развития клиентов hotellab',
                  modules: 'Основные тезисы: \n' +
                  '- Изменение темпов продаж и отмен, глубины бронирования после 21 сентября 2022 \n' +
                  '- Показатели отелей за октябрь текущего года в сравнении с 2019 и 2021 годами \n' +
                  '- Тенденции поведения рынка на ноябрь-декабрь 2022 и новогодние праздники'
                },
              },
            },
            {
              general: {
                id: 1,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '15:40 - 16:20 мск Аналитика осеннего сезона: данные Ostrovok.ru',
                  speaker: 'Ксения Безбородова, старший менеджер по развитию бизнеса Ostrovok.ru, эксперт в области revenue менеджмента.',
                  modules: 'Основные тезисы: \n' +
                  '- Изменения и тренды в бронированиях\n' +
                  '- Рекомендации Ostrovok.ru по адаптации к изменениям'
                },
                en: {
                  title: '15:40 - 16:20 мск Аналитика осеннего сезона: данные Ostrovok.ru',
                  speaker: 'Ксения Безбородова, старший менеджер по развитию бизнеса Ostrovok.ru, эксперт в области revenue менеджмента.',
                  modules: 'Основные тезисы: \n' +
                  '- Изменения и тренды в бронированиях\n' +
                  '- Рекомендации Ostrovok.ru по адаптации к изменениям'
                },
              },
            },
            {
              general: {
                id: 2,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '16:20 - 17:00 мск Тренды онлайн-продаж осенью — 2022',
                  speaker: 'Анастасия Кладовикова, эксперт TravelLine по онлайн-продажам',
                  modules: 'Основные тезисы: \n' +
                  '- Как изменилась ситуация на рынке: статистика продаж с 2020 по 2022 год \n' +
                  '- Инструменты, которые помогают гибко управлять ценами \n' +
                  '- Новинки TravelLine для продажи услуг и работы с гостями'
                },
                en: {
                  title: '16:20 - 17:00 мск Тренды онлайн-продаж осенью — 2022',
                  speaker: 'Анастасия Кладовикова, эксперт TravelLine по онлайн-продажам',
                  modules: 'Основные тезисы: \n' +
                  '- Как изменилась ситуация на рынке: статистика продаж с 2020 по 2022 год \n' +
                  '- Инструменты, которые помогают гибко управлять ценами \n' +
                  '- Новинки TravelLine для продажи услуг и работы с гостями'
                },
              },
            },
          ],
          translation: {
            ru: {
              title: '',
            },
            en: {
              title: '',
            },
          },
        },
      ],
      translation: {
        ru: {
          title: 'Программа воркшопа:',
          description: '',
        },
        en: {
          title: 'Программа воркшопа:',
          description: '',
        },
      },
    },
    partners: [
      {
        "id": 0,
        "generic": {
          "photo": travellineImage,
          "url": "https://www.travelline.ru/",
        },
      },
      {
        "id": 1,
        "generic": {
          "photo": hrImage,
          "url": "https://hotel.report/",
        },
      },
      {
        "id": 2,
        "generic": {
          "photo": "https://hotellab.io/media/hotelier.png",
          "url": "https://hotelier.pro",
        },
      },
      {
        "id": 3,
        "generic": {
          "photo": ostrovokImage,
          "url": "https://ostrovok.ru/",
        },
      },
      {
        "id": 4,
        "generic": {
          "photo": wtImage,
          "url": "https://welcometimes.ru/",
        },
      },
      {
        "id": 5,
        "generic": {
          "photo": horecaImage,
          "url": "https://horeca.estate/",
        },
      },
      {
        "id": 6,
        "generic": {
          "photo": frontdeskImage,
          "url": "https://www.frontdesk.ru/",
        },
      },
    ],
    targets: [
      {
        general: {
          id: 0,
          photo: 'https://hotellab.io/media/webinarForWho.webp',
        },
        translation: {
          ru: {
            title: 'Для кого?',
            description: 'Для всех отельеров и собственников отелей и апартаментов объёмом 20 номеров и более, которые стремятся быть в курсе последних трендов и стратегий развития индустрии гостеприимства. \n' +
            'Для тех, кто хочет быть всегда финансово успешными и не зависеть от периодов неопределенности. \n',
          },
          en: {
            title: 'For Who?',
            description: 'Для всех отельеров и собственников отелей и апартаментов объёмом 20 номеров и более, которые стремятся быть в курсе последних трендов и стратегий развития индустрии гостеприимства. \n' +
            'Для тех, кто хочет быть всегда финансово успешными и не зависеть от периодов неопределенности. \n',
          },
        },
      },
      {
        general: {
          reverse: true,
          id: 1,
          photo: 'https://hotellab.io/media/webinarForWhy.jpg',
        },
        translation: {
          ru: {
            title: 'Что даст?',
            description: '<ul><li>Понимание текущей ситуации в гостиничной индустрии на основе глубоких аналитических данных и подробной сравнительной аналитики</li>' +
            '<li>Инструменты на основе IT технологий для устойчивой деятельности отеля, которые позволят получать максимум прибыли в любой ситуации</li>' +
            '<li>Возможности быть в тренде и впереди конкурентов</li></ul>'
          },
          en: {
            title: 'What Can You Get?',
            description: '<ul><li>Понимание текущей ситуации в гостиничной индустрии на основе глубоких аналитических данных и подробной сравнительной аналитики</li>' +
            '<li>Инструменты на основе IT технологий для устойчивой деятельности отеля, которые позволят получать максимум прибыли в любой ситуации</li>' +
            '<li>Возможности быть в тренде и впереди конкурентов</li></ul>'
          },
        },
      },
    ],
    speakers: [
      {
        general: {
          photo: speakerAnatoliyImage,
        },
        translation: {
          ru: {
            name: 'Анатолий Бурдаков',
            description: 'Основатель hotellab.io',
          },
          en: {
            name: 'Anatoly Burdakov',
            description: 'Founder of hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerMatveevaImage,
        },
        translation: {
          ru: {
            name: 'Татьяна Матвеева',
            description: 'Менеджер развития клиентов hotellab',
          },
          en: {
            name: 'Tatiana Matveeva',
            description: 'Менеджер развития клиентов hotellab',
          },
        },
      },
      {
        general: {
          photo: speakerBezbImage,
        },
        translation: {
          ru: {
            name: 'Ксения Безбородова',
            description: 'Старший менеджер по развитию бизнеса Ostrovok.ru',
          },
          en: {
            name: 'Ксения Безбородова',
            description: 'Старший менеджер по развитию бизнеса Ostrovok.ru',
          },
        },
      },
      {
        general: {
          photo: speakerKladImage,
        },
        translation: {
          ru: {
            name: 'Анастасия Кладовикова',
            description: 'Эксперт TravelLine по онлайн-продажам',
          },
          en: {
            name: 'Анастасия Кладовикова',
            description: 'Эксперт TravelLine по онлайн-продажам',
          },
        },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
@media (max-width: $breakpoint-mobile) {
  .marketing-section-analytics-info-description {
    text-align: left;
  }
}

.page-section-wrapper.pt-0 {
  padding-top: 0;
}

.page-section-reg-wrapper {
  padding: 30px;
}

.page-section-reg-wrapper span {
  margin-right: 20px;
}

.demo-main-photo-title-date {
  white-space: pre-wrap;
}

.programme-item {
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  background-color: #F9FAFF;

  .page-section-reg-wrapper {
    padding: 30px;
  }

  .page-section-reg-wrapper span {
    margin-right: 20px;
  }

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }


  &-day {
    font-weight: 900;
    font-size: 30px;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    background-color: #5d5ca8;
    color: white;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      text-align: center;
      padding: 25px;
      font-size: 24px;
    }
  }

  &-content {
    white-space: pre-wrap;
    font-size: 21px;
    line-height: 30px;
    padding: 40px;
    flex-grow: 1;

    @media (max-width: $breakpoint-tablet) {
      padding: 25px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }

    ol {
      margin: 0;
    }

    &-item {
      margin-bottom: 20px;

      &-title {
        font-weight: 900;
      }

      &-speaker {
        font-size: 0.9em;
        font-style: italic;
        opacity: .7;
      }

      &-modules {
        margin-top: 10px;
      }

      &::marker {
        font-weight: 900;
      }
    }
  }
}
.demo-main-photo {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(50vh - 80px);
    }
  }

  &-title {
    font-size: 60px;
    line-height: 72px;
    max-width: 90%;
    width: 1000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    color: white;
    padding: 20px 70px;
    background-color: #5d5ca8;

    &-date {
      font-size: 30px;
      line-height: 36px;
      margin-top: 10px;


      @media (max-width: $breakpoint-desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 10px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 22px;
            line-height: 28px;
            margin-top: 5px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
      }
    }


    @media (max-width: $breakpoint-desktop) {
      font-size: 50px;
      line-height: 60px;
      max-width: 700px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 36px;
        line-height: 44px;
        padding: 20px 40px;
        max-width: 500px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 28px;
          line-height: 34px;
          width: 90%;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.webinar-target {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column !important;
  }

  &-title {
    width: 50%;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 900;
    font-size: 60px;
    text-align: left;
    display: flex;
    justify-content: flex-start;

    &-inner {
      background-color: #5d5ca8;
      color: white;
      padding: 10px 30px;
    }
  }

  &-description {
    width: 50%;
    font-size: 25px;
    box-sizing: border-box;
    padding: 40px;
    text-align: right;
    line-height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
  }
}

.webinar-target__active {
  flex-direction: row;

  .webinar-target-title {
    text-align: right;
    justify-content: flex-end;
  }

  .webinar-target-description {
    text-align: left;
  }
}

.marketing-section-analytics-wrapper__reverse {
  background-color: transparent !important;
  .marketing-section-analytics {
    flex-direction: row-reverse;
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }

    &-title {
    }
  }
}

.marketing-section-analytics-wrapper {
  background-color: #F9FAFF;

  .marketing-section-analytics {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: column;
    }

    &-image {
      width: 50%;
      box-sizing: border-box;
      padding-right: 32px;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 20px;
      }

      @media (max-width: $breakpoint-desktop) {
        padding-right: 24px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            width: 70%;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }

    &-info {
      flex: 1;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      flex-shrink: 0;
      font-weight: 900;
      font-size: 40px;
      padding-left: 32px;
      line-height: 52px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        font-size: 34px;
        line-height: 40px;
        padding-left: 24px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 36px;
          padding-left: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;
          margin-bottom: 12px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }

      &-description {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        width: 80%;
        white-space: pre-wrap;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }
  }
}
</style>
