<template><div>
  <div v-if="withTitle" class="hubspot-title">Ready to start? Sign up for an audit</div>
  <div class="sign-up">
    <div class="sign-up__info">
      <h2>{{ getTranslate( 'demoTextTitle' ) }}</h2>
      <ul>
        <li>
          {{ getTranslate( 'demoText1' ) }}
        </li>
        <li>
          {{ getTranslate( 'demoText2' ) }}
        </li>
        <li>
          {{ getTranslate( 'demoText3' ) }}
        </li>
        <li>
          {{ getTranslate( 'demoText4' ) }}
        </li>
        <li>
          {{ getTranslate( 'demoText5' ) }}
        </li>
      </ul>
      <p>{{ getTranslate('demoDesc') }}</p>
      <p><b>{{ getTranslate('demoDescBold') }}</b></p>  
    </div>
    <div v-if="lang === 'ru'" class="meetings-iframe-form-container" id="hubspotForm" ref="hubspot-form1"></div>
    <div v-if="lang === 'en'" class="meetings-iframe-container" data-src="https://meetings.hubspot.com/ntsitsoulis?embed=true"></div>
  </div>
  </div>
</template>
  
<script>
  import {mapGetters} from "vuex";
  
  export default {
    name: "BaseSignUp",
    watch: {
      lang() {
        this.appendScript();
      },
    },
    computed: {
      ...mapGetters([
        'getTranslate',
        'lang',
      ]),
    },
    props: {
      withTitle: {
        type: Boolean,
        required: false
      },
    },
    mounted() {
      this.appendScript();
    },
    methods: {
      appendScript() {
        if (this.lang === "en") {
          const script = document.createElement("script");
          script.src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
          document.body.appendChild(script);
        }

        if (this.lang === "ru") {
          let formId = "0a9d92ca-7d25-45ae-9f24-5d8160db10b9";
          this.$refs["hubspot-form1"].innerHTML = '';
          const script = document.createElement("script");
          script.src="https://js.hsforms.net/forms/v2.js";
          document.body.appendChild(script);
          script.addEventListener("load", () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              region: "na1",
              portalId: "7975392",
              formId,
              target: '#hubspotForm',
            });
          }});
        }
      },
    },
  }
</script>
  
<style lang="scss" scoped>
  h2 {
    padding-left: 32px;
    font-size: 20px;
    line-height: 40px;
  }
  
  .meetings-iframe-container {
    width: 50%;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }
  .meetings-iframe-form-container {
    width: 60%;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }
  .hubspot-title {
    color: #bc1a1a;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 40px;
    margin-top: -90px;
  
    @media (max-width: $breakpoint-tablet) {
      font-size: 24px;
      margin-top: 0;
    }
  }
  
  .sign-up {
    $gap: 60px;
    display: flex;
  
    @include adaptiveMobile {
      flex-direction: column;
    }
  
    &__info, &__form {
      width: calc((100% / 2) - #{$gap});
  
      margin-right: $gap;
  
      @include adaptiveTablet {
        width: calc(50% - #{$gap});
      }
  
      @include adaptiveMobile {
        width: 100%;
  
        margin-right: 0;
      }
    }
  
    &__info {
      white-space: pre-line;
    }
  
    &__info {
      font-size: 16px;
      line-height: 30px;
  
      @include adaptiveMobile {
        font-size: 16px;
  
        margin-bottom: $gap;
      }
  
      &-text {
        margin-bottom: 16px;
      }
    }
  } 
</style>  