import client from "@/api/client";

async function fetchNewsById(id) {
    let result

    try {
        result = await client.get('news/' + id)
    } catch (e) {
        console.error(e)
        throw e
    }

    return result.data
}

export {
    fetchNewsById
}