<template>
    <BasePage>
        <PageSection
          :max-width="1050"
        >
          <ImageWithInfo
            class="head"
            :image-url="require('@/assets/images/simulator/simulator_main.png')"
            :title="getTranslate('simulatorMainSlide')"
            large-image
          />
          
          <div class="main-button">
            <a href="#hubspot" class="main-button-button">
                <BaseButton filled>
                    {{getTranslate('buttonForSimulator')}}
                </BaseButton>
            </a>
          </div>
        </PageSection>

        <PageSection primary>
            <div id="simulator-section-integration">
                <div id="simulator-section-integration-image">
                    <img src="../assets/images/simulator/simulator_purple.png" alt="">
                </div>
                <div id="simulator-section-integration-info">
                    <div id="simulator-section-integration-info-description">
                        <ul>
                            <li>{{ getTranslate('simulatorPurpleText1') }}</li>
                            <li>{{ getTranslate('simulatorPurpleText2') }}</li>
                            <li>{{ getTranslate('simulatorPurpleText3') }}</li> 
                        </ul>
                    </div>
                </div>
            </div>
        </PageSection>
        <PageSection :title="getTranslate('simulatorKeyTitle')">
            <div class="simulator-grid">
                <div class="simulator-grid-item">
                    <div class="simulator-grid-item-desc">
                        <div class="simulator-grid-item-desc-title">
                            {{ getTranslate('simulatorKeyText1') }}
                        </div>
                    </div>
                </div>
                <div class="simulator-grid-item">
                    <div class="simulator-grid-item-desc">
                        <div class="simulator-grid-item-desc-title">
                            {{ getTranslate('simulatorKeyText2') }}
                        </div>
                    </div>
                </div>
                <div class="simulator-grid-item">
                    <div class="simulator-grid-item-desc">
                        <div class="simulator-grid-item-desc-title">
                            {{ getTranslate('simulatorKeyText3') }}
                        </div>
                    </div>
                </div>
                <div class="simulator-grid-item">
                    <div class="simulator-grid-item-desc">
                        <div class="simulator-grid-item-desc-title">
                            {{ getTranslate('simulatorKeyText4') }}
                        </div>
                    </div>
                </div>
                <div class="simulator-grid-item">
                    <div class="simulator-grid-item-desc">
                        <div class="simulator-grid-item-desc-title">
                            {{ getTranslate('simulatorKeyText5') }}
                        </div>
                    </div>
                </div>
            </div>
        </PageSection>

        <PageSection>
            <div class="page-section-inner" id="points">
                <div class="page-section-inner-title">{{ getTranslate('simulatorWorkTitle') }}</div>
                <div id="points-item">
                    <div id="points-item-image">
                        <img src="../assets/images/simulator/1.svg" alt="">
                    </div>
                    <div id="points-item-desc">
                        <div id="points-item-desc-text">
                            {{ getTranslate('simulatorWorkText1') }}
                        </div>
                    </div>
                </div>
                <div id="points-item">
                    <div id="points-item-image">
                        <img src="../assets/images/simulator/2.svg" alt="">
                    </div>
                    <div id="points-item-desc">
                        <div id="points-item-desc-text">
                            {{ getTranslate('simulatorWorkText2') }}
                        </div>
                    </div>
                </div>
                <div id="points-item">
                    <div id="points-item-image">
                        <img src="../assets/images/simulator/3.svg" alt="">
                    </div>
                    <div id="points-item-desc">
                        <div id="points-item-desc-text">
                            {{ getTranslate('simulatorWorkText3') }}
                        </div>
                    </div>
                </div>
                <div id="points-item">
                    <div id="points-item-image">
                        <img src="../assets/images/simulator/4.svg" alt="">
                    </div>
                    <div id="points-item-desc">
                        <div id="points-item-desc-text">
                            {{ getTranslate('simulatorWorkText4') }}
                        </div>
                    </div>
                </div>
            </div>
        </PageSection>
        
        <PageSection id="hubspot">
            <HubspotForm
                class="hubspotform"
                :form-id="{
                ru: '464f60df-28c9-4156-991a-4a9224c0c514',
                en: 'cb326461-2353-4b17-91c7-ee02b5baf428',
                }"
            />
        </PageSection>
    </BasePage>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import ImageWithInfo from "@/components/widgets/ImageWithInfo";
import HubspotForm from "@/components/HubspotForm";
import {mapGetters} from "vuex";
import BasePage from "@/components/BasePage";
import PageSection from "@/components/PageSection";

export default {
  name: 'Simulator',
  computed: {
    ...mapGetters([
      'getTranslate',
      'lang',
      'images',
    ]),
  },
  components: {
    BaseButton,
    BasePage,
    HubspotForm,
    ImageWithInfo,
    PageSection
  },
}
</script>

<style lang="scss" scoped>
.page-section-inner :nth-child(odd){
  flex-direction: row-reverse;
  
  #points-item-desc {
    padding-right: 60px;
  }
}

.head {
  img {
    visibility: hidden;
  }
}

.main-button {
  display: flex;
  justify-content: center;

  &-button {
    width: 210px;
    padding-top: 60px;
    padding-left: 10%;

    @media (max-width: $breakpoint-tablet-v) {
    padding-left: 0;
    
    margin: auto;
  }

  }
}

.page-section-inner {
  padding-left: 14%;
  padding-right: 14%;

  margin-top: -90px;
  
  @media (max-width: $breakpoint-tablet-v) {
    margin-top: 0;
  }
  
  &-title {
    color: $primary;
    font-weight: 900;
    font-size: 38px;
    text-align: center;
    margin-bottom: 30px;
  }
}

#simulator-section-integration {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-tablet-v) {
    flex-direction: column;
  }

  &-image {
    width: 50%;
    box-sizing: border-box;
    padding-right: 32px;

    img {
      width: 100%;
    }

    @media (max-width: $breakpoint-desktop) {
      padding-right: 24px;

      @media (max-width: $breakpoint-tablet) {
        padding-right: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 70%;
          max-width: 400px;
          padding: 0;
          margin-bottom: 20px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
      }
    }
  }

  &-info {
    width: 50%;
    color: white;

    @media (max-width: $breakpoint-tablet-v) {
      width: 100%;
    }

    &-description {
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0;
        font-weight: 400;

        @media (max-width: $breakpoint-desktop) {
          font-size: 22px;
          line-height: 30px;
          margin-left: -30px;
          @media (max-width: $breakpoint-tablet) {
            width: 100%;
            font-size: 20px;
            line-height: 28px;
          }
        }

        ul {
          width: 80%;
          list-style-type: none;

          @media (max-width: $breakpoint-tablet) {
            width: 90%;

            @media (max-width: $breakpoint-tablet-v) {
              width: 100%;
            }      
          }

          li {
            padding: 20px;
            box-sizing: border-box;

            @media (max-width: $breakpoint-mobile) {
              padding: 15px;
              margin: 10px 0;
            }

          }
        }
    }
  }
}

.simulator-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &-item {
    position: relative;
    background-color: #f9faff;
    width: calc(80%/3);
    height: 160px;
    top: 50%;
    margin: 15px 10px;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 13px 1px rgb(210, 210, 210);

    @media (max-width: $breakpoint-desktop) {
      flex-direction: column;
      height: inherit;

      @media (max-width: $breakpoint-tablet-v) {
        width: 100%;
        height: 100px;
        margin-left: 0;
        flex-direction: row;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          flex-direction: column;
          height: auto;
        }
      }
    }

    &-desc {
      color: #2F3238;
      text-align: center;
      flex-basis: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 30px;
      flex-direction: column;

      &-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        

        @media (max-width: $breakpoint-tablet) {
          font-size: 20px;
          line-height: 28px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 20px;
            line-height: 28px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}

#points-item {
  display: flex;
  align-items: center;
  
  @media (max-width: $breakpoint-tablet-v) {
    flex-direction: column;
  }

  &-image {
    width: 40%;
    box-sizing: border-box;
    padding-right: 32px;
    display: flex;
    flex-direction: column;

    @media (max-width: $breakpoint-desktop) {
      padding-right: 24px;

      @media (max-width: $breakpoint-tablet) {
        padding-right: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 70%;
          padding: 0;
          margin-bottom: 20px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 400px;
          }
        }
      }
    }
  }

  &-desc {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: 32px;
    line-height: 40px;

    @media (max-width: $breakpoint-desktop) {
      line-height: 35px;
      padding-left: 24px;
      padding-bottom: 40px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 24px;
        line-height: 30px;
        padding-left: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 24px;
          line-height: 30px;
          width: 100%;
          max-width: 550px;
          text-align: center;
          flex-direction: column;
          align-items: center;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 24px;
            line-height: 25px;
            padding: 15px 10px;
          }
        }
      }
    }

    &-text {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0;
      box-sizing: border-box;
      font-weight: 400;

      @media (max-width: $breakpoint-tablet-v) {

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          padding: 0 5px;
        }
      }
    }
  }
}

.hubspotform {
  margin: auto;
  width: 80%;
}
</style>