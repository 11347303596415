<template>
  <BasePage>
    <!--Carousel-->
    <PageSection v-if="carousels.benchmarking.length > 0">
      <CarouselBig
        :slides="carousels.benchmarking"
        image-contain
        big-image
      />
    </PageSection>
    <!--Tips-->
    <PageSection
      v-for="(tip, idx) in tips"
      :key="`tip${idx}`"
    >
      <ImageWithInfo
        v-bind="imageItemBind(tip)"
        :rtl="idx % 2 === 1"
      />
    </PageSection>
    <!--Features-->
    <PageSection :title="translate(features.title)">
      <BaseGrid :items="features.items">
        <template
          v-for="(item, idx) in features.items"
          v-slot:[`item-${idx}`]="item"
        >
          <BaseCard
            :title="translate(item.title)"
            :icon-name="item.icon"
          />
        </template>
      </BaseGrid>
    </PageSection>
    <!--Feedback-->
    <PageSection primary>
      <ImageWithInfo
        v-bind="imageItemBind(feedback)"
        circle-image
        image-cover
        light
        rtl
      />
    </PageSection>
    <PageSection :title="translate('benchmarkingHubspotTitle')">
      <HubspotForm
        :form-id="{
          ru: '4d8304cd-70cb-4d5f-871d-f680c32b3b43',
          en: '4d8304cd-70cb-4d5f-871d-f680c32b3b43',
        }"
      />
    </PageSection>
  </BasePage>
</template>

<script>
import PageSection from "@/components/PageSection";
import BasePage from "@/components/BasePage";
import ImageWithInfo from "@/components/widgets/ImageWithInfo";
import {mapGetters} from "vuex";
import CarouselBig from "@/components/CarouselBig";
import BaseGrid from "@/components/BaseGrid";
import BaseCard from "@/components/BaseCard";
import HubspotForm from "@/components/HubspotForm";

function createImageBlockData({slug, image}) {
  const PAGE = 'benchmarking'

  return {
    title: PAGE + slug + 'Title',
    description: PAGE + slug + 'Text',
    imageUrl: image,
  }
}

export default {
  name: "Benchmarking",
  components: {
    PageSection,
    BasePage,
    ImageWithInfo,
    CarouselBig,
    BaseGrid,
    BaseCard,
    HubspotForm
  },
  data() {
    return {
      tips: [
        createImageBlockData({
          slug: 'Analysis',
          image: 'benchmarking_img1.png'
        }),
        createImageBlockData({
          slug: 'Index',
          image: 'benchmarking2.jpg'
        }),
      ],
      feedback: createImageBlockData({
        slug: 'Feedback',
        image: 'benchmarkingReviewPhoto.jpg'
      }),
      features: {
        title: 'benchmarkingFeaturesTitle',
        items: [
          {
            title: 'benchmarkingFeature1',
            icon: 'grossBook',
          },
          {
            title: 'benchmarkingFeature2',
            icon: 'chart'
          },
          {
            title: 'benchmarkingFeature3',
            icon: 'clock'
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      translate: 'getTranslate',
      carousels: 'carousels',
      image: 'getImage',
    }),
  },
  methods: {
    imageItemBind({imageUrl, title, description}) {
      return {
        description: this.translate(description),
        title: this.translate(title),
        imageUrl: this.image(imageUrl)
      }
    },
  },
}
</script>
