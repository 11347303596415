var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hl-slick-carousel-wrapper",class:{'hl-slick-carousel-wrapper__wo-pad': _vm.slides.length === 1,}},[_c('div',[_c('VueSlickCarousel',_vm._b({ref:"carousel",scopedSlots:_vm._u([{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"hl-carousel-big-arrow hl-carousel-big-arrow-prev"},[_c('img',{attrs:{"src":require("@/assets/icons/chevron-left.svg"),"alt":""}})])]}},{key:"nextArrow",fn:function(arrowOption){return [_c('div',{staticClass:"hl-carousel-big-arrow hl-carousel-big-arrow-next"},[_c('img',{attrs:{"src":require("@/assets/icons/chevron-right.svg"),"alt":""}})])]}}])},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.slides),function(e){return _c('div',{key:e.id},[_c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"carousel-item-description"},[_c('div',{staticClass:"carousel-item-description-title"},[_vm._v(" "+_vm._s(_vm.getTranslateFromItem(e.translation, 'title'))+" ")]),_c('div',{staticClass:"carousel-item-description-text"},[_vm._v(" "+_vm._s(_vm.getTranslateFromItem(e.translation, 'description'))+" ")]),(!e.generic.demo_call_to_action && e.generic.link && e.generic.link.startsWith('/'))?_c('router-link',{attrs:{"to":`/${_vm.lang}${e.generic.link}`}},[_c('BaseButton',{attrs:{"filled":""}},[_vm._v(" "+_vm._s(_vm.getTranslate('homeSolutionsButtonText'))+" ")])],1):_vm._e(),(!e.generic.demo_call_to_action && e.generic.link && e.generic.link.startsWith('http'))?_c('a',{attrs:{"to":`${e.generic.link}`}},[_c('BaseButton',{attrs:{"filled":""}},[_vm._v(" "+_vm._s(_vm.getTranslate('homeSolutionsButtonText'))+" ")])],1):_vm._e(),(e.generic.demo_call_to_action)?_c('router-link',{attrs:{"to":{name: 'Demo',}}},[_c('BaseButton',{attrs:{"filled":""}},[_vm._v(" "+_vm._s(_vm.getTranslate('buttonForADemo'))+" ")])],1):_vm._e()],1),(e.generic.orientation === 'landscape')?_c('div',{staticClass:"carousel-item-image-landscape",style:({
              background: `url('${e.generic.photo}') 50% 50% no-repeat`,
              backgroundSize: (_vm.imageContain ? 'contain' : 'cover') + '!important',
            }),attrs:{"data-big-image":_vm.bigImage}}):_c('div',{staticClass:"carousel-item-image-portrait",style:({
              background: `url('${e.generic.photo}') 50% 50% no-repeat`,
              backgroundSize: (_vm.imageContain ? 'contain' : 'cover') + '!important',
            }),attrs:{"data-big-image":_vm.bigImage}})])])}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }