import { render, staticRenderFns } from "./WebinarApr24.vue?vue&type=template&id=bfd79088&scoped=true&"
import script from "./WebinarApr24.vue?vue&type=script&lang=js&"
export * from "./WebinarApr24.vue?vue&type=script&lang=js&"
import style0 from "./WebinarApr24.vue?vue&type=style&index=0&id=bfd79088&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfd79088",
  null
  
)

export default component.exports