import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueCarousel from 'vue-carousel';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYandexMetrika from 'vue-yandex-metrika'
import './settings'
import {GOOGLE_MAP_KEY} from "@/settings";

Vue.use(VueYandexMetrika, {
  id: 35218475,
  router: router,
  options: {clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor: true,},
  env: process.env.NODE_ENV
  // other options
})

Vue.use(VueCarousel);

Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAP_KEY,
    libraries: 'places',
  },
  installComponents: true
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  render: h => h(App)
}).$mount()
