<template>
  <div class="hl-slick-carousel-multi-wrapper">
    <div>
      <VueSlickCarousel ref="carousel" v-bind="settings">
        <div v-for="e in speakers" tabindex="-1" role="tabpanel" aria-hidden="true">
          <div class="speaker-item">
            <div class="speaker-item-inner">
              <div
                class="speaker-item-inner-photo"
                :style="{
                  background: `url('${e.general.photo}') 50% 50%`,
                  backgroundSize: 'cover',
                }"
              ></div>
              <div class="speaker-item-inner-desc">
                <div class="speaker-item-inner-desc-name">{{ getTranslateFromItem(e.translation, 'name') }}</div>
                <div class="speaker-item-inner-desc-description">{{ getTranslateFromItem(e.translation, 'description') }}</div>
              </div>
            </div>
          </div>
        </div>
        <template #prevArrow="arrowOption">
          <div class="hl-carousel-big-arrow hl-carousel-big-arrow-prev">
            <img src="@/assets/icons/chevron-left.svg" alt="">
          </div>
        </template>
        <template #nextArrow="arrowOption">
          <div class="hl-carousel-big-arrow hl-carousel-big-arrow-next">
            <img src="@/assets/icons/chevron-right.svg" alt="">
          </div>
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";

export default {
  name: 'CarouselSmall',
  components: {
    VueSlickCarousel,
    BaseButton
  },
  computed: {
    ...mapGetters([
      'getTranslate',
      'getTranslateFromItem',
      'lang',
      'carousels',
      'trust',
      'team',
      'news',
      'images',
      'integrations',
    ]),
  },
  props: {
    speakers: Array,
  },
  methods: {
    showPrev() {
      this.$refs.carousel.prev();
    },
    showNext() {
      this.$refs.carousel.next();
    },
  },
  data: () => ({
    settings: {
      arrows: true,
      dots: false,
      infinite: true,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 955,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            // arrows: false,
            dots:true,
          },
        }
      ],
    },
  }),
}
</script>

<style lang="scss" scoped>
//variables
$arrow-full: 35px;
$arrow-desktop: 35px;
$arrow-tablet: 35px;
$arrow-tablet-v: 35px;
$arrow-mobile: 35px;
$space-for-arrow: 5px;


.hl-carousel-big-arrow {
  opacity: .8;
  width: 35px;
  height: 35px;
  img {
    width: 100%;
  }
}
.hl-carousel-big-arrow:before {
  display: none;
}
.hl-carousel-big-arrow-prev {
  left: -($arrow-full+$space-for-arrow);
}
.hl-carousel-big-arrow-next {
  right: -($arrow-full+$space-for-arrow);
}
.hl-slick-carousel-multi-wrapper {
  padding: 0 $arrow-full+$space-for-arrow;

  @media (max-width: $breakpoint-mobile) {
    padding: 0 0 25px;
  }
}
.carousel-multi-item {
  min-height: 380px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;

  @media (max-width: $breakpoint-mobile) {
    min-height: 300px;
  }
}

.speaker-item {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;

  .speaker-item-inner {
    border-radius: 15px;
    overflow: hidden;
    height: 350px;
    max-height: 90vh;
    position: relative;
    width: 100%;
    background-color: #5d5ca8;

    .speaker-item-inner-photo {
      z-index: 2;
      position: absolute;
      opacity: .5;
      height: 100%;
      width: 100%;
      //mask-image: linear-gradient(180deg, #000000 50% , #00000000);
    }

    &-desc {
      padding: 30px;
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      color: white;
      font-size: 18px;
      z-index: 10;

      &-name {
        font-size: 23px;
        font-weight: 900;
        margin-bottom: 10px;
      }
    }
  }
}
</style>