<template>
  <header
    id="header"
    :class="{'header__mobile-menu-open': isMobileMenuOpen,}"
  >
    <div id="header-inner">
      <router-link :to="{name: 'Home',}">
        <img
            src="@/assets/logo-full.svg"
            id="header-logo"
            width="160"
            alt="Logo"
        >
      </router-link>
      <div id="mobile-header-open-button" @click="setIsMobileMenuOpen(true)">
        <img src="@/assets/icons/menu.svg" alt="">
      </div>
      <div class="header-links">
        <HeaderLink
            v-for="link in links"
            v-if="checkVisibility(link)"
            :to="link.to"
            :colored="link.colored"
            :key="link.text"
            :sublinks="link.sublinks"
        >
          {{getTranslate(link.translateId)}}
        </HeaderLink>
      </div>
      <div class="header-buttons">
<!--        <div-->
<!--            @mouseover="isLangMenuOpen = false/*true*/"-->
<!--            @mouseleave="isLangMenuOpen = false"-->
<!--            id="change-lang"-->
<!--        >-->
<!--          <img-->
<!--              :src="lang === 'ru' ? ruIcon : enIcon"-->
<!--              alt=""-->
<!--          >-->
<!--          <TransitionFade>-->
<!--            <div-->
<!--                v-show="isLangMenuOpen"-->
<!--                class="other-lang"-->
<!--                @click="changeLanguage"-->
<!--            >-->
<!--              <img-->
<!--                  :src="lang === 'ru' ? enIcon : ruIcon"-->
<!--                  alt=""-->
<!--              >-->
<!--            </div>-->
<!--          </TransitionFade>-->
<!--        </div>-->
        <a href="https://app.hotellab.io">
          <BaseButton filled>
            {{getTranslate('buttonSignIn')}}
          </BaseButton>
        </a>
        <router-link :to="{name: 'Demo',}">
          <BaseButton
              filled
              class="header-buttons-demo"
          >
            {{getTranslate('buttonForADemo')}}
          </BaseButton>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import BaseButton from "./BaseButton";
import HeaderLink from "./HeaderLink";
import { links } from '@/ext.js';
import ruIcon from '@/assets/flag-ru.svg';
import enIcon from '@/assets/flag-uk.svg';
import TransitionFade from "@/components/TransitionFade";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BaseHeader",
  computed: {
    ...mapGetters([
      'getTranslate',
      'lang'
    ]),
  },
  props: {
    setIsMobileMenuOpen: {
      type: Function,
      required: true,
    },
    isMobileMenuOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseButton,
    HeaderLink,
    TransitionFade,
  },
  data:() => ({
    links,
    ruIcon,
    enIcon,
    isLangMenuOpen: false,
  }),
  methods: {
    ...mapActions([
        'setLang'
    ]),
    changeLanguage() {
      this.setLang(this.lang === 'ru' ? 'en' : 'ru');
      this.isLangMenuOpen = false;
    },
    checkVisibility(link) {
      return (this.lang === 'ru' && link.to.name !== 'Pricing') || this.lang === 'en';
    }
  },
}
</script>

<style lang="scss" scoped>
#header {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: $header-height-large-desktop;
  position: fixed;
  background-color: white;
  width: 100%;
  padding: 0 50px;
  z-index: 40;
  line-height: 16px;
  transition: .3s;
  top: 0;

  @media (max-width: $breakpoint-desktop) {
    padding: 0 25px;
    height: $header-height-desktop;
  }

  @media (max-width: $breakpoint-tablet) {
    padding: 0 15px;
    height: $header-height-tablet;
  }

  #header-inner {
    max-width: $max-width;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: row-reverse;
    }

    #mobile-header-open-button {
      width: 30px;
      height: 30px;
      display: none;
      cursor: pointer;

      @media (max-width: $breakpoint-tablet-v) {
        display: block;
      }

      img {
        width: 100%;
      }
    }

    #header-logo {
      width: 160px;
      margin-top: -16px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        width: 130px;
        margin-top: -10px;
      }

      @media (max-width: $breakpoint-tablet) {
        width: 120px;
        margin-top: -10px;
      }
    }

    .header-links {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      flex-grow: 0;

      @media (max-width: $breakpoint-tablet-v) {
        display: none;
      }
    }

    .header-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: $breakpoint-tablet-v) {
        display: none;
      }

      .header-buttons-demo {
        margin-left: 20px;
        transition: .3s;

        @media (max-width: $breakpoint-desktop) {
          margin-left: 12px;
        }

        @media (max-width: $breakpoint-tablet) {
          margin-left: 10px;
        }
      }

      #change-lang {
        margin-right: 40px;
        position: relative;
        transition: .3s;

        @media (max-width: $breakpoint-desktop) {
          margin-right: 24px;
        }

        @media (max-width: $breakpoint-tablet) {
          margin-right: 20px;
        }

        .other-lang {
          padding-top: 14px;
          position: absolute;
          width: 100%;

          img {
            width: 36px;
            border-radius: 4px;
            box-shadow: $shadow-default;
          }
        }

        img {
          width: 36px;
          border-radius: 4px;
          box-shadow: $shadow-default;
        }
      }
    }
  }
}
</style>
