import client from "@/api/client";

async function getEntities() {
    let result

    try {
        result = await client.get('entities')
    } catch (e) {
        console.error(e)
        throw e
    }

    return result.data
}

export {
    getEntities
}