<template>
  <EntitiesContext>
    <MenuMobile
        :is-mobile-menu-open="isMobileMenuOpen"
        :set-is-mobile-menu-open="setIsMobileMenuOpen"
    ></MenuMobile>
    <BaseHeader
        :isMobileMenuOpen="isMobileMenuOpen"
        :setIsMobileMenuOpen="setIsMobileMenuOpen"
    />
    <router-view></router-view>
    <BaseFooter></BaseFooter>
  </EntitiesContext>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import BaseFooter from "@/components/BaseFooter";
import MenuMobile from "@/components/MenuMobile";
import EntitiesContext from "@/components/EntitiesContext";
import {mapGetters} from "vuex";
import HomeUsp from "@/components/HomeUsp";
import BaseMain from "@/components/BaseMain";

export default {
  name: 'App',
  components: {
    BaseHeader,
    BaseFooter,
    MenuMobile,
    EntitiesContext,
  },
  computed: {
    ...mapGetters([
        'getTranslate',
        'lang'
    ]),
  },
  watch: {
    lang() {
      if (window.HubSpotConversations && window.HubSpotConversations.widget) {
        window.HubSpotConversations.resetAndReloadWidget();
      }
    },
  },
  mounted() {
    const initHubspot = () => new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src="https://js.hs-scripts.com/7975392.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Hubspot Error`));
    });

    initHubspot().then((resp) => {
        if (window.HubSpotConversations) {
            window.HubSpotConversations.resetAndReloadWidget();
        }
    });
  },
  data: () => ({
    isMobileMenuOpen: false,
  }),
  methods: {
    setIsMobileMenuOpen(val) {
      this.isMobileMenuOpen = val;
      let bodyNode = document.body || document.getElementsByTagName('body')[0];
      bodyNode.style.overflowY = val ? 'hidden' : 'auto';
    },
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: "HL-Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "HL-Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "HL-Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "HL-Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

html {
  height: 100%;
}

body {
  width: 100%;
  margin: 0;
  position: relative;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: 'HL-Roboto', sans-serif;
  min-height: 100%;
  letter-spacing: 0;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.page-section-title-center {
  font-weight: 900;
  text-align: center;
  font-size: 38px;
  line-height: 57px;
  color: #2F3238;
  margin-bottom: 40px;

  @media (max-width: $breakpoint-tablet) {
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 30px;

    @media (max-width: $breakpoint-tablet-v) {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 20px;

      @media (max-width: $breakpoint-mobile) {
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 34px;
      }
    }
  }
}

.slick-slide {
  padding: 20px 0;
}

.page-section-wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px $padding-edge-large-desktop;

  @media (max-width: $breakpoint-desktop) {
    padding: 60px $padding-edge-desktop;

    @media (max-width: $breakpoint-tablet) {
      padding: 60px $padding-edge-tablet;

      @media (max-width: $breakpoint-tablet-v) {
        padding: 40px $padding-edge-tablet;
      }
    }
  }

  .page-section-inner {
    width: 100%;
    max-width: $max-width;
  }
}

#app {
  flex-grow: 1;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-top: $header-height-large-desktop;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: $breakpoint-desktop) {
    padding-top: $header-height-desktop;

    @media (max-width: $breakpoint-tablet) {
      padding-top: $header-height-tablet;
    }
  }
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}
</style>
