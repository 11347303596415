<template>
  <BasePage>
    <!--Carousel-->
    <PageSection v-if="carousels.rate_shopper.length > 0">
      <CarouselBig
        :slides="carousels.rate_shopper"
        image-contain
        big-image
      />
    </PageSection>

    <div class="page-section-wrapper" id="rate-shopper-section-wrapper">
      <div class="page-section-inner" id="rate-shopper-section">
        <div id="rate-shopper-section-image">
          <div
            id="rate-shopper-section-image-inner"
            :style="{
              background: `url('https://hotellab.io/media/rs-image_vg9hZht.png') 50% 50%`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }"
          />
        </div>
        <div id="rate-shopper-section-info">
          <div id="rate-shopper-section-info-title">
            <!--Источник цен-->
          </div>
          <div id="rate-shopper-section-info-description">
            {{ translate('rateShopperTitle') }}
            <ul>
              <li>{{ translate('rateShopperText1') }}</li>
              <li>{{ translate('rateShopperText2') }}</li>
              <li>{{ translate('rateShopperText3') }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!--Tips-->
    <PageSection
      v-for="(tip, idx) in tips"
      :key="`tip${idx}`"
    >
      <ImageWithInfo
        v-bind="imageItemBind(tip)"
        :rtl="idx % 2 === 1"
      />
    </PageSection>
    <!--Features-->
    <PageSection :title="translate(features.title)">
      <BaseGrid
        :items="features.items"
      >
        <template
          v-for="(item, idx) in features.items"
          v-slot:[`item-${idx}`]="item"
        >
          <BaseCard
            :title="translate(item.title)"
            :icon-name="item.icon"
          />
        </template>
      </BaseGrid>
    </PageSection>
    <!--Feedback-->
    <PageSection primary>
      <ImageWithInfo
        v-bind="imageItemBind(feedback)"
        circle-image
        image-cover
        light
        rtl
      />
    </PageSection>
    <PageSection :title="translate('rateShopperHubspotTitle')">
      <HubspotForm
        :form-id="{
          ru: 'e468bc53-00b6-4e53-8ede-87ee2b014937',
          en: 'e468bc53-00b6-4e53-8ede-87ee2b014937'
        }"
      />
    </PageSection>
  </BasePage>
</template>

<script>
import PageSection from "@/components/PageSection";
import BasePage from "@/components/BasePage";
import ImageWithInfo from "@/components/widgets/ImageWithInfo";
import {mapGetters} from "vuex";
import CarouselBig from "@/components/CarouselBig";
import BaseGrid from "@/components/BaseGrid";
import BaseCard from "@/components/BaseCard";
import HubspotForm from "@/components/HubspotForm";

function createImageBlockData({slug, image}) {
  const PAGE = 'rateShopper'

  return {
    title: PAGE + slug + 'Title',
    description: PAGE + slug + 'Text',
    imageUrl: image,
  }
}

export default {
  name: "RateShopper",
  components: {
    PageSection,
    BasePage,
    ImageWithInfo,
    CarouselBig,
    BaseGrid,
    BaseCard,
    HubspotForm
  },
  data() {
    return {
      tips: [
        createImageBlockData({
          slug: 'Analysis',
          image: 'competitor_analysis.png'
        }),
        createImageBlockData({
          slug: 'Index',
          image: 'demand_index_old.png'
        }),
        createImageBlockData({
          slug: 'Visualization',
          image: 'rs_1.png'
        }),
      ],
      feedback: createImageBlockData({
        slug: 'Feedback',
        image: 'rateShopperReview.jpg'
      }),
      features: {
        title: 'rateShopperFeaturesTitle',
        items: [
          {
            title: 'rateShopperFeature1',
            icon: 'grossBook',
          },
          {
            title: 'rateShopperFeature2',
            icon: 'chart'
          },
          {
            title: 'rateShopperFeature3',
            icon: 'clock'
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      translate: 'getTranslate',
      carousels: 'carousels',
      image: 'getImage',
    }),
  },
  methods: {
    imageItemBind({imageUrl, title, description}) {
      return {
        description: this.translate(description),
        title: this.translate(title),
        imageUrl: this.image(imageUrl)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#rate-shopper-section-wrapper {
  background-color: $primary;
  color: white;

  #rate-shopper-section {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      padding: 20px 10px;
      background-color: rgba(0, 0, 0, 0.03);
      border-radius: 20px;
    }

    &-image {
      width: 45%;
      align-self: stretch;
      border-radius: 20px;
      overflow: hidden;
      box-sizing: border-box;
      max-width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: calc((9/20) * 100%);
      position: relative;

      @media (max-width: $breakpoint-desktop) {

        @media (max-width: $breakpoint-tablet) {

          @media (max-width: $breakpoint-tablet) {
            align-self: center;
            margin-bottom: 10px;

            @media (max-width: $breakpoint-mobile) {
              margin-bottom: 20px;
            }
          }
        }
      }

      &-inner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    &-info {
      flex-grow: 1;
      width: 50%;
      padding-left: 100px;
      transition: .3s;
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 20px;

      @media (max-width: $breakpoint-desktop) {
        padding-left: 60px;

        @media (max-width: $breakpoint-tablet) {
          padding-left: 40px;

          @media (max-width: $breakpoint-tablet) {
            padding: 20px;
            width: 100%;

            @media (max-width: $breakpoint-mobile) {
              padding: 0;
            }
          }
        }
      }

      &-title {
        font-weight: 900;
        font-size: 38px;
        margin-bottom: 20px;
      }

      &-description {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        box-sizing: border-box;
        font-weight: 400;

        @media (max-width: $breakpoint-desktop) {
          font-size: 16px;
          line-height: 24px;
          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        ul {
          margin: 0;
          padding-left: 0;
          list-style-type: none;

          li {
            margin: 15px 0;
            background-color: rgba(255, 255, 255, 0.11);
            border-radius: 10px;
            padding: 20px;
            box-sizing: border-box;

            @media (max-width: $breakpoint-mobile) {
              padding: 15px;
              margin: 10px 0;
            }

          }
        }
      }
    }
  }
}

</style>
