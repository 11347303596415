<template>
  <div class="about-program">
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="about-program-content-inner-title">{{ getTranslate('linkProgram') }}</div>
        <div class="about-program-content-inner-text">
          <a href="/Руководство пользователя программного обеспечения Hotellab.pdf" download>
            Руководство пользователя программного обеспечения Hotellab
          </a>
          <a href="/Описание функционала ПО Hotellab.docx.pdf" download>
            Описание функционала ПО Hotellab
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BaseButton from "@/components/BaseButton";

export default {
  name: "AboutProgram",
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters([
      'getTranslate',
      'getTranslateFromItem',
      'lang',
    ]),
  },
  data () {
  return {
  publicPath: process.env.BASE_URL
  }
}
}
</script>

<style lang="scss" scoped>
.about-program-content-inner-text {
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
    color: #7f7eba;
  }
}
.about-program-content-inner-title {
  font-weight: 900;
  font-size: 44px;
  line-height: 57px;
  margin-bottom: 40px;
  
  @media (max-width: $breakpoint-tablet) {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 30px;
    
    @media (max-width: $breakpoint-tablet-v) {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 20px;
      
      @media (max-width: $breakpoint-mobile) {
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 34px;
      }
    }
  }
}
</style>