<template>
  <div id="integrations">
    <div
      v-for="type in integrationsTypes"
      class="page-section-wrapper"
    >
      <div
        class="page-section-inner"
      >
        <div class="page-section-title-center">{{ getTranslate(type.slug) }}</div>
        <div class="integrations-list">
          <div
              v-for="integration in type.items"
              class="integration-item"
          >
            <a
              :href="integration.generic.url"
              class="integration-item-inner"
              target="_blank"
            >
              <div class="integration-item-inner-image">
                <div
                  :style="{
                    background: `url('${integration.generic.photo}') 50% 50%`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }"
                  class="integration-item-inner-image-inner"
                />
              </div>
              <div class="integration-item-inner-info">
                <div class="integration-item-inner-info-title">{{ getTranslateFromItem(integration.translation, 'title') }}</div>
                <div class="integration-item-inner-info-description">{{ getTranslateFromItem(integration.translation, 'description') }}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Integrations",
  computed: {
    ...mapGetters([
        'integrations',
        'getTranslate',
        'getTranslateFromItem'
    ]),
    integrationsTypes() {
      const types = {
        'pms': {
          slug: 'integrationsTypePms',
          items: [],
        },
        'channel_manager': {
          slug: 'integrationsTypeChannelManager',
          items: [],
        },
        'data': {
          slug: 'integrationsTypeData',
          items: [],
        },
        /*'other': {
          slug: 'integrationsTypeOther',
          items: [],
        },*/
      };

      if (!this.integrations) {
        return [];
      }

      this.integrations.forEach(e => {
        if (e.generic.type in types) {
          types[e.generic.type].items.push(e)
        } else {
          types['other'].items.push(e);
        }
      });

      return Object.values(types);
    },
  },
}
</script>

<style lang="scss" scoped>
.integrations-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  .integration-item {
    width: calc(100% / 4);
    box-sizing: border-box;
    padding: 30px;
    display: flex;

    @media (max-width: $breakpoint-tablet) {
      width: calc(100% / 3);

      @media (max-width: $breakpoint-tablet-v) {
        width: calc(100% / 2);

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
        }
      }
    }

    &-inner {
      background-color: #F9FAFF;
      border-radius: 15px;
      width: 100%;
      padding: 40px;
      box-shadow: $shadow-default;
      box-sizing: border-box;
      height: 100%;
      overflow: hidden;

      &-image {
        padding-top: 100%;
        margin-bottom: 40px;
        position: relative;

        &-inner {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }

      &-info {
        color: black;

        &-title {
          width: 100%;
          font-size: 20px;
          font-weight: 900;
          text-align: center;
          margin-bottom: 10px;
        }

        &-description {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
</style>