<template>
  <div id="solutions">
    <div class="solutions-main">
      <div v-for="e in solutions" class="solutions-main-card">
        <div
            :style="{background: `url('${e.photo}') 50% 50%`, backgroundSize: 'cover',}"
            class="solutions-main-card-background"
        ></div>
        <div class="solutions-main-card-blur">
          <div class="solutions-main-card-blur-title">
            {{ getTranslate(e.titleTranslateId) }}
          </div>
          <div class="solutions-main-card-blur-text">
            {{ getTranslate(e.textTranslateId) }}
          </div>
          <div class="solutions-main-card-blur-btn">
            <router-link :to="e.to">
            <BaseButton filled>{{getTranslate('homeSolutionsButtonText')}}</BaseButton>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="lang !== 'en'" class="solutions-main">
      <div v-for="e in solutions2" class="solutions-main-card">
        <div
            :style="{background: `url('${e.photo}') 50% 50%`, backgroundSize: 'cover',}"
            class="solutions-main-card-background"
        ></div>
        <div class="solutions-main-card-blur">
          <div class="solutions-main-card-blur-title">
            {{ getTranslate(e.titleTranslateId) }}
          </div>
          <div class="solutions-main-card-blur-text">
            {{ getTranslate(e.textTranslateId) }}
          </div>
          <div class="solutions-main-card-blur-btn">
            <router-link :to="e.to">
            <BaseButton filled>{{getTranslate('homeSolutionsButtonText')}}</BaseButton>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="lang !== 'ru'" class="solutions-main">
      <div v-for="e in solutions3" class="solutions-main-card">
        <div
            :style="{background: `url('${e.photo}') 50% 50%`, backgroundSize: 'cover',}"
            class="solutions-main-card-background"
        ></div>
        <div class="solutions-main-card-blur">
          <div class="solutions-main-card-blur-title">
            {{ getTranslate(e.titleTranslateId) }}
          </div>
          <div class="solutions-main-card-blur-text">
            {{ getTranslate(e.textTranslateId) }}
          </div>
          <div class="solutions-main-card-blur-btn">
            <router-link :to="e.to">
            <BaseButton filled>{{getTranslate('homeSolutionsButtonText')}}</BaseButton>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="getTranslate('homeCasesTitle') !== '{{hidden}}'" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslate('homeCasesTitle') }}
        </div>
        <div>
          <CarouselSmallCases></CarouselSmallCases>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";
import imgRevenue from '@/assets/images/solutions/revenue.jpg'
import imgMarketing from '@/assets/images/solutions/marketing.jpg'
import imgBenchmarking from '@/assets/images/solutions/benchmarking.jpg'
import imgRateShopper from '@/assets/images/solutions/rateShopper.jpg'
import imgOtaInsight from '@/assets/images/solutions/otaInsight.jpg'
import imgSimulator from '@/assets/images/solutions/simulator.jpg'
import CarouselSmallCases from "@/components/CarouselSmallCases";

export default {
  name: "Blog",
  computed: {
    ...mapGetters([
      'getTranslate',
      'getTranslateFromItem',
      'lang',
      'cases',
      'carousels',
      'trust',
      'images',
      'integrations',
      'news',
    ]),
  },
  data: () => ({
    solutions: [
      {
        photo: imgRevenue,
        titleTranslateId: 'linkRevenue',
        textTranslateId: 'homeSolutionsRevenueText',
        to: {name: 'Revenue',},
      },
      {
        photo: imgMarketing,
        titleTranslateId: 'linkMarketing',
        textTranslateId: 'homeSolutionsMarketingText',
        to: {name: 'Marketing',},
      },
    ],
    solutions2: [
      {
        photo: imgRateShopper,
        titleTranslateId: 'linkRateShopper',
        textTranslateId: 'homeSolutionsRateShopperText',
        to: {name: 'RateShopper',},
      },
      {
        photo: imgBenchmarking,
        titleTranslateId: 'linkBenchmarking',
        textTranslateId: 'homeSolutionsBenchmarkingText',
        to: {name: 'Benchmarking',},
      },
    ],
    solutions3: [
      {
        photo: imgOtaInsight,
        titleTranslateId: 'linkOtaInsight',
        textTranslateId: 'homeSolutionsOtaText',
        to: {name: 'OtaInsight',},
      },
      {
        photo: imgSimulator,
        titleTranslateId: 'linkSimulator',
        textTranslateId: 'homeSolutionsSimulatorText',
        to: {name: 'Solutions',},
      },
    ],
  }),
  components: {
    BaseButton,
    CarouselSmallCases,
  },
}
</script>

<style lang="scss" scoped>

.solutions-main {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 90px $padding-edge-large-desktop;

  @media (max-width: $breakpoint-desktop) {
    padding: 60px $padding-edge-desktop;

    @media (max-width: $breakpoint-tablet) {
      padding: 60px $padding-edge-tablet;
      flex-direction: column;

      @media (max-width: $breakpoint-tablet-v) {
        padding: 40px $padding-edge-tablet;
      }
    }
  }

  &-card {
    height: 100%;
    width: calc(50% - 32px);
    background-color: black;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 40px;
    color: white;
    position: relative;
    overflow: hidden;
    text-align: center;

    @media (max-width: $breakpoint-tablet-v) {
      padding: 0;
    }
    flex-direction: column;

    &-background {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: .7;
      transform: scale(1.2, 1.2);
      z-index: 1;
    }

    @media (max-width: $breakpoint-desktop) {
      width: calc(50% - 20px);


      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        height: calc(50% - 10px);

        @media (max-width: $breakpoint-tablet-v) {
        }
      }
    }

    &-blur {
      border-radius: 15px;
      z-index: 1;
      display: flex;
      box-sizing: border-box;
      padding: 30px 20px;

      flex-direction: column;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(40px);

      @media (max-width: $breakpoint-tablet-v) {
        height: 100%;
        width: 100%;
      }

      &-title {
        font-size: 30px;
        margin-bottom: 10px;
        color: white;
        font-weight: 900;
        padding: 4px 10px;
        z-index: 2;
      }

      &-text {
        padding: 10px;
        margin-bottom: 30px;
        max-width: 350px;
        z-index: 2;
        font-size: 18px;

        @media (max-height: 700px) {
          display: none;
        }
      }

      &-btn {
        z-index: 2;
      }
    }
  }

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(100vh - 80px);
    }
  }
}
</style>