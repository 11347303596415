<template>
  <div class="home-section-wrapper">
    <div class="home-section-inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
name: "HomeSection"
}
</script>

<style lang="scss" scoped>
.home-section-wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;

  .home-section-inner {
    width: 100%;
    max-width: $max-width;
  }
}
</style>