var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BasePage',[(_vm.carousels.rate_shopper.length > 0)?_c('PageSection',[_c('CarouselBig',{attrs:{"slides":_vm.carousels.rate_shopper,"image-contain":"","big-image":""}})],1):_vm._e(),_c('div',{staticClass:"page-section-wrapper",attrs:{"id":"rate-shopper-section-wrapper"}},[_c('div',{staticClass:"page-section-inner",attrs:{"id":"rate-shopper-section"}},[_c('div',{attrs:{"id":"rate-shopper-section-image"}},[_c('div',{style:({
            background: `url('https://hotellab.io/media/rs-image_vg9hZht.png') 50% 50%`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }),attrs:{"id":"rate-shopper-section-image-inner"}})]),_c('div',{attrs:{"id":"rate-shopper-section-info"}},[_c('div',{attrs:{"id":"rate-shopper-section-info-title"}}),_c('div',{attrs:{"id":"rate-shopper-section-info-description"}},[_vm._v(" "+_vm._s(_vm.translate('rateShopperTitle'))+" "),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.translate('rateShopperText1')))]),_c('li',[_vm._v(_vm._s(_vm.translate('rateShopperText2')))]),_c('li',[_vm._v(_vm._s(_vm.translate('rateShopperText3')))])])])])])]),_vm._l((_vm.tips),function(tip,idx){return _c('PageSection',{key:`tip${idx}`},[_c('ImageWithInfo',_vm._b({attrs:{"rtl":idx % 2 === 1}},'ImageWithInfo',_vm.imageItemBind(tip),false))],1)}),_c('PageSection',{attrs:{"title":_vm.translate(_vm.features.title)}},[_c('BaseGrid',{attrs:{"items":_vm.features.items},scopedSlots:_vm._u([_vm._l((_vm.features.items),function(item,idx){return {key:`item-${idx}`,fn:function(item){return [_c('BaseCard',{attrs:{"title":_vm.translate(item.title),"icon-name":item.icon}})]}}})],null,true)})],1),_c('PageSection',{attrs:{"primary":""}},[_c('ImageWithInfo',_vm._b({attrs:{"circle-image":"","image-cover":"","light":"","rtl":""}},'ImageWithInfo',_vm.imageItemBind(_vm.feedback),false))],1),_c('PageSection',{attrs:{"title":_vm.translate('rateShopperHubspotTitle')}},[_c('HubspotForm',{attrs:{"form-id":{
        ru: 'e468bc53-00b6-4e53-8ede-87ee2b014937',
        en: 'e468bc53-00b6-4e53-8ede-87ee2b014937'
      }}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }