<template>
  <CarouselSmall :slides="cases" :toRoute="'Case'"></CarouselSmall>
</template>

<script>
import CarouselSmall from "@/components/CarouselSmall";
import {mapGetters} from "vuex";

export default {
  name: "CarouselSmallCases",
  computed: {
    ...mapGetters([
        'cases',
    ]),
  },
  components: {
    CarouselSmall
  },
}
</script>

<style scoped>

</style>