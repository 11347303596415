const {
    VUE_APP_API_URL: HOST_URL,
    VUE_APP_API_PREFIX: API_PREFIX,
    VUE_APP_GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
} = process.env

export {
    HOST_URL,
    API_PREFIX,
    GOOGLE_MAP_KEY
}