<template>
  <div class="usp">
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('homeUspTitle') }}</div>
        <div class="usp-list">
          <div
              v-for="(e, i) in usp"
              class="usp-list-item"
              :ref="`usp-${i}`"
              :class="{
            'usp-list-item__active': i === activeUsp,
          }"
              @click="changeActiveUsp(i, $event)"
          >
            <div class="usp-list-item-icon">
              <BaseIcon :icon="e.icon" :color="i === activeUsp ? 'white' : 'primary'" :size="40"></BaseIcon>
            </div>
            {{getTranslate(e.labelId)}}
          </div>
        </div>
      </div>
    </div>

    <div
        class="usp-info"
        :class="{
          'usp-info__collapsed': activeUsp === -1,
        }"
    >
      <div
          class="usp-info-inner"
      >
        <div>

        </div>
        {{info}}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import iconLearning from '@/assets/icons/learning.svg';
import BaseIcon from "@/components/BaseIcon";

const WINDOW_WIDTH_HEADER_HEIGHT = [
  {
    windowWidth: 1200,
    headerHeight: 122,
  },
  {
    windowWidth: 955,
    headerHeight: 100,
  },
  {
    windowWidth: 0,
    headerHeight: 80,
  },
]

export default {
  name: "HomeUsp",
  methods: {
    changeActiveUsp(i) {
      const elementTop = this.$refs[`usp-${i}`][0].offsetTop;
      const windowWidth = window.innerWidth;
      let saveZone;
      
      WINDOW_WIDTH_HEADER_HEIGHT.forEach(e => {
        if (!saveZone && windowWidth > e.windowWidth) {
          saveZone = e.headerHeight;
        }
      })
      window.scrollTo({
        top: elementTop - saveZone,
        behavior: "smooth"
      });
      
      this.prevActiveUsp = this.activeUsp;

      if (i === this.activeUsp) {
        this.activeUsp = -1;
      } else {
        this.activeUsp = i;
      }
    },
  },
  computed: {
    ...mapGetters([
        'getTranslate',
    ]),
    info() {
      if (this.activeUsp === -1) {
        if (this.prevActiveUsp !== -1) {
          let prevActiveUsp = this.usp[this.prevActiveUsp];
          return this.getTranslate(prevActiveUsp.descriptionId);
        }

        return '';
      }

      let activeUsp = this.usp[this.activeUsp];

      return this.getTranslate(activeUsp.descriptionId);
    },
  },
  data: () => ({
    prevActiveUsp: -1,
    activeUsp: -1,
    usp: [
      {
        icon: 'learning',
        labelId: 'usp1Label',
        descriptionId: 'usp1Description',
      },
      {
        icon: 'airplane',
        labelId: 'usp2Label',
        descriptionId: 'usp2Description',
      },
      {
        icon: 'salesChannels',
        labelId: 'usp3Label',
        descriptionId: 'usp3Description',
      },
      {
        icon: 'phone',
        labelId: 'usp4Label',
        descriptionId: 'usp4Description',
      },
      {
        icon: 'chart',
        labelId: 'usp5Label',
        descriptionId: 'usp5Description',
      },
      {
        icon: 'cloudDb',
        labelId: 'usp6Label',
        descriptionId: 'usp6Description',
      },
      {
        icon: 'deleteUser',
        labelId: 'usp7Label',
        descriptionId: 'usp7Description',
      },
      {
        icon: 'XLS',
        labelId: 'usp8Label',
        descriptionId: 'usp8Description',
      },
      {
        icon: 'grossBook',
        labelId: 'usp9Label',
        descriptionId: 'usp9Description',
      },
    ],
  }),
  components: {
    BaseIcon,
  },
}
</script>

<style lang="scss" scoped>
.usp {
  width: 100%;

  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
}

.usp-info {
  transition: .5s;
  height: 280px;
  display: flex;
  justify-content: center;
  background-color: #5d5ca8;
  overflow: hidden;
}

.usp-info__collapsed {
  height: 0;
}

.usp-info-inner {
  height: 280px;
  color: white;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 400px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.page-section-wrapper {
  overflow: hidden;

  transition: .3s;
}

.page-section-wrapper-collapsed {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.usp-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &-item {
    box-sizing: border-box;
    padding: 28px 48px;
    display: flex;
    align-items: center;
    transition: .3s ease-in-out;
    margin: 10px;
    user-select: none;
    border-radius: 10px;
    background-color: #fbfbff;
    color: #5d5ca8;
    font-weight: 700;
    font-size: 20px;

    &-icon {
      margin-right: 10px;
      svg {
        transition: .3s;
      }
    }

    &-icon {
      margin-right: 10px;
    }

    &:hover {
      background-color: #5d5ca8;
      color: white;
    }

    &:hover &-icon svg {
      fill: white;
    }

    &__active {
      background-color: #5d5ca8;
      color: white;
      transform: scale(0.93, 0.93);
      box-shadow: none;

      &:hover {
        background-color: #5d5ca8;
      }
    }

    &:hover {
      box-shadow: 0 0 20px 2px rgba(0,0,0,0.05);
    }
  }
}
</style>