<template>
  <BasePage :style="{ marginTop: '-122px'}">
    <PageSection v-if="carousels.main.length > 0">
      <CarouselBig :slides="carousels.main"/>
    </PageSection>

    <PageSection :title="'Наши решения'">
      <CarouselBig :slides="solutions" imageContain />
    </PageSection>

    <HomeUsp></HomeUsp>

    <PageSection primary>
      <div class="page-section__title single-page-section__title">
        Наше приложение, написанное на языках
        <span class="single-icon-wrapper"><img
          width="40px"
          :style="{
            backgroundColor: '#fff',
            borderRadius: '50%',
            marginRight: '10px',
            marginBottom: '-5px'
          }"
          src="@/assets/python.svg"
          alt="Python" />Python</span> и
        <span class="single-icon-wrapper"><img
          width="40px"
          :style="{
            marginRight: '10px',
            marginBottom: '-5px'
          }"
          src="@/assets/js.svg"
          alt="Java Script" />JavaScript,</span> использует такие фреймворки как
        <span class="single-icon-wrapper"><img
          width="90px"
          :style="{
            marginRight: '10px',
            marginBottom: '-2px',
            borderRadius: '5px',
          }"
          src="@/assets/express.png"
          alt="Express" />Express, </span>
        <span class="single-icon-wrapper"><img
          width="40px"
          :style="{
            marginRight: '10px',
            marginBottom: '-5px'
          }"
          src="@/assets/django.svg"
          alt="Django" />Django,</span>
        <span class="single-icon-wrapper"><img
          width="40px"
          :style="{
            marginRight: '10px',
            marginBottom: '-5px'
          }"
          src="@/assets/vue.svg"
          alt="Vue" />Vue</span> и
        <span class="single-icon-wrapper"><img
          width="40px"
          :style="{
            marginRight: '10px',
            marginBottom: '-5px',
            borderRadius: '50%',
          }"
          src="@/assets/react.svg"
          alt="React" />React.</span>
      </div>
    </PageSection>

    <div v-if="getTranslate('homeCasesTitle') !== '{{hidden}}'" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslate('homeCasesTitle') }}
        </div>
        <div>
          <CarouselSmallCases></CarouselSmallCases>
        </div>
      </div>
    </div>

    <PageSection :title="'Тарифные планы'">
      <div class="single-tariffs-grid">
        <div v-for="e in tariffs" :key="e.id" class="single-tariffs-grid-item">
          <div class="single-tariffs-grid-item-info">
            <div class="single-tariffs-grid-item-desc-title">
              <img
                v-if="e.id === 1"
                class="single-tariffs-grid-item-desc-img"
                src="@/assets/medal.png"
                alt="medal" />
              {{ e.title }}
            </div>
            <div class="single-tariffs-grid-item-desc-text">
              <ul>
                <li v-for="(el, ind) in e.description" :key="ind + e.id" :style="{ marginBottom: '8px' }">
                  <span v-if="e.id !==1" style="color: #000">{{ el }}</span>
                  <span v-if="e.id ===1">{{ el }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="single-tariffs-grid-item-desc">
            <div v-if="e.id === 0" class="single-tariffs-grid-item-desc-wrapper">
              <img src="@/assets/user-profile.png" alt="demo" />
              <span>Демо-доступ: 14 дней</span>
            </div>
            <router-link :to="{name: 'Demo',}">
              <BaseButton>
                От {{ e.generic.price_from }} &#8381;/месяц*
              </BaseButton>
            </router-link>
          </div>
        </div>        
      </div>
      <p class="single-tariffs-grid-item-comment">
        * Не является коммерческим предложением. Стоимость подписки и настройки зависят от номерного фонда отеля и набора функций
      </p>
    </PageSection>

    <PageSection :title="getTranslate('contactsFormTitle')">
      <div class="single-head-office">
        <div
          class="single-head-office__title"
          v-text="getTranslate('headOfficeTitle')"
        />
        <div class="single-head-office__info">
          <div
            class="single-head-office__info-text"
            v-text="getTranslate('headOfficeInfo')"
          />
          <div class="single-head-office__info-socials">
            <a
              v-for="social in socialsList"
              :href="social.link"
              :key="social.key"
              target="_blank"
            >
              <BaseIcon
                color="secondary"
                :icon="social.icon"
                :size="32"
              />
            </a>
          </div>
        </div>
        <HubspotForm
          class="single-head-office__form"
          :form-id="{
            ru: '956c226b-ac06-48ed-8829-fc7021e05dc1',
            en: '2f1f4d4f-914d-4ae2-899b-29fbca58841b',
          }"
        />
      </div>
    </PageSection>
    <PageSection
      v-if="lang !== 'ru'"
      :title="getTranslate('contactsLocationsTitle')"
    >
      <div
        v-for="location in locations"
        :key="location.key"
        class="single-location"
      >
        <div
          class="single-location__img"
          :style="locationImgStyle(location.img)"
        />
        <div class="single-location__info">
          <div
            class="single-location__info-name"
            v-text="getTranslate(location.name)"
          />
          <div
            class="single-location__info-address"
            v-text="getTranslate(location.address)"
          />
          <BaseButton
            v-text="getTranslate('contactsInfoLocationGoTo')"
            :link-url="location.link"
            link-blank
            filled
          />
        </div>
      </div>
    </PageSection>
  </BasePage>
</template>

<script>
import HomeSection from "@/components/HomeSection";
import BaseButton from "@/components/BaseButton";
import BaseIcon from "@/components/BaseIcon";
import CarouselBig from "@/components/CarouselBig";
import CarouselSmall from "@/components/CarouselSmall";
import {mapGetters} from "vuex";
import HomeUsp from "@/components/HomeUsp";
import CarouselSmallCases from "@/components/CarouselSmallCases";
import BasePage from "@/components/BasePage";
import PageSection from "@/components/PageSection";
import { v4 as uuid } from 'uuid'
import HubspotForm from "@/components/HubspotForm";
import imgRevenue from '@/assets/demand_calendar.png';
import imgMarketing from '@/assets/analytics.png';
import imgRateShopper from '@/assets/rate_shopper.png';
import imgBenchmarking from '@/assets/bench.png';
import imgSimulator from '@/assets/simulator.png';
import iconPhython from '@/assets/python.svg';
import iconJS from '@/assets/js.svg';
import iconExpress from '@/assets/express.png';
import iconDjango from '@/assets/django.svg';
import iconVue from '@/assets/vue.svg';
import iconReact from '@/assets/react.svg';

const socialItem = ({link, icon}) => ({
  key: uuid(),
  link,
  icon
})

const locationItem = ({link, name, address, img}) => ({
  key: uuid(),
  link,
  address,
  name,
  img,
})

export default {
  name: 'Home',
  computed: {
    ...mapGetters([
      'getTranslate',
      'getImage',
      'lang',
      'cases',
      'carousels',
      'images',
    ]),
    socialsList() {
      return this.socials[this.lang] || []
    }
  },
  components: {
    CarouselSmallCases,
    HomeSection,
    BaseButton,
    BasePage,
    HomeUsp,
    CarouselBig,
    BaseIcon,
    CarouselSmall,
    PageSection,
    HubspotForm,
    PageSection,
    BaseButton,
  },
  data() {
    return {
      socials: {
        ru: [
          socialItem({
            link: 'https://t.me/hotellab_io',
            icon: 'telegram',
          }),
        ],
        en: [
          socialItem({
            link: 'https://www.linkedin.com/company/hotellab-io',
            icon: 'linkedIn',
          }),
          socialItem({
            link: 'https://www.facebook.com/hotellab.io/',
            icon: 'facebook',
          }),
        ],
      },
      locations: [
        locationItem({
          link: 'https://www.google.com/maps/place/Mikras+Asias+1,+Thessaloniki+543+51,+Greece/@40.6141007,22.9705775,16z',
          name: 'contactsInfoGreeceTitle',
          address: 'contactsInfoGreece',
          img: 'greekOfficePhoto.jpg',
        }),
        locationItem({
          link: 'https://goo.gl/maps/Xh1SiJnTSg5L2fUA8',
          name: 'contactsInfoPortugueseTitle',
          address: 'contactsInfoPortuguese',
          img: 'portugueseOfficePhoto.jpg',
        }),
      ],
      planItems: [ "starter", "pro", "interprise" ],
      tariffs: [
        {
          id: 0,
          generic: {
            price_from: "6 000",
            setup_fee: 800,
          },
          title: "hotellab: Start",
          description: [
            'Динамика изменения цен',
            'Фиксирование отклонения цены',
            'Глубина мониторинга цен 90 дней',
            'Индекс спроса',
            'Удобная визуализация',
            'До 10 конкурентов в группе',
            'Выгрузка отчетов в Excel',
            'Анализ в разрезе разных типов тарифов']
        },
        {
          id: 1,
          generic: {
            price_from: "12 000",
            setup_fee: 1200,
          },
          title: "hotellab: PRO",
          description: [
            'Формирование рекомендаций на 90 дней вперед',
            'Частота обновления рекомендаций цен 2 раза в сутки',
            'Прогноз загрузки/дохода на 90 дней вперед',
            'Расширенная аналитика продаж отеля',
            'Анализ цен и доступости конкурентов',
            'Выгрузка отчетов в Excel',
            'Настройки алгоритма',
            'Двусторонняя интеграция с PMS']
        },
        {
          id: 2,
          generic: {
            price_from: "9 000",
            setup_fee: 1500,
          },
          title: "hotellab: Analytics",
          description: ['Дашборд анализа продаж отеля и спроса',
            'Темпы продаж с подробной детализацией',
            'Аналитика ключевых метрик',
            'Сравнение с историей и бюджетом',
            'Автоматизированная отправка отчетов на вышу почту',
            'Анализ цен и доступности конкурентов',
            'Односторонняя интеграция с PMS']
        },
      ],
      solutions: [
        {
          id: 0,
          generic: {
            demo_call_to_action: false,
            link: "/revenue",
            orientation: "landscape",
            photo: imgRevenue,
          },
          translation: {
            ru: {
              title: "hotellab: RMS",
              description: "Система управления доходами. Дополнительный доход от математически точного и эластичного ценообразования",              
            },
            en: {
              title: "hotellab: RMS",
              description: "Система управления доходами. Дополнительный доход от математически точного и эластичного ценообразования",              
            },
          }

        },
        {
          id: 1,
          generic: {
            demo_call_to_action: false,
            link: "/marketing",
            orientation: "landscape",
            photo: imgMarketing,
          },
          translation: {
            ru: {
              title: "hotellab: Analytics",
              description: "Необходимые данные для эффективных стратегий revenue management: анализ конкурентов, изучение продаж отеля, сквозная web-аналитика",              
            },
            en: {
              title: "hotellab: Analytics",
              description: "Необходимые данные для эффективных стратегий revenue management: анализ конкурентов, изучение продаж отеля, сквозная web-аналитика",              
            },
          }

        },
        {
          id: 2,
          generic: {
            demo_call_to_action: false,
            link: "/rateShopper",
            orientation: "landscape",
            photo: imgRateShopper,
          },
          translation: {
            ru: {
              title: "hotellab: Rate shopper",
              description: "Мониторинг цен, доступности и динамики цены конкурентов",              
            },
            en: {
              title: "hotellab: Rate shopper",
              description: "Мониторинг цен, доступности и динамики цены конкурентов",              
            },
          }

        },
        {
          id: 3,
          generic: {
            demo_call_to_action: false,
            link: "/benchmarking",
            orientation: "landscape",
            photo: imgBenchmarking,
          },
          translation: {
            ru: {
              title: "hotellab: Market",
              description: "Качественные данные для максимизации вашего дохода",              
            },
            en: {
              title: "hotellab: Market",
              description: "Качественные данные для максимизации вашего дохода",              
            },
          }

        },
        {
          id: 3,
          generic: {
            demo_call_to_action: false,
            link: "/simulator",
            orientation: "landscape",
            photo: imgSimulator,
          },
          translation: {
            ru: {
              title: "hotellab: Price Simulator",
              description: "Отличный способ получить практический опыт управления ценообразованием в отеле",              
            },
            en: {
              title: "hotellab: Price Simulator",
              description: "Отличный способ получить практический опыт управления ценообразованием в отеле",              
            },
          }

        },
      ]
    }
  },
  methods: {
    locationImgStyle(img) {
      return {
        background: `url('${this.getImage(img)}') 50% 50%`,
        backgroundSize: 'cover',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.single-icon-wrapper {
  white-space: nowrap;
}

.single-page-section__title.page-section__title {
  width: auto;
  padding: 0 10%;

  @media (max-width: $breakpoint-desktop) {
    padding: 0 30px;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 20px;

      @media (max-width: $breakpoint-tablet-v) {
        padding: 0 20px;

        @media (max-width: $breakpoint-mobile) {
          padding: 0;
        }
      }
    }
  }
}

.carousel-item {
  display: flex;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0 60px;
  min-height: 430px;
  align-items: stretch;

  @media (max-width: $breakpoint-desktop) {
    padding: 0 30px;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 20px;
      min-height: 380px;

      @media (max-width: $breakpoint-tablet-v) {
        padding: 0 20px;
        min-height: 200px;

        @media (max-width: $breakpoint-mobile) {
          padding: 0 15px;
          min-height: auto;
        }
      }
    }
  }

  &-description {
    flex-grow: 1;
    margin-right: 60px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: $breakpoint-desktop) {
      margin-right: 50px;

      @media (max-width: $breakpoint-tablet) {
        margin-right: 40px;

        @media (max-width: $breakpoint-tablet-v) {

          @media (max-width: $breakpoint-mobile) {
            margin-right: 0;
            padding: 10px 0;
            align-items: center;
          }
        }
      }
    }

    &-title {
      font-size: 38px;
      line-height: 64px;
      font-weight: 900;
      transition: .3s;
      color: #25272B;
      margin-bottom: 40px;

      @media (max-width: $breakpoint-desktop) {
        margin-bottom: 25px;
        font-size: 38px;
        line-height: 54px;
        max-width: 500px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 34px;
          line-height: 42px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              margin-bottom: 15px;
              text-align: center;
              max-width: 380px;
            }
          }
        }
      }
    }

    &-text {
      font-size: 16px;
      line-height: 21px;
      font-weight: 500;
      color: black;
      max-width: 500px;
      margin-bottom: 40px;

      @media (max-width: $breakpoint-desktop) {
        margin-bottom: 25px;

        @media (max-width: $breakpoint-tablet) {

          @media (max-width: $breakpoint-tablet-v) {
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              margin-bottom: 15px;
              text-align: center;
              font-weight: 400;
            }
          }
        }
      }

      ul {
        outline: 2px olid red;
      }
    }
  }

  &-image {
    width: 50%;
    max-width: 380px;
    border-radius: 10px;
    min-height: 430px;
    flex-shrink: 0;

    @media (max-width: $breakpoint-desktop) {
      max-width: 360px;

      @media (max-width: $breakpoint-tablet) {
        max-width: 320px;
        min-height: 380px;

        @media (max-width: $breakpoint-tablet-v) {
          max-width: 260px;
          min-height: 300px;

          @media (max-width: $breakpoint-mobile) {
            display: none;
          }
        }
      }
    }
  }
}

.single-head-office {
  $gap: 32px;
  display: flex;

  @include adaptiveMobile {
    flex-direction: column;
  }

  &__info, &__title, &__form {
    width: calc((100% / 3) - #{$gap});

    margin-right: $gap;

    @include adaptiveTablet {
      width: calc(50% - #{$gap});
    }

    @include adaptiveMobile {
      width: 100%;

      margin-right: 0;
    }
  }

  &__title, &__info {
    white-space: pre-line;
  }

  &__title {
    font-weight: bold;
    font-size: 38px;

    @include adaptiveTablet {
      display: none;
    }
  }

  &__info {
    font-size: 20px;

    @include adaptiveMobile {
      text-align: center;
      font-size: 20px;

      margin-bottom: $gap;
    }

    &-text {
      margin-bottom: 16px;
    }

    &-socials {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      @include adaptiveMobile {
        justify-content: center;
      }
    }
  }
}

.single-location:not(:last-child) {
  margin-bottom: 20px;
}

.single-location {
  $border-radius: 16px;

  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @include adaptiveTabletV {
    flex-direction: column;
  }

  &__img, &__info {
    flex-shrink: 0;
    width: 50%;

    @include adaptiveTabletV {
      width: 100%;
    }
  }

  &__img {
    border-radius: $border-radius 0 0 $border-radius;

    @include adaptiveTabletV {

      border-radius: $border-radius $border-radius 0 0;
      @include aspectRatio(2, 1);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px;
    border: 1px solid #8c8c8c;
    border-radius: 0 $border-radius $border-radius 0;
    border-left-style: none;
    box-sizing: border-box;

    @include adaptiveTabletV {
      padding: 32px;

      border-radius: 0 0 $border-radius $border-radius;
      border-left-style: solid;
      border-top-style: none;
    }

    &-name, &-address {
      font-weight: 500;
      font-size: 22px;
      margin-bottom: 16px;

      @include adaptiveTabletV {
        font-size: 20px;
      }
    }

    &-name {
      color: $primary;
    }

    &-address {
      white-space: pre-line;
    }
  }
}
.single-tariffs-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &-item {
    position: relative;
    width: 30%;
    height: 500px;
    margin: 25px 15px;
    padding: 17px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0px 3px 13px #00000029;
    border-radius: 10px;

    &:first-child {
      background-color: #e1ecfe;
    }

    &:first-child &-info {
      color: #29a7bb;
    }

    .button {
      font-size: 20px;
      font-weight: bold;
      border: 1px solid #C4C4C4;
    }

    &:first-child .button {
      color: #fff;
      background-color: #29a7bb;
    }

    &:nth-child(2) {
      background-color: #5d5ca8;
      border: 1px solid #77599c;

      @media (min-width: $breakpoint-desktop) {
        height: 525px;
        margin-top: 0;
        border-radius: 15px;

      }
    }

    &:nth-child(2) .button {
      color: #5d5ca8;
      background-color: #fff;
    }

    &:nth-child(2) &-info {
      color: #fff;
    }

    &:last-child &-info {
      color: #5d5ca8;
    }

    &:last-child .button {
      color: #fff;
      background-color: #5d5ca8;
    }

    @media (max-width: $breakpoint-desktop) {
      width: 40%;
      flex-direction: column;
      height: 500px;

      @media (max-width: $breakpoint-tablet-v) {
        width: 100%;
        margin-left: 0px;
        height: auto;
        flex-direction: row;

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
        }
      }
    }

    &-info {
      width: 100%;
      border-radius: 8px;
      box-sizing: border-box;
      position: relative;

      @media (max-width: $breakpoint-desktop) {
        height: 200px;
        width: 100%;

        @media (max-width: $breakpoint-tablet-v) {
          width: 55%;
          height: 100%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
    }

    &-desc {
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: $breakpoint-tablet-v) {
        justify-content: center;
      }

      &-title {
        padding: 10px;
        margin: 0 10px 10px;
        font-weight: 500;
        font-size: 32px;
        line-height: 43px;
        text-align: center;
        border-bottom: 2px solid #dbdde2;       

        &:nth-child(2) {
          border-color: #fff;
        }

        @media (max-width: $breakpoint-tablet) {
          font-size: 20px;
          line-height: 28px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 20px;
            line-height: 28px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
      }

      &-img {
        width: 50px;
        margin-bottom: -15px;
        @media (max-width: $breakpoint-tablet) {
          width: 40px;
        }
      }

      &-text {
        margin-top: 10px;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-weight: 500;

        img {
          width: 30px;
          margin-right: 10px;
        }
      }
    }

    &-comment {
      font-weight: 500;
    }
  }
}

</style>
