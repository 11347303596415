<template>
  <div :class="b()">
    <slot/>
  </div>
</template>

<script>
import {bemMixin} from '@/mixins'

export default {
  name: "BasePage",
  blockName: 'base-page',
  mixins: [bemMixin]
}
</script>