<template>
  <CarouselSmall :slides="news" :toRoute="'News'" :image-like-cover="true"></CarouselSmall>
</template>

<script>
import CarouselSmall from "@/components/CarouselSmall";
import {mapGetters} from "vuex";

export default {
  name: "CarouselSmallCases",
  computed: {
    ...mapGetters([
        'news',
    ]),
  },
  components: {
    CarouselSmall
  },
}
</script>

<style scoped>

</style>