<template>
  <div class="hl-slick-carousel-multi-wrapper">
    <div >
      <VueSlickCarousel ref="carousel" v-bind="settings">
        <div v-for="e in slides" tabindex="-1" role="tabpanel" aria-hidden="true">
          <div class="carousel-multi-item">
            <div class="carousel-multi-item-inner">
              <div
                  v-if="imageLikeCover"
                  class="carousel-multi-item-inner-image-cover"
                  :style="{background: `url('${e.generic.photo}') 50% 50%`, backgroundSize: 'cover', }"
              ></div>
              <div v-else class="carousel-multi-item-inner-image">
                <img :src="e.generic.photo" alt="" tabindex="-1" role="tabpanel" aria-hidden="true">
              </div>
              <div class="carousel-multi-item-inner-title">
                {{getTranslateFromItem(e.translation, 'title')}}
              </div>
              <div class="carousel-multi-item-inner-button">
                <router-link :to="{name: toRoute, params: {id: e.id,},}">
                  <BaseButton filled>{{ getTranslate('homeSolutionsButtonText') }}</BaseButton>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <template #prevArrow="arrowOption">
          <div class="hl-carousel-big-arrow hl-carousel-big-arrow-prev">
            <img src="@/assets/icons/chevron-left.svg" alt="">
          </div>
        </template>
        <template #nextArrow="arrowOption">
          <div class="hl-carousel-big-arrow hl-carousel-big-arrow-next">
            <img src="@/assets/icons/chevron-right.svg" alt="">
          </div>
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";

export default {
  name: 'CarouselSmall',
  components: {
    VueSlickCarousel,
    BaseButton
  },
  computed: {
    ...mapGetters([
      'getTranslate',
      'getTranslateFromItem',
      'lang',
      'carousels',
      'trust',
      'team',
      'news',
      'images',
      'integrations',
    ]),
  },
  props: {
    slides: Array,
    imageLikeCover: false,
    toRoute: {
      type: String,
      required: true,
    },
  },
  methods: {
    showPrev() {
      this.$refs.carousel.prev();
    },
    showNext() {
      this.$refs.carousel.next();
    },
  },
  data: () => ({
    settings: {
      arrows: true,
      dots: false,
      infinite: true,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 955,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            arrows: false,
            dots:true,
          },
        }
      ],
    },
  }),
}
</script>

<style lang="scss" scoped>
//variables
$arrow-full: 35px;
$arrow-desktop: 35px;
$arrow-tablet: 35px;
$arrow-tablet-v: 35px;
$arrow-mobile: 35px;
$space-for-arrow: 5px;


.hl-carousel-big-arrow {
  opacity: .8;
  width: 35px;
  height: 35px;
  img {
    width: 100%;
  }
}
.hl-carousel-big-arrow:before {
  display: none;
}
.hl-carousel-big-arrow-prev {
  left: -($arrow-full+$space-for-arrow);
}
.hl-carousel-big-arrow-next {
  right: -($arrow-full+$space-for-arrow);
}
.hl-slick-carousel-multi-wrapper {
  padding: 0 $arrow-full+$space-for-arrow;

  @media (max-width: $breakpoint-mobile) {
    padding: 0 0 25px;
  }
}
.carousel-multi-item {
  min-height: 380px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;

  @media (max-width: $breakpoint-mobile) {
    min-height: 300px;
  }
}
.carousel-multi-item-inner {
  border-radius: 10px;
  background-color: #fbfbff;
  box-shadow: $shadow-default;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  display: flex;
  overflow: hidden;

  &-image-cover {
    height: 150px;
    flex-shrink: 0;

    @media (max-width: $breakpoint-mobile) {
      height: 120px;
    }
  }

  &-image {
    height: 150px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    user-select: none;
    pointer-events: none;

    img {
      max-height: 100%;
    }

    @media (max-width: $breakpoint-mobile) {
      height: 120px;
    }
  }

  &-title {
    font-weight: 500;
    padding: 20px;
    font-size: 20px;
    flex-grow: 1;
    overflow: hidden;

    @media (max-width: $breakpoint-mobile) {
      font-size: 18px;
      padding: 15px;
    }
  }

  &-button {
    align-self: flex-start;
    padding: 20px;

    @media (max-width: $breakpoint-mobile) {
      padding: 15px;
    }
  }
}
</style>