<template>
  <div
    class="button"
    v-bind="props"
    :class="{
      'button__bordered': bordered,
      'button__filled': filled,
      'button__shadowed': !flat,
    }"
    :style="{
      width: `${width}px`,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>

const TAG = {
  A: 'a',
}

const LINK_TARGET = {
  BLANK: '_blank',
}

export default {
  name: "BaseButton",
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    linkUrl: {
      type: String,
    },
    linkBlank: {
      type: Boolean,
    },
    width: Number,
    height: Number,
  },
  computed: {
    props() {
      return {
        ...this.linkProps,
      }
    },
    linkProps() {
      return this.linkUrl ? {
        target: this.resolveTarget(this.linkBlank),
        href: this.linkUrl,
        is: TAG.A,
      } : {}
    },
  },
  methods: {
    resolveTarget(blank) {
      return blank ? LINK_TARGET.BLANK : undefined
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
  font-size: 14px;
  color: white;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  letter-spacing: 0;
  white-space: nowrap;
  border-radius: 5px;
  line-height: 16px;
  padding: 16px 32px;
  transition: .3s;

  @media (max-width: $breakpoint-desktop) {
    padding: 14px 24px;
    font-size: 14px;
  }

  @media (max-width: $breakpoint-tablet) {
    padding: 12px 20px;
    font-size: 14px;
  }

  &:hover {
    opacity: .9;
  }
}

.button__shadowed {
  box-shadow: $shadow-default;
}

.button__filled {
  background-color: $primary;
}

.button__bordered {
  border: 2px solid #FFFFFF;
}
</style>
