<template>
  <BasePage>
    <PageSection v-if="carousels.main.length > 0">
      <CarouselBig :slides="carousels.main"/>
    </PageSection>
    <PageSection primary>
      <div id="home-section-profit">
        <div id="home-section-profit-image">
          <img :src="images.homeIncreaseRevenueImg[lang]" alt="">
        </div>
        <div id="home-section-profit-info">
          <div
            id="home-section-profit-info-title"
            v-text="getTranslate('homeIncreaseRevenueText')"
          />
          <router-link :to="{name: 'Demo',}">
            <BaseButton
              bordered
              flat
              v-text="getTranslate('buttonForADemo')"
            />
          </router-link>
        </div>
      </div>
    </PageSection>
    <PageSection>
      <div class="page-section-inner" id="home-section-creator">
        <div id="home-section-creator-image">
          <div
            id="home-section-creator-image-inner"
            :data-lang="this.lang"
          />
          <div v-if="lang === 'ru'" id="home-section-creator-image-desc">
            <div
              id="home-section-creator-image-desc-name"
              v-text="getTranslate('homeCreatorName')"
            />
            <div
              id="home-section-creator-image-desc-position"
              v-text="getTranslate('homeCreatorPosition')"
            />
          </div>
        </div>
        <div id="home-section-creator-info">
          <div
            id="home-section-creator-info-title"
            v-text="getTranslate('homeCreatorTitle')"
          />
          <div
            id="home-section-creator-info-description"
            v-text="getTranslate('homeCreatorText')"
          />
        </div>
      </div>
    </PageSection>
    <PageSection :title="getTranslate('homeTrustTitle')">
      <div id="home-section-trust">
        <div id="home-section-trust-logos">
          <div class="home-section-trust-logos-item" v-for="e in trust">
            <img :src="e.generic.photo" alt="">
          </div>
        </div>
      </div>
    </PageSection>
    <div v-if="lang === 'ru'" class="page-section-wrapper" id="home-section-solutions-wrapper">
      <div class="page-section-inner" id="home-section-solutions">
        <div id="home-section-solutions-title" class="page-section-title-center">
          {{ getTranslate('homeSolutionsTitle') }}
        </div>
        <div
          id="home-section-solutions-solutions"
          :data-odd="solutions.length % 2 === 1"
        >
          <div v-for="e in solutions" class="home-section-solutions-solutions-item">
            <BaseIcon class="home-section-solutions-solutions-item-icon" :icon="e.icon"/>
            <div class="home-section-solutions-solutions-item-desc">
              <div class="home-section-solutions-solutions-item-desc-title">
                {{ getTranslate(e.titleTranslateId) }}
              </div>
              <div class="home-section-solutions-solutions-item-desc-text">
                {{ getTranslate(e.textTranslateId) }}
              </div>
              <router-link :to="e.to" :style="{marginTop: '20px',}" class="solution-link">
                <BaseButton filled>
                  {{ getTranslate('homeSolutionsButtonText') }}
                </BaseButton>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="lang === 'en'" class="page-section-wrapper" id="home-section-solutions-en-wrapper">
      <div class="page-section-inner" id="home-section-solutions-en">
        <div id="home-section-solutions-en-title" class="page-section-title-center">
          {{ getTranslate('homeSolutionsTitle') }}
        </div>
        <div
          id="home-section-solutions-en-solutions"
          :data-odd="solutions.length % 2 === 1"
        >
          <div v-for="e in solutions" class="home-section-solutions-en-solutions-item">
            <div class="home-section-solutions-en-solutions-item-image">
              <img :src="e.photo" alt="">
            </div>
            <div class="home-section-solutions-en-solutions-item-desc">
              <div class="home-section-solutions-en-solutions-item-desc-title">
                {{ getTranslate(e.titleTranslateId) }}
              </div>
              <div class="home-section-solutions-en-solutions-item-desc-text">
                {{ getTranslate(e.textTranslateId) }}
              </div>
              <router-link :to="e.to" :style="{marginTop: '20px',}" class="solution-link">
                <BaseButton filled>
                  {{ getTranslate('homeSolutionsButtonText') }}
                </BaseButton>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeUsp></HomeUsp>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('homeIntegrationsTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item"
             v-for="e in integrations.slice(0, 10)">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
        <router-link
          :to="{name: 'Integrations',}"
          :style="{alignSelf: 'center', marginTop: '20px',}"
        >
          <BaseButton filled>
            {{ getTranslate('homeIntegrationsButton') }}
          </BaseButton>
        </router-link>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div id="home-section-news-title" class="page-section-title-center">
          {{ getTranslate('homeNewsTitle') }}
        </div>
        <div id="home-section-news-carousel">
          <CarouselSmallNews></CarouselSmallNews>
        </div>
      </div>
    </div>
    <!--KQ.Control 6-->
    <div v-if="getTranslate('homeCasesTitle') !== '{{hidden}}'" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslate('homeCasesTitle') }}
        </div>
        <div>
          <CarouselSmallCases></CarouselSmallCases>
        </div>
      </div>
    </div>
  </BasePage>
</template>

<script>
import HomeSection from "@/components/HomeSection";
import BaseButton from "@/components/BaseButton";
import BaseIcon from "@/components/BaseIcon";
import CarouselBig from "@/components/CarouselBig";
import CarouselSmall from "@/components/CarouselSmall";
import {mapGetters} from "vuex";
import HomeUsp from "@/components/HomeUsp";
import CarouselSmallCases from "@/components/CarouselSmallCases";
import CarouselSmallNews from "@/components/CarouselSmallNews";
import BasePage from "@/components/BasePage";
import PageSection from "@/components/PageSection";

export default {
  name: 'Home',
  computed: {
    ...mapGetters([
      'getTranslate',
      'lang',
      'cases',
      'carousels',
      'trust',
      'images',
      'integrations',
      'news',
    ]),
    solutions() {
      return this.solutionsData
        .filter(solution => this.getTranslate(solution.titleTranslateId) !== '{{hidden}}')
    },
  },
  components: {
    CarouselSmallCases,
    CarouselSmallNews,
    HomeSection,
    BaseButton,
    BasePage,
    HomeUsp,
    CarouselBig,
    BaseIcon,
    CarouselSmall,
    PageSection
  },
  data: () => ({
    solutionsData: [
      {
        photo: "https://hotellab.io/media/solutions-rms.png",
        icon: 'gears',
        titleTranslateId: 'linkRevenue',
        textTranslateId: 'homeSolutionsRevenueText',
        to: {name: 'Revenue',},
      },
      {
        photo: "https://hotellab.io/media/solutions-analytics.png",
        icon: 'news',
        titleTranslateId: 'linkMarketing',
        textTranslateId: 'homeSolutionsMarketingText',
        to: {name: 'Marketing',},
      },
      {
        icon: 'chart',
        titleTranslateId: 'linkBenchmarking',
        textTranslateId: 'homeSolutionsBenchmarkingText',
        to: {name: 'Benchmarking',},
      },
      {
        icon: 'cloudDb',
        titleTranslateId: 'linkRateShopper',
        textTranslateId: 'homeSolutionsRateShopperText',
        to: {name: 'RateShopper',},
      },
      {
        photo: "https://hotellab.io/media/solutions-ota2.png",
        titleTranslateId: 'linkOtaInsight',
        textTranslateId: 'homeSolutionsOtaText',
        to: {name: 'OtaInsight',},
      },
      {
        photo: "https://hotellab.io/media/solutions-simulator.png",
        titleTranslateId: 'linkSimulator',
        textTranslateId: 'homeSolutionsSimulatorText',
        to: {name: 'Simulator',},
      }, 
    ],
  }),
}
</script>

<style lang="scss" scoped>
#home-section-solutions-wrapper {

  #home-section-solutions {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-solutions {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: space-between;

      &[data-odd] {
        justify-content: center;
      }

      .home-section-solutions-solutions-item {
        width: calc(50% - 24px);
        box-sizing: border-box;
        box-shadow: $shadow-default;
        border-radius: 15px;
        display: flex;
        background-color: #fbfbff;
        padding: 64px 48px;
        margin: 24px 0;
        align-items: center;
        justify-content: flex-start;


        @media (max-width: $breakpoint-desktop) {
          padding: 64px 36px;

          @media (max-width: $breakpoint-tablet) {
            padding: 32px;
            flex-direction: column;
            width: calc(50% - 16px);
            margin: 16px 0;

            @media (max-width: $breakpoint-tablet-v) {
              padding: 24px;

              @media (max-width: $breakpoint-mobile) {
                width: 100%;
                margin: 12px 0;
              }
            }
          }
        }

        &-icon {
          width: 100px;
          margin-right: 48px;
          flex-shrink: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: $breakpoint-desktop) {
            width: 100px;
            margin-right: 24px;

            @media (max-width: $breakpoint-tablet) {
              height: auto;
              width: 140px;
              margin: 0 0 20px;
            }
          }
        }

        &-desc {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          @media (max-width: $breakpoint-tablet) {
            flex-grow: 1;
          }

          &-title {
            font-size: 38px;
            font-weight: 900;
            line-height: 48px;
            margin-bottom: 20px;
            color: #2F3238;

            @media (max-width: $breakpoint-desktop) {
              font-size: 38px;
              line-height: 42px;

              @media (max-width: $breakpoint-tablet) {
                font-size: 26px;
                line-height: 34px;

                @media (max-width: $breakpoint-tablet-v) {
                  font-size: 24px;
                  width: 100%;
                  text-align: center;
                  line-height: 30px;
                }
              }
            }
          }

          &-text {
            flex-grow: 1;
            @media (max-width: $breakpoint-tablet-v) {
              width: 100%;
              text-align: center;
            }
          }

          .solution-link {
            margin-top: 20px;

            @media (max-width: $breakpoint-tablet) {
              align-self: flex-end;

              @media (max-width: $breakpoint-tablet-v) {
                align-self: center;
              }
            }
          }
        }
      }
    }

    &-title {
      font-weight: 900;
      text-align: center;
      font-size: 38px;
      align-self: center;
      line-height: 57px;
      color: #2F3238;
      margin-bottom: 20px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 34px;
        line-height: 42px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 32px;
          line-height: 38px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

#home-section-solutions-en-wrapper {

#home-section-solutions-en {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &-solutions {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;

    &[data-odd] {
      justify-content: center;
    }

    .home-section-solutions-en-solutions-item {
      width: calc(640px / 1366px * 100%);
      position: relative;
      box-sizing: border-box;
      box-shadow: $shadow-default;
      border-radius: 10px;
      background: #F9FAFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 13px #00000047;
      opacity: 1;
      padding: 30px 48px;
      margin: 15px 15px;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 92px;


      @media (max-width: $breakpoint-desktop) {
        width: calc(548px / 1366px * 100%);

        @media (max-width: $breakpoint-tablet) {
          width: 40%;

          @media (max-width: $breakpoint-tablet-v) {

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              margin: 12px 0;
            }
          }
        }
      }

      &-image {
        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 24px;
          max-width: 226px;
          max-height: 150px;
          
          @media (max-width: $breakpoint-tablet-v) {
            max-width: 176px;
            max-height: 100px;
            
            @media (max-width: $breakpoint-mobile) {
              max-width: 206px;
              max-height: 130px;
            }
          }
        }
      }

      &-desc {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: $breakpoint-tablet) {
          flex-grow: 1;
        }

        &-title {
          font-size: 34px;
          font-weight: 900;
          line-height: 48px;
          margin-bottom: 20px;
          color: #2F3238;

          @media (max-width: $breakpoint-desktop) {
            font-size: 38px;
            line-height: 42px;

            @media (max-width: $breakpoint-tablet) {
              font-size: 26px;
              line-height: 34px;

              @media (max-width: $breakpoint-tablet-v) {
                font-size: 24px;
                width: 100%;
                text-align: center;
                line-height: 30px;
              }
            }
          }
        }

        &-text {
          flex-grow: 1;
          text-align: center;
          margin-bottom: 28px;
          @media (max-width: $breakpoint-tablet-v) {
            width: 100%;
            text-align: center;
          }
        }

        .solution-link {
          width: 192px;
          position: absolute;
          bottom: 20px;

          @media (max-width: $breakpoint-tablet) {
            
            @media (max-width: $breakpoint-tablet-v) {
              bottom: 30px;
              align-self: center;
            }
          }
        }
      }
    }
  }

  &-title {
    font-weight: 900;
    text-align: center;
    font-size: 38px;
    align-self: center;
    line-height: 57px;
    color: #2F3238;
    margin-bottom: 20px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 34px;
      line-height: 42px;

      @media (max-width: $breakpoint-tablet-v) {
        font-size: 32px;
        line-height: 38px;

        @media (max-width: $breakpoint-mobile) {
          font-size: 28px;
          line-height: 34px;
        }
      }
    }
  }
}
}

.carousel-item {
  display: flex;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0 60px;
  min-height: 430px;
  align-items: stretch;

  @media (max-width: $breakpoint-desktop) {
    padding: 0 30px;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 20px;
      min-height: 380px;

      @media (max-width: $breakpoint-tablet-v) {
        padding: 0 20px;
        min-height: 200px;

        @media (max-width: $breakpoint-mobile) {
          padding: 0 15px;
          min-height: auto;
        }
      }
    }
  }

  &-description {
    flex-grow: 1;
    margin-right: 60px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: $breakpoint-desktop) {
      margin-right: 50px;

      @media (max-width: $breakpoint-tablet) {
        margin-right: 40px;

        @media (max-width: $breakpoint-tablet-v) {

          @media (max-width: $breakpoint-mobile) {
            margin-right: 0;
            padding: 10px 0;
            align-items: center;
          }
        }
      }
    }

    &-title {
      font-size: 38px;
      line-height: 64px;
      font-weight: 900;
      transition: .3s;
      color: #25272B;
      margin-bottom: 40px;

      @media (max-width: $breakpoint-desktop) {
        margin-bottom: 25px;
        font-size: 38px;
        line-height: 54px;
        max-width: 440px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 34px;
          line-height: 42px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              margin-bottom: 15px;
              text-align: center;
              max-width: 380px;
            }
          }
        }
      }
    }

    &-text {
      font-size: 16px;
      line-height: 21px;
      font-weight: 500;
      color: black;
      max-width: 460px;
      margin-bottom: 40px;

      @media (max-width: $breakpoint-desktop) {
        margin-bottom: 25px;

        @media (max-width: $breakpoint-tablet) {

          @media (max-width: $breakpoint-tablet-v) {
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              margin-bottom: 15px;
              text-align: center;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  &-image {
    width: 50%;
    max-width: 380px;
    border-radius: 10px;
    min-height: 430px;
    flex-shrink: 0;

    @media (max-width: $breakpoint-desktop) {
      max-width: 360px;

      @media (max-width: $breakpoint-tablet) {
        max-width: 320px;
        min-height: 380px;

        @media (max-width: $breakpoint-tablet-v) {
          max-width: 260px;
          min-height: 300px;

          @media (max-width: $breakpoint-mobile) {
            display: none;
          }
        }
      }
    }
  }
}

#home-section-trust {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    .home-section-trust-logos-item {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 10px;
      width: calc(100% / 6);
      border: 1px solid white;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      @media (max-width: $breakpoint-tablet) {
        width: calc(100% / 4);

        @media (max-width: $breakpoint-tablet-v) {
          width: calc(100% / 4);
          padding: 10px;

          @media (max-width: $breakpoint-mobile) {
            width: calc(100% / 3);
            padding: 5px;
          }
        }
      }
    }
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }
  }
}

#home-section-profit {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-tablet-v) {
    flex-direction: column;
  }

  &-image {
    width: 50%;
    box-sizing: border-box;
    padding-right: 32px;

    img {
      width: 100%;
    }

    @media (max-width: $breakpoint-desktop) {
      padding-right: 24px;

      @media (max-width: $breakpoint-tablet) {
        padding-right: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 70%;
          max-width: 400px;
          padding: 0;
          margin-bottom: 20px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
      }
    }
  }

  &-info {
    flex: 1;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    flex-shrink: 0;
    font-weight: 900;
    font-size: 38px;
    padding-left: 32px;
    line-height: 52px;
    transition: .3s;
    color: white;

    @media (max-width: $breakpoint-desktop) {
      font-size: 38px;
      line-height: 40px;
      padding-left: 24px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 30px;
        line-height: 36px;
        padding-left: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 30px;
          line-height: 36px;
          width: 100%;
          text-align: center;
          flex-direction: column;
          align-items: center;
          padding: 0;

          @media (max-width: $breakpoint-mobile) {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
    }

    &-title {
      margin-bottom: 24px;
      white-space: break-spaces;

      @media (max-width: $breakpoint-tablet-v) {
        width: 80%;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          max-width: 350px;
        }
      }
    }
  }
}

#home-section-creator {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-tablet-v) {
    flex-direction: column;
  }

  &-image {
    width: 50%;
    box-sizing: border-box;
    padding-right: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: $breakpoint-desktop) {
      padding-right: 24px;

      @media (max-width: $breakpoint-tablet) {
        padding-right: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 70%;
          padding: 0;
          margin-bottom: 20px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 400px;
          }
        }
      }
    }

    &-inner {
      width: 580px;
      height: 580px;

      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("https://hotellab.io/media/Anatoly-mob2.png");

      &[data-lang="en"] {
        background-image: url("https://hotellab.io/media/Instead_of_Nikos_illustration2x_4FF5Kgz.png");
      }

      @media (max-width: $breakpoint-desktop) {
        width: 580px;
        height: 580px;

        @media (max-width: $breakpoint-tablet) {
          width: 420px;
          height: 420px;

          @media (max-width: $breakpoint-tablet-v) {
            /*width: 240px;
            height: 240px;*/
            background-image: url("https://hotellab.io/media/Anatoly-mob2.png");

            &[data-lang="en"] {
              background-image: url("https://hotellab.io/media/Instead_of_Nikos_illustration2x_4FF5Kgz.png");
            }

            @media (max-width: $breakpoint-mobile) {
              /*  width: 120px;
                height: 120px;*/
            }
          }
        }
      }

      img {
        width: 100%;
      }
    }

    &-desc {
      margin-top: 20px;
      text-align: center;

      @media (max-width: $breakpoint-tablet) {
        margin-top: 8px;
      }

      &-name {
        //color: $primary;
        font-size: 20px;
        font-weight: 900;
      }

      &-position {
        margin-top: 4px;
        font-size: 16px;
        font-weight: 400;
        color: #25272B80;
      }
    }
  }

  &-info {
    flex: 1;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    flex-shrink: 0;
    font-weight: 900;
    font-size: 38px;
    padding-left: 32px;
    line-height: 46px;
    transition: .3s;

    @media (max-width: $breakpoint-desktop) {
      font-size: 38px;
      line-height: 46px;
      padding-left: 24px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 24px;
        line-height: 34px;
        padding-left: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 24px;
          line-height: 36px;
          width: 100%;
          max-width: 550px;
          text-align: center;
          box-sizing: border-box;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          background-color: rgba(0, 0, 0, 0.03);

          @media (max-width: $breakpoint-mobile) {
            font-size: 24px;
            line-height: 30px;
            padding: 15px 10px;
          }
        }
      }
    }

    &-title {
      color: #2F3238;
      margin-bottom: 24px;

      @media (max-width: $breakpoint-tablet-v) {
        margin-bottom: 10px;
        max-width: 400px;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          max-width: 350px;
        }
      }
    }

    &-description {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0;
      box-sizing: border-box;
      font-weight: 400;

      @media (max-width: $breakpoint-tablet-v) {
        width: 80%;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          padding: 0 5px;
        }
      }
    }
  }
}
</style>
