<template>
  <div :class="b({ primary })">
    <h2
      v-if="title"
      :class="e('title')"
      v-text="title"
    />
    <div
      :class="e('content')"
      :style="{ maxWidth: `${maxWidth}px` }"
    >
      <slot/>
    </div>
  </div>
</template>

<script>
import {bemMixin} from '@/mixins'

export default {
  name: "PageSection",
  blockName: 'page-section',
  mixins: [bemMixin],
  props: {
    primary: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    maxWidth: {
      type: Number,
      default: 1280
    },
  },
}
</script>

<style lang="scss" scoped>
.page-section {
  $block: &;

  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include adaptiveValue(
    padding,
    90px $padding-edge-large-desktop,
    60px $padding-edge-desktop,
    60px $padding-edge-tablet,
    40px $padding-edge-tablet,
    40px $padding-edge-tablet
  );

  &__title {
    margin: 0;
    width: 100%;
    line-height: 1.25;
    color: #2F3238;

    font: {
      size: 38px;
      weight: 900;
    }

    text-align: center;

    @include adaptiveValue(
      font-size,
      38px,
      38px,
      36px,
      32px,
      28px
    );

    @include adaptiveValue(
      'margin-bottom',
      40px,
      40px,
      32px,
      20px,
      20px,
    )
  }

  &__content {
    width: 100%;
    max-width: $max-width;
    box-sizing: border-box;
  }

  &--primary {
    background-color: $primary;

    #{$block}__title {
      color: white;
    }
  }
}
</style>
