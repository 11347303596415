<template>
    <BasePage>
        <PageSection
          :max-width="1050"
        >
          <ImageWithInfo
            class="head"
            :image-url="require('@/assets/images/ota-insight/main_slide.png')"
            :title="'Predictive market demand data to automate your pricing, distribution and inventory decisions'"
            large-image
          />
          <div class="main-button">
            <router-link :to="{name: 'Demo',}" class="main-button-button">
                <BaseButton filled>
                    {{getTranslate('buttonForADemo')}}
                </BaseButton>
            </router-link>
          </div>
        </PageSection>

        <PageSection primary>
            <div id="ota-section-integration">
                <div id="ota-section-integration-image">
                    <img src="../assets/images/ota-insight/purple_box.png" alt="">
                </div>
                <div id="ota-section-integration-info">
                    <div id="ota-section-integration-info-description">
                        <ul>
                            <li>Be proactive not reactive. This integrated insight and trend data helps to adjust pricing ahead of market shifts</li>
                            <li>Identify the right data for decision making to suit your business strategy</li>
                            <li>Customise your competitor set like never before</li> 
                        </ul>
                    </div>
                </div>
            </div>
        </PageSection>
        <PageSection :title="'Key benefits of our integrations'">
            <div class="ota-grid">
                <div class="ota-grid-item">
                    <div class="ota-grid-item-desc">
                        <div class="ota-grid-item-desc-title">
                          Advanced demand data with Lighthouse’s Market Insight and Skyscanner’s flight search data
                        </div>
                    </div>
                </div>
                <div class="ota-grid-item">
                    <div class="ota-grid-item-desc">
                        <div class="ota-grid-item-desc-title">
                          Adaptive minimum length of stay restrictions
                        </div>
                    </div>
                </div>
                <div class="ota-grid-item">
                    <div class="ota-grid-item-desc">
                        <div class="ota-grid-item-desc-title">
                          Utilise customer booking patterns to update pricing strategies across different channels
                        </div>
                    </div>
                </div>
                <div class="ota-grid-item">
                    <div class="ota-grid-item-desc">
                        <div class="ota-grid-item-desc-title">
                          Drive more direct bookings with popular room categories over OTA channels
                        </div>
                    </div>
                </div>
                <div class="ota-grid-item">
                    <div class="ota-grid-item-desc">
                        <div class="ota-grid-item-desc-title">
                          Dynamic pricing that responds to both your room types and your competitors
                        </div>
                    </div>
                </div>
            </div>
        </PageSection>

        <PageSection>
            <div class="page-section-inner" id="points">
                <div class="page-section-inner-title">How does it work?</div>
                <div id="points-item">
                    <div id="points-item-image">
                        <img src="../assets/images/ota-insight/1.svg" alt="">
                    </div>
                    <div id="points-item-desc">
                        <div id="points-item-desc-title">
                          Hotellab - smart solutions for smart hotels
                        </div>
                        <div id="points-item-desc-text">
                          After subscribing to these predictive demand data products, the hotellab RMS system allows you to build price 
                          management and forecasting for your hotel based on the deepest market analysis.
                        </div>
                    </div>
                </div>
                <div id="points-item">
                    <div id="points-item-image">
                        <img src="../assets/images/ota-insight/2.svg" alt="">
                    </div>
                    <div id="points-item-desc">
                        <div id="points-item-desc-title">
                          Customisation is the key
                        </div>
                        <div id="points-item-desc-text">
                          In today's uncertain market, choosing the right data for decision-making is crucial. 
                          Hotellab's RMS helps you identify and leverage the most impactful parameters for your specific property.
                        </div>
                    </div>
                </div>
                <div id="points-item">
                    <div id="points-item-image">
                        <img src="../assets/images/ota-insight/3.svg" alt="">
                    </div>
                    <div id="points-item-desc">
                        <div id="points-item-desc-title">
                          Real-time prices updates
                        </div>
                        <div id="points-item-desc-text">
                          The hospitality landscape can change in an instant. 
                          With hotellab's automatic price updates, you can ensure your rates always reflect the most current market conditions. 
                        </div>
                    </div>
                </div>
                <div id="points-item">
                    <div id="points-item-image">
                        <img src="../assets/images/ota-insight/4.svg" alt="">
                    </div>
                    <div id="points-item-desc">
                        <div id="points-item-desc-title">
                          Be proactive and not reactive
                        </div>
                        <div id="points-item-desc-text">
                          By incorporating Lighthouse's demand index and Skyscanner's flight search trends, 
                          hotellab's RMS helps you adjust prices ahead of market shifts, putting you ahead of the competition.
                        </div>
                    </div>
                </div>
            </div>
        </PageSection>
        
        <PageSection>
          <BaseSignUp withTitle></BaseSignUp>
        </PageSection>
    </BasePage>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import ImageWithInfo from "@/components/widgets/ImageWithInfo";
import {mapGetters} from "vuex";
import BasePage from "@/components/BasePage";
import PageSection from "@/components/PageSection";
import BaseSignUp from "@/components/BaseSignUp.vue";

export default {
  name: 'OtaInsight',
  computed: {
    ...mapGetters([
      'getTranslate',
      'lang',
      'images',
    ]),
  },
  components: {
    BaseButton,
    BasePage,
    ImageWithInfo,
    PageSection,
    BaseSignUp
  },
}
</script>

<style lang="scss" scoped>
.page-section-inner :nth-child(odd){
  flex-direction: row-reverse;
  
  #points-item-desc {
    padding-right: 60px;
  }
}

.head {
  img {
    visibility: hidden;
  }
}

.main-button {
  display: flex;
  justify-content: center;

  &-button {
    width: 210px;
    padding-top: 60px;
    padding-left: 10%;

    @media (max-width: $breakpoint-tablet-v) {
    padding-left: 0;
    
    margin: auto;
  }

  }
}

.page-section-inner {
  padding-left: 14%;
  padding-right: 14%;

  margin-top: -90px;
  
  @media (max-width: $breakpoint-tablet-v) {
    margin-top: 0;
  }
  
  &-title {
    color: $primary;
    font-weight: 900;
    font-size: 38px;
    text-align: center;
    margin-bottom: 30px;
  }
}

#ota-section-integration {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-tablet-v) {
    flex-direction: column;
  }

  &-image {
    width: 50%;
    box-sizing: border-box;
    padding-right: 32px;

    img {
      width: 100%;
    }

    @media (max-width: $breakpoint-desktop) {
      padding-right: 24px;

      @media (max-width: $breakpoint-tablet) {
        padding-right: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 70%;
          max-width: 400px;
          padding: 0;
          margin-bottom: 20px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
      }
    }
  }

  &-info {
    width: 50%;
    color: white;

    @media (max-width: $breakpoint-tablet-v) {
      width: 100%;
    }

    &-description {
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0;
        font-weight: 400;

        @media (max-width: $breakpoint-desktop) {
          font-size: 22px;
          line-height: 30px;
          margin-left: -30px;
          @media (max-width: $breakpoint-tablet) {
            width: 100%;
            font-size: 20px;
            line-height: 28px;
          }
        }

        ul {
          width: 80%;
          list-style-type: none;

          @media (max-width: $breakpoint-tablet) {
            width: 90%;

            @media (max-width: $breakpoint-tablet-v) {
              width: 100%;
            }      
          }

          li {
            padding: 20px;
            box-sizing: border-box;

            @media (max-width: $breakpoint-mobile) {
              padding: 15px;
              margin: 10px 0;
            }

          }
        }
    }
  }
}

.ota-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &-item {
    background-color: #f9faff;
    position: relative;
    width: calc(80%/3);
    height: 170px;
    margin: 15px 10px;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 13px 1px rgb(210, 210, 210);

    @media (max-width: $breakpoint-desktop) {
      flex-direction: column;
      height: inherit;

      @media (max-width: $breakpoint-tablet-v) {
        width: 100%;
        height: 100px;
        margin-left: 0;
        flex-direction: row;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          flex-direction: column;
          height: auto;
        }
      }
    }

    &-desc {
      color: #2F3238;
      text-align: center;
      flex-basis: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 30px;
      flex-direction: column;

      &-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        

        @media (max-width: $breakpoint-tablet) {
          font-size: 20px;
          line-height: 28px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 20px;
            line-height: 28px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}

#points-item {
  display: flex;
  align-items: center;
  
  @media (max-width: $breakpoint-tablet-v) {
    flex-direction: column;
  }

  &-image {
    width: 40%;
    box-sizing: border-box;
    padding-right: 32px;
    display: flex;
    flex-direction: column;

    @media (max-width: $breakpoint-desktop) {
      padding-right: 24px;

      @media (max-width: $breakpoint-tablet) {
        padding-right: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 70%;
          padding: 0;
          margin-bottom: 20px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 400px;
          }
        }
      }
    }
  }

  &-desc {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: 32px;
    line-height: 40px;

    @media (max-width: $breakpoint-desktop) {
      line-height: 35px;
      padding-left: 24px;
      padding-bottom: 40px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 24px;
        line-height: 30px;
        padding-left: 18px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 24px;
          line-height: 30px;
          width: 100%;
          max-width: 550px;
          text-align: center;
          flex-direction: column;
          align-items: center;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 24px;
            line-height: 25px;
            padding: 15px 10px;
          }
        }
      }
    }

    &-title {
      color: $primary;
      font-weight: 900;
      font-size: 20px;
      margin-bottom: 15px;

      @media (max-width: $breakpoint-tablet-v) {
        margin-bottom: 10px;
        max-width: 400px;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          max-width: 350px;
        }
      }
    }

    &-text {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0;
      box-sizing: border-box;
      font-weight: 400;

      @media (max-width: $breakpoint-tablet-v) {

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          padding: 0 5px;
        }
      }
    }
  }
}

.hubspotform {
  margin: auto;
  width: 80%;
}
</style>