<template>
  <div :data-id="this.uniqueId" :ref="this.uniqueId" />
</template>

<script>
import { v4 as uuid } from 'uuid'
import {mapGetters} from "vuex";

const ERROR_MESSAGE = {
  NOT_FOUND_FORM_ID: 'Not found formId for current language',
}

export default {
  name: "HubspotForm",
  data() {
    return {
      uniqueId: uuid()
    }
  },
  props: {
    formId: {
      type: Object,
      required: true
    },
    scriptSrc: {
      type: String,
      default: 'https://js.hsforms.net/forms/v2.js'
    },
  },
  mounted() {
    this.updateForm()
  },
  computed: {
    ...mapGetters(['lang']),
    curFormId() {
      return this.formId?.[this.lang]
    },
  },
  watch: {
    curFormId() {
      this.updateForm()
    },
  },
  methods: {
    updateForm() {
      this.clearForm()
      this.mountForm()
    },
    mountForm() {
      const script = this.createScript();
      document.body.appendChild(script);
      this.clearForm();

      script.onload = this.handleScriptLoad
    },
    clearForm() {
      const formWrapper = this.$refs[this.uniqueId]

      if (formWrapper) {
        formWrapper.innerHTML = ''
      }
    },
    createScript() {
      const script = document.createElement("script")
      script.src = this.scriptSrc

      return script
    },
    handleScriptLoad() {
      if (!this.curFormId) {
        console.error(ERROR_MESSAGE.NOT_FOUND_FORM_ID)
        return
      }

      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "7975392",
          formId: this.curFormId,
          target: `[data-id="${this.uniqueId}"]`,
        })
      }
    },
  },
}
</script>