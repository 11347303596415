<template>
  <div
      v-if="isLoading"
      :key="'app-load'"
      id="app-load"
  >
    <div class="app-load-loader">
      <img src="@/assets/flask_animate.svg" alt="">
    </div>
  </div>
  
  <div
      v-else
      :key="'app'"
      id="app"
  >
    <TransitionFade>
      <div id="cookie-agreement" v-if="!isLoading && !cookieAgreement" @click="acceptCookieAgreement">
        {{ getTranslate('cookieLabel') }}
        <div id="cookie-agreement-btn">OK</div>
      </div>
    </TransitionFade>
    <slot></slot>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import TransitionFade from "@/components/TransitionFade";

export default {
  name: "EntitiesContext",
  computed: {
    ...mapGetters([
        'isLoading',
        'getTranslate',
        'cookieAgreement'
    ]),
  },
  methods: {
    ...mapActions([
        'loadEntities',
        'acceptCookieAgreement'
    ]),
  },
  created() {
    this.loadEntities();
  },
  components: {
    TransitionFade,
  },
}
</script>


<style lang="scss" scoped>
#cookie-agreement {
  position: fixed;
  width: calc(100% - 40px);
  box-sizing: border-box;
  padding: 20px;
  left: 20px;
  box-shadow: 0 0 20px 10px rgba(0,0,0,0.2);
  border-radius: 10px;
  text-align: center;
  bottom: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(137deg, $primary, $secondary) $primary;
  color: white;
  line-height: 21px;
  z-index: 4444444;
  max-width: 400px;

  &-btn {
    box-sizing: border-box;
    cursor: pointer;
    padding: 6px 24px;
    margin-top: 20px;
    border-radius: 5px;
    border: 2px solid white;
  }
  
  @media (max-width: $breakpoint-tablet-v) {
    width: 70%;
    padding: 20px;
    border-radius: 0 10px 0 0;
    bottom: 0;
    left: 0;
  }
}

#app-load {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  .app-load-loader {
    height: 200px;

    img {
      width: 100%;
      max-height: 100%;
    }
  }
}
</style>