<template>
  <div id="webinar">
    <div class="demo-main-photo" :style="{background: `url('${webinarImage}') 50% 50%`, backgroundSize: 'cover',}">
      <div class="demo-main-photo-title">
        {{ getTranslateFromItem(main.translation, 'title') }}
        <div class="demo-main-photo-title-date">
          {{ getTranslateFromItem(main.translation, 'date') }}
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslateFromItem(description.translation, 'title') }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0px',
            whiteSpace: 'pre-wrap',
          }"
        >
          <div v-html="descriptionBefore"></div>
          <ul>
            <li v-for="e in description.list">{{ getTranslateFromItem(e.translate, 'text') }}</li>
          </ul>
          <div>{{ getTranslateFromItem(description.translation, 'after') }}</div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslateFromItem(program.translation, 'title') }}</div>
        <div v-for="e in program.days" class="programme-item">
          <div class="programme-item-day">{{ getTranslateFromItem(e.translation, 'title') }}</div>
          <div class="programme-item-content">
            <ol
                type="1"
            >
              <li
                v-for="(item, i) in e.programme"
                class="programme-item-content-item"
              >
                <div class="programme-item-content-item-title">{{ getTranslateFromItem(item.translation, 'title') }}</div>
                <div class="programme-item-content-item-speaker" v-if="item.general.speaker">{{ getTranslateFromItem(item.translation, 'speaker') }}</div>
                <div class="programme-item-content-item-modules">{{ getTranslateFromItem(item.translation, 'modules') }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="e in targets"
        class="page-section-wrapper marketing-section-analytics-wrapper"
        :class="{
          'marketing-section-analytics-wrapper__reverse': e.general.reverse,
        }"
    >
      <div
          class="page-section-inner marketing-section-analytics"
      >
        <div class="marketing-section-analytics-image">
          <img :src="e.general.photo" alt="">
        </div>
        <div class="marketing-section-analytics-info">
          <div class="marketing-section-analytics-info-title">
            {{ getTranslateFromItem(e.translation, 'title') }}
          </div>
          <div class="marketing-section-analytics-info-description">{{
              getTranslateFromItem(e.translation, 'description')
          }}</div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarSpeakersTitle') }}</div>
        <CarouselWebinar :speakers="speakers"></CarouselWebinar>
      </div>
    </div>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('webinarPartnersTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item" v-for="e in partners">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarFormTitle') }}</div>
        <div id="hubspotForm" ref="hubspot-form1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import webinarImage from '@/assets/images/webinar/webinar.jpg';
import seoImage from '@/assets/images/webinar/feb22.png';
import speakerHvostovaImage from '@/assets/images/speakers/hvostova.jpg';
import speakerMatveevaImage from '@/assets/images/speakers/matveeva.jpg';
import speakerKarasevaImage from '@/assets/images/speakers/karaseva.jpg';
import speakerChesnokovImage from '@/assets/images/speakers/chesnokov.jpg';
import speakerMoskvinaImage from '@/assets/images/speakers/moskvina.jpg';
import speakerAnatoliyImage from '@/assets/images/speakers/anatoliy.jpg';
import hotelInsImage from '@/assets/images/partners/hotelIns.png';
import bagetImage from '@/assets/images/partners/baget.png';
import variantImage from '@/assets/images/partners/variant.png';
import travellineImage from '@/assets/images/partners/travelline.png';
import bronevikImage from '@/assets/images/partners/bronevik.png';
import WRKHImage from '@/assets/images/partners/wrkh.jpg';
import MPATImage from '@/assets/images/partners/mpat.jpg';
import hotImage from '@/assets/images/partners/hot.jpg';
import wtImage from '@/assets/images/partners/wt.png';
import {mapGetters} from "vuex";
import CarouselWebinar from "@/components/CarouselWebinar";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Webinar",
  computed: {
    ...mapGetters([
        'getTranslate',
        'getTranslateFromItem',
        'lang',
        'integrations',
    ]),
    descriptionBefore() {
      return this.getTranslateFromItem(this.description.translation, 'before');
    },
  },
  watch: {
    lang() {
      this.appendForm();
    },
  },
  mounted() {
    this.appendForm();
    this.createMeta("og:url", "https://hotellab.io/ru/wrw-feb22");
    this.createMeta("og:type", "article");
    this.createMeta("og:title", "WEB Revenue Workshop для мини-отелей и апартаментов");
    this.createMeta("og:description", "Цифровые технологии и RMS системы в небольших объектах гостиничного бизнеса. Реальные кейсы внедрения, реальные цифры эффективности, последние тренды, эксперты-практики.");
    this.createMeta("og:image", seoImage);
  },
  methods: {
    createMeta(attr, content) {
      const meta = document.createElement("meta");
      meta.setAttribute("property", attr);
      meta.content = content;
      document.getElementsByTagName("head")[0].appendChild(meta);
    },
    appendForm() {
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/shell.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "f9a8439f-f72f-4e2c-b542-cc1a2dc129dc",
            target: '#hubspotForm',
          });
        }
      })
    },
  },
  components: {
    CarouselWebinar,
    BaseButton
  },
  data: () => ({
    webinarImage,
    main: {
      translation: {
        ru: {
          title: 'WEB Revenue Workshop',
          date: 'Для мини-отелей и апартаментов \n Онлайн | 2 Февраля | 11:00-14:00 мск'
        },
        en: {
          title: 'WEB Revenue Workshop',
          date: 'Для мини-отелей и апартаментов \n Онлайн | 2 Февраля | 11:00-14:00 мск'
        },
      },
    },
    description: {
      list: [
      ],
      translation: {
        ru: {
          title: 'О чём  наш воркшоп',
          before: '<p><b>Компания Hotellab не стала раскачиваться в послепраздничные дни. Оперативно подготовила первый в новом году воркшоп.</b></p>' +
          '<p>Онлайн воркшоп - целенаправленно для мини-отелей и апартаментов.</p>' +
          '<p>Совместно с такими партнерами как Travelline и Hotel Insight.</p>' +
          '<p>На воркшопе мы:</p>' +
          '<ul><li>обсудим текущие тренды спроса и технологии Revenue Management, которые применимы  для небольших объектов.</li>' +
          '<li>выясним, где сегодня в отеле возможна полная автоматизация, а где все еще необходим  человек.</li>' +
          '<li>Особое внимание будет уделено экономической выгоде внедрения цифровых технологий и Revenue Management  в отельный бизнес.</li></ul>' +
          'Поэтому наш воркшоп особенно интересен тем отельерам и собственникам, которые смотрят на свой бизнес через цифры.' +
          'Во время воркшопа  у вас будет отличная возможность задать вопросы:' +
          '<ul><li>отельерам, успешно внедрившим у себя Revenue Management System (RMS)</li>' +
          '<li>экспертам по Revenue Management</li>' +
          '<li>разработчикам современных технологических решений в сфере Revenue Management и онлайн продаж</li></ul>' +
          '<p>Чтобы получить ссылку для подключения, необходимо обязательно зарегистрироваться.</p>',
          after: '',
        },
        en: {
          title: 'What is our workshop about',
          before: '<p><b>Компания Hotellab не стала раскачиваться в послепраздничные дни. Оперативно подготовила первый в новом году воркшоп.</b></p>' +
          '<p>Онлайн воркшоп - целенаправленно для мини-отелей и апартаментов.</p>' +
          '<p>Совместно с такими партнерами как Travelline и Hotel Insight.</p>' +
          '<p>На воркшопе мы:</p>' +
          '<ul><li>обсудим текущие тренды спроса и технологии Revenue Management, которые применимы  для небольших объектов.</li>' +
          '<li>выясним, где сегодня в отеле возможна полная автоматизация, а где все еще необходим  человек.</li>' +
          '<li>Особое внимание будет уделено экономической выгоде внедрения цифровых технологий и Revenue Management  в отельный бизнес.</li></ul>' +
          'Поэтому наш воркшоп особенно интересен тем отельерам и собственникам, которые смотрят на свой бизнес через цифры.' +
          'Во время воркшопа  у вас будет отличная возможность задать вопросы:' +
          '<ul><li>отельерам, успешно внедрившим у себя Revenue Management System (RMS)</li>' +
          '<li>экспертам по Revenue Management</li>' +
          '<li>разработчикам современных технологических решений в сфере Revenue Management и онлайн продаж</li></ul>' +
          '<p>Чтобы получить ссылку для подключения, необходимо обязательно зарегистрироваться.</p>',
          after: '',
        },
      },
    },
    program: {
      days: [
        {
          general: {
            id: 0,
          },
          programme: [
            {
              general: {
                id: 5,
                speaker: false,
              },
              translation: {
                ru: {
                  title: '11:00-11:40',
                  speaker: '',
                  modules: 'Какие технологии Revenue Management эффективны для небольших отелей (Hotellab) \n' +
                  '- Особенности динамического ценообразования для небольших отелей \n' +
                  '- Чек-лист Revenue менеджера небольшого отеля \n' +
                  '- Карта настройки источников спроса отелей в зависимости от кол-ва номеров \n',
                },
                en: {
                  title: '11:00-11:40',
                  speaker: '',
                  modules: 'Какие технологии Revenue Management эффективны для небольших отелей (Hotellab) \n' +
                  '- Особенности динамического ценообразования для небольших отелей \n' +
                  '- Чек-лист Revenue менеджера небольшого отеля \n' +
                  '- Карта настройки источников спроса отелей в зависимости от кол-ва номеров \n',
                },
              },
            },
            {
              general: {
                id: 6,
                speaker: false,
              },
              translation: {
                ru: {
                  title: '11:40-12:20',
                  speaker: '',
                  modules: 'Гости, которые возвращаются: как привлекать лояльных клиентов \n' +
                  '1. Точки касания с клиентом от бронирования до выезда: что улучшить \n' +
                  '2. «Вау-эффект»: как завоевать лояльность клиентов \n' +
                  '3. Брони по рекомендациям: что гость расскажет о вас друзьям \n' +
                  '4. Новинки и лайфхаки от TravelLine, которые помогают зарабатывать больше'
                },
                en: {
                  title: '11:40-12:20',
                  speaker: '',
                  modules: 'Гости, которые возвращаются: как привлекать лояльных клиентов \n' +
                  '1. Точки касания с клиентом от бронирования до выезда: что улучшить \n' +
                  '2. «Вау-эффект»: как завоевать лояльность клиентов \n' +
                  '3. Брони по рекомендациям: что гость расскажет о вас друзьям \n' +
                  '4. Новинки и лайфхаки от TravelLine, которые помогают зарабатывать больше'
                },
              },
            },
            {
              general: {
                speaker: false,
                id: 7,
              },
              translation: {
                ru: {
                  title: '12:20-13:00',
                  speaker: '',
                  modules: 'Эффективность внедрения Revenue Management System (RMS) в цифрах \n' +
                  '- Калькулятор расчета ROI (возврат от вложений) от внедрения RMS \n' +
                  '- Кейс отеля Багет Нижний Новгород (15 номеров) \n' +
                  '- Кейс сети апарт-отелей Variant (46 апартаментов) \n',
                },
                en: {
                  title: '12:20-13:00',
                  speaker: '',
                  modules: 'Эффективность внедрения Revenue Management System (RMS) в цифрах \n' +
                  '- Калькулятор расчета ROI (возврат от вложений) от внедрения RMS \n' +
                  '- Кейс отеля Багет Нижний Новгород (15 номеров) \n' +
                  '- Кейс сети апарт-отелей Variant (46 апартаментов) \n',
                },
              },
            },
            {
              general: {
                speaker: false,
                id: 8,
              },
              translation: {
                ru: {
                  title: '13:00-13:40',
                  speaker: '',
                  modules: 'Где заканчивается автоматизация и становится нужен человек (Hotel Insight) \n' +
                  '- Кто такой revenue manager и зачем он нужен небольшому отелю \n' +
                  '- Как работа с онлайн каналами помогает увеличить доход \n' +
                  '- Какие задачи решает rms и почему система не заменит сотрудника \n',
                },
                en: {
                  title: '13:00-13:40',
                  speaker: '',
                  modules: 'Где заканчивается автоматизация и становится нужен человек (Hotel Insight) \n' +
                  '- Кто такой revenue manager и зачем он нужен небольшому отелю \n' +
                  '- Как работа с онлайн каналами помогает увеличить доход \n' +
                  '- Какие задачи решает rms и почему система не заменит сотрудника \n',
                },
              },
            },
            {
              general: {
                speaker: false,
                id: 9,
              },
              translation: {
                ru: {
                  title: '13:40-14:00',
                  speaker: '',
                  modules: 'Ответы на вопросы',
                },
                en: {
                  title: '13:40-14:00',
                  speaker: '',
                  modules: 'Ответы на вопросы',
                },
              },
            },
          ],
          translation: {
            ru: {
              title: '',
            },
            en: {
              title: '',
            },
          },
        },
      ],
      translation: {
        ru: {
          title: 'Программа',
          description: 'Бюджетирование и планирование | Технологии продаж и маркетинга',
        },
        en: {
          title: 'Program',
          description: 'Budgeting and Planning | Sales and marketing technologies',
        },
      },
    },
    partners: [
      {
        "id": 4,
        "generic": {
          "photo": wtImage,
          "url": "https://welcometimes.ru/",
        },
      },
      {
        "id": 5,
        "generic": {
          "photo": bagetImage,
          "url": "http://baget-hotel.ru/",
        },
      },
      {
        "id": 6,
        "generic": {
          "photo": variantImage,
          "url": "http://variantapart.ru/",
        },
      },
      {
        "id": 7,
        "generic": {
          "photo": travellineImage,
          "url": "https://www.travelline.ru/",
        },
      },
      {
        "id": 8,
        "generic": {
          "photo": hotelInsImage,
          "url": "https://hotelinsight.ru/",
        },
      },
      {
        "id": 9,
        "generic": {
          "photo": "https://hotellab.io/media/hotelier.png",
          "url": "https://hotelier.pro",
        },
      },
      {
        "id": 10,
        "generic": {
          "photo": WRKHImage,
          "url": "https://t.me/wrkhotel",
        },
      },
      {
        "id": 11,
        "generic": {
          "photo": MPATImage,
          "url": "https://mpat.online/",
        },
      },
      {
        "id": 12,
        "generic": {
          "photo": hotImage,
          "url": "https://hotconsulting.ru/",
        },
      },
      {
        "id": 13,
        "generic": {
          "photo": bronevikImage,
          "url": "https://bronevik.com/ru/",
        },
      },
    ],
    targets: [
      {
        general: {
          id: 0,
          photo: 'https://hotellab.io/media/webinarForWho.webp',
        },
        translation: {
          ru: {
            title: 'Для кого?',
            description: 'Для отельеров и собственников отелей и апартаментов объёмом менее 50 номеров, которые хотят увеличить эффективность своего бизнеса.',
          },
          en: {
            title: 'For Who?',
            description: 'Для отельеров и собственников отелей и апартаментов объёмом менее 50 номеров, которые хотят увеличить эффективность своего бизнеса.',
          },
        },
      },
      {
        general: {
          reverse: true,
          id: 1,
          photo: 'https://hotellab.io/media/webinarForWhy.jpg',
        },
        translation: {
          ru: {
            title: 'Что даст?',
            description: '- Анализы трендов спроса. \n' +
            '- Реальные кейсы увеличения продаж на основе IT технологий. \n' +
            '- Инструменты, которые помогут выжать максимум прибыли. \n' +
            '- Возможность быть в тренде и опережать конкурентов.',
          },
          en: {
            title: 'What Can You Get?',
            description: '- Анализы трендов спроса. \n' +
            '- Реальные кейсы увеличения продаж на основе IT технологий. \n' +
            '- Инструменты, которые помогут выжать максимум прибыли. \n' +
            '- Возможность быть в тренде и опережать конкурентов.',
          },
        },
      },
    ],
    speakers: [
      {
        general: {
          photo: speakerAnatoliyImage,
        },
        translation: {
          ru: {
            name: 'Анатолий Бурдаков',
            description: 'Основатель Hotellab.io',
          },
          en: {
            name: 'Anatoly Burdakov',
            description: 'Founder of Hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerKarasevaImage,
        },
        translation: {
          ru: {
            name: 'Анна Бабина',
            description: 'Эксперт по повышению доходности мини-отелей и апартаментов в TravelLine',
          },
          en: {
            name: 'Anna Babina',
            description: 'Эксперт по повышению доходности мини-отелей и апартаментов в TravelLine',
          },
        },
      },
      {
        general: {
          photo: speakerHvostovaImage,
        },
        translation: {
          ru: {
            name: 'Эрика Хвостова',
            description: 'Основатель Hotel Insight',
          },
          en: {
            name: 'Erika Khvostova',
            description: 'Founder of Hotel Insight',
          },
        },
      },
      {
        general: {
          photo: speakerMatveevaImage,
        },
        translation: {
          ru: {
            name: 'Татьяна Матвеева',
            description: 'Директор по ревенью сети отелей и апартаментов VARIANT',
          },
          en: {
            name: 'Tatiana Matveeva',
            description: 'Director of the revenue chain of hotels and apartments VARIANT',
          },
        },
      },
      {
        general: {
          photo: speakerChesnokovImage,
        },
        translation: {
          ru: {
            name: 'Денис Чесноков',
            description: 'Управляющий Багет Отель',
          },
          en: {
            name: 'Denis Chesnokov',
            description: 'Управляющий Багет Отель',
          },
        },
      },
      {
        general: {
          photo: speakerMoskvinaImage,
        },
        translation: {
          ru: {
            name: 'Мария Москвина',
            description: 'Коммерческий директор Hotellab.io',
          },
          en: {
            name: 'Maria Moskvina',
            description: 'Коммерческий директор Hotellab.io',
          },
        },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.demo-main-photo-title-date {
  white-space: pre-wrap;
}
.programme-item {
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  background-color: #F9FAFF;


  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }


  &-day {
    font-weight: 900;
    font-size: 30px;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    background-color: #5d5ca8;
    color: white;

    @media (max-width: $breakpoint-tablet) {
      text-align: center;
      padding: 25px;
      font-size: 24px;
    }
  }

  &-content {
    white-space: pre-wrap;
    font-size: 21px;
    line-height: 30px;
    padding: 40px;
    flex-grow: 1;

    @media (max-width: $breakpoint-tablet) {
      padding: 25px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }

    ol {
      margin: 0;
    }

    &-item {
      margin-bottom: 20px;

      &-title {
        font-weight: 900;
      }

      &-speaker {
        font-size: 0.9em;
        font-style: italic;
        opacity: .7;
      }

      &-modules {
        margin-top: 10px;
      }

      &::marker {
        font-weight: 900;
      }
    }
  }
}
.demo-main-photo {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(50vh - 80px);
    }
  }

  &-title {
    font-size: 60px;
    line-height: 72px;
    max-width: 90%;
    width: 1000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    color: white;
    padding: 20px 70px;
    background-color: #5d5ca8;

    &-date {
      font-size: 30px;
      line-height: 36px;
      margin-top: 10px;


      @media (max-width: $breakpoint-desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 10px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 22px;
            line-height: 28px;
            margin-top: 5px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
      }
    }


    @media (max-width: $breakpoint-desktop) {
      font-size: 50px;
      line-height: 60px;
      max-width: 700px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 36px;
        line-height: 44px;
        padding: 20px 40px;
        max-width: 500px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 28px;
          line-height: 34px;
          width: 90%;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.webinar-target {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column !important;
  }

  &-title {
    width: 50%;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 900;
    font-size: 60px;
    text-align: left;
    display: flex;
    justify-content: flex-start;

    &-inner {
      background-color: #5d5ca8;
      color: white;
      padding: 10px 30px;
    }
  }

  &-description {
    width: 50%;
    font-size: 25px;
    box-sizing: border-box;
    padding: 40px;
    text-align: right;
    line-height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
  }
}

.webinar-target__active {
  flex-direction: row;

  .webinar-target-title {
    text-align: right;
    justify-content: flex-end;
  }

  .webinar-target-description {
    text-align: left;
  }
}

.marketing-section-analytics-wrapper__reverse {
  background-color: transparent !important;
  .marketing-section-analytics {
    flex-direction: row-reverse;
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }

    &-title {
    }
  }
}

.marketing-section-analytics-wrapper {
  background-color: #F9FAFF;

  .marketing-section-analytics {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: column;
    }

    &-image {
      width: 50%;
      box-sizing: border-box;
      padding-right: 32px;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 20px;
      }

      @media (max-width: $breakpoint-desktop) {
        padding-right: 24px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            width: 70%;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }

    &-info {
      flex: 1;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      flex-shrink: 0;
      font-weight: 900;
      font-size: 40px;
      padding-left: 32px;
      line-height: 52px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        font-size: 34px;
        line-height: 40px;
        padding-left: 24px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 36px;
          padding-left: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;
          margin-bottom: 12px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }

      &-description {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        width: 80%;
        white-space: pre-wrap;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }
  }
}
</style>
