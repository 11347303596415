import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";
import { images } from "@/ext";
import { HOST_URL } from "@/settings";
import {
    fetchContentPageById,
    fetchNewsById,
    getEntities
} from "@/api";

Vue.use(Vuex);

const filterItemsByLang = (items, lang) => items.filter(item => item.translation[lang])

const base64ToBlob = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return URL.createObjectURL(new File([u8arr], filename, {type:mime}));
}

const imageCostyl = arr => {
    arr = arr.map(e => {
        e.generic.photo = `${HOST_URL}${e.generic.photo}`;
        e.generic.blog_photo = `${HOST_URL}${e.generic.blog_photo}`;
        e.generic.hotel_image = `${HOST_URL}${e.generic.hotel_image}`;
        return e;
    });
};

const userBrowserLanguage = () => {
    const fallbackLang = 'en'
    const navigatorLang = navigator.language || navigator.userLanguage

    return navigatorLang === 'ru-RU'
        ? 'ru'
        : fallbackLang
}

export default new Vuex.Store({
    state: {
        images: {},
        route: null,
        translations: {
            ru: {},
            en: {},
        },
        languages: ['ru', 'en'],
        lang: localStorage.selectedLanguage ?? userBrowserLanguage(),
        team: [],
        cookieAgreement: localStorage.cookieAgreement,
        carousels: {
            main: [],
            revenue: [],
            marketing: [],
            rate_shopper: [],
            benchmarking: []
        },
        revenueSections: [],
        revenueTechnologies: [],
        trust: [],
        integrations: [],
        news: [],
        cases: [],
        isLoading: true,
        content: {},
    },
    mutations: {
        SET_ROUTE: (state, route) => {
            if (route) {
                let lang = route?.params?.lang;
                state.route = route;

                if (lang !== state.lang) {
                    if (lang && state.languages.indexOf(lang) !== -1) {
                        localStorage.selectedLanguage = lang;
                        state.lang = lang;
                    } else {
                        location.href = `/${state.lang}/`;
                        return;
                    }
                }
            }

            const getTranslate = slug => state.translations[state.lang][slug];

            const getTranslateFromItem = (item, key) => {
                if (item.hasOwnProperty(state.lang) && (item[state.lang]).hasOwnProperty(key)) {
                    return item[state.lang][key]
                }

                return key;
            };

            let slug = state.route.meta.slug;

            if (slug) {
                slug = getTranslate(slug);
            } else {
                slug = '';
            }

            if (state.route.meta.type === 'news') {
                const getNewsById = id => state.news.filter(e => +e.id === +id);
                const news = getNewsById(state.route.params.id)[0];
                if (news) {
                    slug = getTranslateFromItem(news.translation, 'title');
                }
            }

            if (state.route.meta.type === 'case') {
                const getNewsById = id => state.cases.filter(e => +e.id === +id);
                const cases = getNewsById(state.route.params.id)[0];
                if (cases) {
                    slug = getTranslateFromItem(cases.translation, 'title');
                }
            }

            document.title = `Hotellab${slug ? ` | ${slug}` : ''}`;
        },
        SET_IMAGES: (state, images) => {
            let tempImages = {};

            for (let key in images) {
                const value = images[key];
                const typeOfValue = typeof value;

                if (typeOfValue === 'string') {
                    tempImages[key] = base64ToBlob(value);
                } else if (typeOfValue === 'object') {
                    let multilangImage = {};

                    for (let lang in value) {
                        multilangImage[lang] = base64ToBlob(value[lang]);
                    }

                    tempImages[key] = multilangImage;
                }
            }

            state.images = tempImages;
        },
        SET_TRANSLATIONS: (state, slugs) => {
            for (let lang in slugs) {
                state.translations[lang] = slugs[lang];
            }
        },
        SET_COOKIE: state => {
            localStorage.cookieAgreement = true;
            state.cookieAgreement = true;
        },
        SET_NEWS: (state, news) => {
            imageCostyl(news);

            state.news = news;
        },
        SET_CASES: (state, cases) => {
            imageCostyl(cases);

            state.cases = cases;
        },
        SET_LANG: (state, lang) => {
            if (state.languages.indexOf(lang) !== -1) {
                localStorage.selectedLanguage = lang;
                state.lang = lang;
            }
        },
        SET_CAROUSELS: (state, carousels) => {
            imageCostyl(carousels);

            for (let section in state.carousels) {
                state.carousels[section] = carousels.filter(e => e.generic.section === section);
            }
        },
        SET_TRUST: (state, trust) => {
            imageCostyl(trust);

            state.trust = trust;
        },
        SET_REVENUE_SECTIONS: (state, sections) => {
            imageCostyl(sections);

            state.revenueSections = sections;
        },
        SET_REVENUE_TECHNOLOGIES: (state, technologies) => {
            imageCostyl(technologies);

            state.revenueTechnologies = technologies;
        },
        SET_TEAM: (state, team) => {
            imageCostyl(team);

            state.team = team;
        },
        SET_INTEGRATIONS: (state, integrations) => {
            imageCostyl(integrations);

            state.integrations = integrations;
        },
        PUSH_NEWS: (state, news) => {
            if (state.news.filter(e => e.id === news.id).length === 0) {
                state.news = [...state.news, news];
            }
        },
        FINISH_LOADING: (state) => {
            state.isLoading = false;
        },
        PUSH_CONTENT: (state, content) => {
            Vue.set(state.content, content.key, content.value);
        },
    },
    actions: {
        loadEntities: async ({ commit }) => {
            const entities = await getEntities();

            commit('SET_IMAGES', images);
            commit('SET_TRANSLATIONS', entities['slugs']);
            commit('SET_TRUST', entities['trusted_by']);
            commit('SET_TEAM', entities['employees']);
            commit('SET_NEWS', entities['news']);
            commit('SET_CASES', entities['cases']);
            commit('SET_INTEGRATIONS', entities['integrations']);
            commit('SET_CAROUSELS', entities['carousel']);

            commit('SET_ROUTE');

            commit('FINISH_LOADING');
        },
        updateDocumentTitle: ({commit}) => {
            commit('SET_ROUTE');
        },
        acceptCookieAgreement: ({ commit }) => {
            commit('SET_COOKIE');
        },
        checkNewsById: async ({commit}, id) => {
            let news

            try {
                news = await fetchNewsById(id)
            } catch (e) {
                return false
            }

            commit('PUSH_NEWS', news);

            return true
        },
        setLang: async ({ commit }, lang) => {
            commit('SET_LANG', lang);
            await router.push({params: {lang},})
        },
        getNewContent: async ({ commit }, id) => {
            let content

            try {
                content = await fetchContentPageById(id)
            } catch (e) {
                return false
            }

            commit('PUSH_CONTENT', {
                key: id,
                value: content,
            });

            return true;
        },
    },
    getters: {
        content: state => state.content,
        getTranslate: state => slug => state.translations[state.lang][slug] || slug,
        getImage: state => slug => {
            return `${ HOST_URL }/media/${slug}`
            // const imageItem = state.images[slug]
            // return imageItem?.[state.lang] || ''
        },
        getTranslateFromItem: state => (item, key) => {
            if (item.hasOwnProperty(state.lang) && (item[state.lang]).hasOwnProperty(key)) {
                return item[state.lang][key]
            }

            return key;
        },
        getContentById: state => id => state.content[id],
        cookieAgreement: state => state.cookieAgreement,
        getNewsById: state => id => state.news.filter(e => +e.id === +id),
        getCaseById: state => id => state.cases.filter(e => +e.id === +id),
        lang: state => state.lang,
        news: ({news, lang}) => filterItemsByLang(news, lang),
        images: state => state.images,
        cases: ({cases, lang}) => filterItemsByLang(cases, lang),
        team: state => state.team,
        trust: ({trust, lang}) => filterItemsByLang(trust, lang),
        revenueSections: state => state.revenueSections,
        revenueTechnologies: state => state.revenueTechnologies,
        integrations: ({integrations, lang}) => filterItemsByLang(integrations, lang),
        isLoading: state => state.isLoading,
        carousels: ({ carousels, lang }) => {
            const carouselsEntries = Object.entries(carousels)

            return carouselsEntries.reduce((result, carousel) => {
                const [name, items] = carousel
                result[name] = filterItemsByLang(items, lang)
                return result
            }, {})
        },
    },
})
