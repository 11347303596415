var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({staticClass:"button",class:{
    'button__bordered': _vm.bordered,
    'button__filled': _vm.filled,
    'button__shadowed': !_vm.flat,
  },style:({
    width: `${_vm.width}px`,
  })},'div',_vm.props,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }