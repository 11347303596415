<template>
  <div class="news" v-if="!isContentLoading">
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="news-content-inner-title">{{ getTranslateFromItem(curContent.translation, 'title') }}</div>
        <div class="news-content-inner-text" v-html="curContentDescription"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Content",
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters([
        'getContentById',
        'getTranslateFromItem',
        'content'
    ]),
    curContent() {
      const contentId = this.$route.params.id;
      
      if (this.isContentLoading) {
        return null;
      }
      
      return this.getContentById(contentId);
    },
    curContentDescription() {
      if (this.curContent) {
        return this.getTranslateFromItem(
            this.curContent.translation,
            'description'
        );
      }
    },
  },
  methods: {
    ...mapActions([
        'getNewContent'
    ]),
  },
  created() {
    const contentId = this.$route.params.id;
    
    const getContent = async () => {
      if (this.getContentById(contentId)) {
        this.isContentLoading = false;
      } else {
        const res = await this.getNewContent(contentId);
        
        if (!res) {
          await this.$router.push({name: 'NotFound',});
        }
  
        this.isContentLoading = false;
      }
    }
    
    getContent();
  },
  data: () => ({
    isContentLoading: true,
  }),
}
</script>

<style lang="scss" scoped>
.news-content-inner-title {
  font-weight: 900;
  font-size: 44px;
  line-height: 57px;
  margin-bottom: 40px;
  
  @media (max-width: $breakpoint-tablet) {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 30px;
    
    @media (max-width: $breakpoint-tablet-v) {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 20px;
      
      @media (max-width: $breakpoint-mobile) {
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 34px;
      }
    }
  }
}
</style>