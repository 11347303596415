<template>
  <div :class="b()">
    <div
      v-for="(item, idx) in items"
      :key="itemKey(item, idx)"
      :class="e('item')"
    >
      <slot
        :name="`item-${ idx }`"
        v-bind="item"
      />
    </div>
  </div>
</template>

<script>
import {bemMixin} from "@/mixins"

export default {
  name: "BaseGrid",
  blockName: 'base-grid',
  mixins: [bemMixin],
  props: {
    itemId: {
      type: String,
      default: 'id',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    itemKey(item, idx) {
      return item?.id ?? idx
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/style/variables.scss";

.base-grid {
  @function widthForPerRow($perRow) {
    @return calc(100% / #{$perRow});
  }

  $grid: &;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  &__item {
    box-sizing: border-box;
    padding: 20px;
    @include adaptiveValue(
      'width',
      widthForPerRow(3),
      widthForPerRow(3),
      widthForPerRow(2),
      widthForPerRow(2),
      widthForPerRow(1),
    )
  }
}
</style>