import client from "@/api/client";

async function fetchContentPageById(id) {
    let result

    try {
        result = await client.get('content_pages/' + id)
    } catch (e) {
        console.error(e)
        throw e
    }

    return result.data
}

export {
    fetchContentPageById
}