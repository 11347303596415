<template>
<BasePage>
  <PageSection :title="getTranslate('demoCallToActionTitle')">
    <BaseSignUp></BaseSignUp>
    <!-- <div class="demo-page">
      <div class="demo-page__info">
        <h2>{{ getTranslate( 'demoTextTitle' ) }}</h2>
        <ul>
          <li>
            {{ getTranslate( 'demoText1' ) }}
          </li>
          <li>
            {{ getTranslate( 'demoText2' ) }}
          </li>
          <li>
            {{ getTranslate( 'demoText3' ) }}
          </li>
          <li>
            {{ getTranslate( 'demoText4' ) }}
          </li>
          <li>
            {{ getTranslate( 'demoText5' ) }}
          </li>
        </ul>
      </div>
      <div class="demo-page__form" id="hubspotForm" ref="hubspot-form1"></div>
    </div> -->
  </PageSection>
</BasePage>
</template>

<script>
import {mapGetters} from "vuex";
import PageSection from "@/components/PageSection";
import BasePage from "@/components/BasePage";
import BaseSignUp from "../components/BaseSignUp.vue";

export default {
  name: "Demo",
  components: {
    PageSection,
    BasePage,
    BaseSignUp
  },
  data: () => ({

  }),
  /*watch: {
    lang() {
      this.appendScript();
    },
  },*/
  computed: {
    ...mapGetters([
      'getTranslate',
      'lang',
      'cases',
      'carousels',
      'trust',
      'images',
      'integrations',
      'news',
    ]),
  },
  mounted() {
    this.createMeta("description", this.getTranslate('demoCallToActionTitle'));
    /*this.appendScript();*/
  },
  methods: {
    createMeta(attr, content) {
      const meta = document.createElement("meta");
      meta.setAttribute("name", attr);
      meta.content = content;
      document.getElementsByTagName("head")[0].appendChild(meta);
    },
    /*appendScript() {
      // const elemId = 'hubspot-form-demo';
      // const scriptId = 'demo-page-hubspot-script';
      // const script = document.createElement("script");
      // const elem = document.getElementById(elemId);
      // const prevScript = document.getElementById(scriptId);
      // const dataSrc = this.lang === 'ru'
      //     ? 'https://meetings.hubspot.com/sales1532?embed=true'
      //     : 'https://meetings.hubspot.com/sales1532/demo-hotellab?embed=true';
      //
      //
      // if (prevScript) {
      //   prevScript.remove();
      // }
      // elem.dataset.src = dataSrc;
      // elem.innerHTML = '';
      // script.id = scriptId;
      // script.src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
      // document.body.appendChild(script);

      let formId = this.lang === 'ru'
          ? "0a9d92ca-7d25-45ae-9f24-5d8160db10b9"
          : "cfc3ee0b-7825-48d2-a73a-3bee2e2be5e5"
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId,
            target: '#hubspotForm',
          });
        }
      })
    },*/
  },
}
</script>

<style lang="scss" scoped>
h2 {
  padding-left: 32px;
  font-size: 20px;
  line-height: 40px;
}

.page-section {
  padding-top: 20px;
}

/*.hubspot-title {
  display: none;
}*/

</style>
