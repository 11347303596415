<template>
  <BasePage>
    <PageSection :title="translate('contactsFormTitle')">
      <div class="head-office">
        <div
          class="head-office__title"
          v-text="translate('headOfficeTitle')"
        />
        <div class="head-office__info">
          <div
            class="head-office__info-text"
            v-text="translate('headOfficeInfo')"
          />
          <div class="head-office__info-socials">
            <a
              v-for="social in socialsList"
              :href="social.link"
              :key="social.key"
              target="_blank"
            >
              <BaseIcon
                color="secondary"
                :icon="social.icon"
                :size="32"
              />
            </a>
          </div>
        </div>
        <HubspotForm
          class="head-office__form"
          :form-id="{
            ru: '956c226b-ac06-48ed-8829-fc7021e05dc1',
            en: '2f1f4d4f-914d-4ae2-899b-29fbca58841b',
          }"
        />
      </div>
    </PageSection>
    <PageSection
      v-if="lang !== 'ru'"
      :title="translate('contactsLocationsTitle')"
    >
      <div
        v-for="location in locations"
        :key="location.key"
        class="location"
      >
        <div
          class="location__img"
          :style="locationImgStyle(location.img)"
        />
        <div class="location__info">
          <div
            class="location__info-name"
            v-text="translate(location.name)"
          />
          <div
            class="location__info-address"
            v-text="translate(location.address)"
          />
          <BaseButton
            v-text="translate('contactsInfoLocationGoTo')"
            :link-url="location.link"
            link-blank
            filled
          />
        </div>
      </div>
    </PageSection>
  </BasePage>
</template>

<script>
import {mapGetters} from "vuex";
import { v4 as uuid } from 'uuid'
import HubspotForm from "@/components/HubspotForm";
import PageSection from "@/components/PageSection";
import BaseButton from "@/components/BaseButton";
import BasePage from "@/components/BasePage";
import BaseIcon from "@/components/BaseIcon";

const socialItem = ({link, icon}) => ({
  key: uuid(),
  link,
  icon
})

const locationItem = ({link, name, address, img}) => ({
  key: uuid(),
  link,
  address,
  name,
  img,
})

export default {
  name: "Contacts",
  components: {
    HubspotForm,
    PageSection,
    BaseButton,
    BaseIcon,
    BasePage
  },
  computed: {
    ...mapGetters({
      translate: 'getTranslate',
      image: 'getImage',
      lang: 'lang',
    }),
    socialsList() {
      return this.socials[this.lang] || []
    }
  },
  data() {
    return {
      socials: {
        ru: [
          socialItem({
            link: 'https://t.me/hotellab_io',
            icon: 'telegram',
          }),
        ],
        en: [
          socialItem({
            link: 'https://www.linkedin.com/company/hotellab-io',
            icon: 'linkedIn',
          }),
          socialItem({
            link: 'https://www.facebook.com/hotellab.io/',
            icon: 'facebook',
          }),
        ],
      },
      locations: [
        locationItem({
          link: 'https://www.google.com/maps/place/Mikras+Asias+1,+Thessaloniki+543+51,+Greece/@40.6141007,22.9705775,16z',
          name: 'contactsInfoGreeceTitle',
          address: 'contactsInfoGreece',
          img: 'greekOfficePhoto.jpg',
        }),
        locationItem({
          link: 'https://goo.gl/maps/Xh1SiJnTSg5L2fUA8',
          name: 'contactsInfoPortugueseTitle',
          address: 'contactsInfoPortuguese',
          img: 'portugueseOfficePhoto.jpg',
        }),
      ],
    }
  },
  methods: {
    locationImgStyle(img) {
      return {
        background: `url('${this.image(img)}') 50% 50%`,
        backgroundSize: 'cover',
      }
    },
  },
}
</script>

<style lang="scss">
.head-office {
  $gap: 32px;
  display: flex;

  @include adaptiveMobile {
    flex-direction: column;
  }

  &__info, &__title, &__form {
    width: calc((100% / 3) - #{$gap});

    margin-right: $gap;

    @include adaptiveTablet {
      width: calc(50% - #{$gap});
    }

    @include adaptiveMobile {
      width: 100%;

      margin-right: 0;
    }
  }

  &__title, &__info {
    white-space: pre-line;
  }

  &__title {
    font-weight: bold;
    font-size: 38px;

    @include adaptiveTablet {
      display: none;
    }
  }

  &__info {
    font-size: 20px;

    @include adaptiveMobile {
      text-align: center;
      font-size: 20px;

      margin-bottom: $gap;
    }

    &-text {
      margin-bottom: 16px;
    }

    &-socials {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      @include adaptiveMobile {
        justify-content: center;
      }
    }
  }
}

.location:not(:last-child) {
  margin-bottom: 20px;
}

.location {
  $border-radius: 16px;

  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @include adaptiveTabletV {
    flex-direction: column;
  }

  &__img, &__info {
    flex-shrink: 0;
    width: 50%;

    @include adaptiveTabletV {
      width: 100%;
    }
  }

  &__img {
    border-radius: $border-radius 0 0 $border-radius;

    @include adaptiveTabletV {

      border-radius: $border-radius $border-radius 0 0;
      @include aspectRatio(2, 1);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px;
    border: 1px solid #8c8c8c;
    border-radius: 0 $border-radius $border-radius 0;
    border-left-style: none;
    box-sizing: border-box;

    @include adaptiveTabletV {
      padding: 32px;

      border-radius: 0 0 $border-radius $border-radius;
      border-left-style: solid;
      border-top-style: none;
    }

    &-name, &-address {
      font-weight: 500;
      font-size: 22px;
      margin-bottom: 16px;

      @include adaptiveTabletV {
        font-size: 20px;
      }
    }

    &-name {
      color: $primary;
    }

    &-address {
      white-space: pre-line;
    }
  }
}
</style>
