<template>
  <div :class="b()">
    <div
      v-if="iconName"
      :class="e('icon')"
    >
      <BaseIcon :icon="iconName" :size="60"/>
    </div>
    <div
      v-if="title"
      :class="e('title')"
      v-text="title"
    />
    <div
      v-if="text"
      :class="e('text')"
      v-text="text"
    />
  </div>
</template>

<script>
import {bemMixin} from "@/mixins";

export default {
  name: "BaseCard",
  blockName: 'base-card',
  mixins: [bemMixin],
  components: {
    BaseIcon: () => import('@/components/BaseIcon')
  },
  props: {
    iconName: {
      type: String,
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
  },
}
</script>

<style lang="scss" scoped>
.base-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  box-sizing: border-box;
  padding: 20px;

  &__icon {}

  &__title {
    text-align: center;
    line-height: 1.25;
    font-size: 20px;
    font-weight: bold;
  }

  &__text {

  }
}
</style>
