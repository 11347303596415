<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="size"
       :height="size"
       :viewBox="viewBox"
       :aria-labelledby="icon"
       :fill="fill"
  >
    <component :is="selectedIcon"></component>
  </svg>
</template>

<script>
import * as icons from './icons';
import { COLORS } from "@/ext";

export default {
  name: "BaseIcon",
  props: {
    icon: {
      type: String,
      required: true,
      default: 'burger',
    },
    size: {
      type: Number,
      default: 48,
    },
    color: {
      type: String,
      default: 'secondary',
    },
  },
  computed: {
    fill() {
      switch (this.color) {
        case 'primary':
          return COLORS.PRIMARY;
        case 'secondary':
          return COLORS.SECONDARY;
        case 'black':
          return COLORS.BLACK;
        case 'white':
          return COLORS.WHITE;
        default:
          return this.color;
      }
    },
    viewBox() {
      let viewBox = '0 0 64 64';

      switch (this.icon) {
        case 'news':
        case 'gears':
          viewBox = '0 0 172 172';
          break;
        case 'telegram':
          viewBox = '0 0 496 512';
          break;
        case 'facebook':
        case 'linkedIn':
          viewBox = '0 0 20 20';
          break;
        case 'close':
        case 'burger':
        case 'chevronLeft':
        case 'chevronRight':
          viewBox = '0 0 16 16';
          break;
      }

      return viewBox;
    },
    selectedIcon() {
      if (this.icon in icons) {
        return icons[this.icon];
      }

      return icons.clock;
    },
  },
}
</script>

<style scoped>

</style>