<template>
  <div id="blog">
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="filter-tags">
          <button v-on:click="show = 'news'" class="filter-tags-news">{{ getTranslate('linkNews') }}</button>
          <button v-on:click="show = 'cases'" class="filter-tags-cases">{{ getTranslate('linkCases') }}</button>
          <button v-on:click="show = 'materials'" class="filter-tags-materials" v-if="lang === 'ru'">
            Материалы
          </button>
          <button v-on:click="show = 'materials'" class="filter-tags-materials" v-else-if="lang === 'en'">
            Materials
          </button>
        </div>
        <div class="blog-grid">
          <router-link v-if="show==='news' || show==='all'" v-for="e in news" :key="e.id" :to="{name: 'News', params: {id: e.id,},}" class="blog-grid-item">
            <div class="blog-grid-item-image" :style="{background: `url('${e.generic.photo}') 50% 50%`, backgroundSize: 'cover',}">
              <div class="blog-grid-item-tag-news">
                {{getTranslate('linkNews')}}
              </div>
            </div>
            <div class="blog-grid-item-desc">
              <div class="blog-grid-item-desc-title">
                {{getTranslateFromItem(e.translation, 'title')}}
              </div>
              <div class="blog-grid-item-desc-text">
                {{getTranslateFromItem(e.translation, 'annotation')}}
              </div>
              <div class="blog-grid-item-desc-spacer"></div>
              <div class="blog-grid-item-desc-more" v-if="lang === 'ru'">
                Подробнее
              </div>
              <div class="blog-grid-item-desc-more" v-else-if="lang === 'en'">
                Read more
              </div>
              <div class="blog-grid-item-desc-date">
                {{e.date}}
              </div>
            </div>
          </router-link>
          <router-link v-if="show==='cases' || show==='all'" v-for="e in cases" :key="e.id" :to="{name: 'Case', params: {id: e.id,},}" class="blog-grid-item">
            <div class="blog-grid-item-image" :style="{background: `url('${e.generic.blog_photo}') 50% 50%`, backgroundSize: 'cover',}">
              <div class="blog-grid-item-tag-cases">
                {{getTranslate('linkCases')}}
              </div>
            </div>
            <div class="blog-grid-item-desc">
              <div class="blog-grid-item-desc-title">
                {{getTranslateFromItem(e.translation, 'title')}}
              </div>
              <div class="blog-grid-item-desc-text">
                {{getTranslateFromItem(e.translation, 'annotation')}}
              </div>
              <div class="blog-grid-item-desc-spacer"></div>
              <div class="blog-grid-item-desc-more" v-if="lang === 'ru'">
                Подробнее
              </div>
              <div class="blog-grid-item-desc-more" v-else-if="lang === 'en'">
                Read more
              </div>
              <div class="blog-grid-item-desc-date">
                {{e.date}}
              </div>
            </div>
          </router-link>
          <!--
            Uncomment the following section once the material part is ready to show.
            Remember to keep the names as stated below (name: 'Material').
          -->
          <!--<router-link v-if="show==='materials' || show==='all'" v-for="e in materials" :key="e.id" :to="{name: 'Material', params: {id: e.id,},}" class="blog-grid-item">
            <div class="blog-grid-item-image" :style="{background: `url('${e.generic.photo}') 50% 50%`, backgroundSize: 'cover',}">
              <div class="blog-grid-item-tag-materials" v-if="lang==='ru'">
                Материалы
              </div>
              <div class="blog-grid-item-tag-materials" v-else-if="lang==='en'">
                Materials
              </div>
            </div>
            <div class="blog-grid-item-desc">
              <div class="blog-grid-item-desc-title">
                {{getTranslateFromItem(e.translation, 'title')}}
              </div>
              <div class="blog-grid-item-desc-text">
                {{getTranslateFromItem(e.translation, 'annotation')}}
              </div>
              <div class="blog-grid-item-desc-spacer"></div>
              <div class="blog-grid-item-desc-more" v-if="lang === 'ru'">
                Подробнее
              </div>
              <div class="blog-grid-item-desc-more" v-else-if="lang === 'en'">
                Read more
              </div>
              <div class="blog-grid-item-desc-date">
                {{e.date}}
              </div>
            </div>
          </router-link>-->
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-demo">
        <router-link :to="{name: 'Demo',}">
          <BaseButton filled>
            {{getTranslate('buttonForADemo')}}
          </BaseButton>
        </router-link>
      </div>
      <div class="bottom-description">
        {{getTranslate('underButtonPhrase')}}
      </div>
    </div>
  </div>
</template>

<script>
import {news} from "@/ext";
import {cases} from "@/ext";
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";

export default {
  name: "Blog",
  props: {
    show: {
      type: String,
      default: 'all',
      required: true,
    }
  },
  computed: {
    ...mapGetters([
      'getTranslate',
      'getTranslateFromItem',
      'lang',
      'carousels',
      'trust',
      'images',
      'integrations',
      'news',
      'cases',
      'links'
    ]),
  },
  components: {
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>
.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-demo {
    width: 210px;
    margin-top: -20px;
    margin-bottom: 10px;
  }

  &-description {
    font-weight: 500;
    width: 50%;
    align-items: center;
    font-size: 32px;
    text-align: center;
    line-height: 60px;
    padding: 30px 30px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 20px;
      line-height: 28px;

      @media (max-width: $breakpoint-tablet-v) {
        font-size: 20px;
        line-height: 28px;

        @media (max-width: $breakpoint-mobile) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}

.filter-tags {
  margin-left: 100px;

  @media (max-width: $breakpoint-desktop) {
    margin-left: 75px;

    @media (max-width: $breakpoint-tablet-v) {
      margin-left: 15px;

      @media (max-width: $breakpoint-mobile) {
        margin-left: 15px;
      }
    }
  }

  &-news {
    text-align: center;
    border: none;
    width: 15%;
    cursor: pointer;
    padding: 12px 0;
    margin-right: 30px;
    background-color: #55C0AD;
    color: white;
    font-size: 14px;
    font-weight: 450;
    box-sizing: border-box;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    @media (max-width: $breakpoint-mobile) {
      width: 25%;
      margin-right: 15px;
    }
  }

  &-cases {
    border: none;
    width: 15%;
    cursor: pointer;
    padding: 12px 0;
    margin-right: 30px;
    background-color: #5D5CA8;
    color: white;
    font-size: 14px;
    font-weight: 450;
    box-sizing: border-box;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    @media (max-width: $breakpoint-mobile) {
      width: 25%;
      margin-right: 15px;
    }
  }

  &-materials {
    border: none;
    width: 15%;
    cursor: pointer;
    padding: 12px 0;
    background-color: #1AA7BC;
    color: white;
    font-size: 14px;
    font-weight: 450;
    box-sizing: border-box;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    @media (max-width: $breakpoint-mobile) {
      width: 25%;
    }
  }
}

.blog-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &-item {
    position: relative;
    width: calc(80%/3);
    height: 530px;
    color: black;
    overflow: hidden;
    margin: 25px 15px;
    border-radius: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    box-shadow: 0px 0px 1.5px 1.5px rgb(210, 210, 210);

    @media (max-width: $breakpoint-desktop) {
      flex-direction: column;
      height: inherit;

      @media (max-width: $breakpoint-tablet-v) {
        width: 100%;
        margin-left: 0px;
        height: 300px;
        flex-direction: row;

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
          height: auto;
        }
      }
    }

    &-image {
      flex-shrink: 0;
      width: 100%;
      height: 200px;
      border-radius: 8px;
      box-sizing: border-box;
      position: relative;

      @media (max-width: $breakpoint-desktop) {
        height: 200px;
        width: 100%;

        @media (max-width: $breakpoint-tablet-v) {
          width: 40%;
          height: 100%;

          @media (max-width: $breakpoint-mobile) {
            height: 150px;
            width: 100%;
          }
        }
      }
    }

    &-tag {
      &-news {
        background-color: #55C0AD;
        color: white;
        font-size: 14px;
        font-weight: 450;
        padding: 12px 25px;
        box-sizing: border-box;
        border-top-left-radius: 20px;
        border-bottom-right-radius: inherit;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      &-cases {
        background-color: #5D5CA8;
        color: white;
        font-size: 14px;
        font-weight: 450;
        padding: 12px 25px;
        box-sizing: border-box;
        border-top-left-radius: 20px;
        border-bottom-right-radius: inherit;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      &-materials {
        background-color: #1AA7BC;
        color: white;
        font-size: 14px;
        font-weight: 450;
        padding: 12px 25px;
        box-sizing: border-box;
        border-top-left-radius: 20px;
        border-bottom-right-radius: inherit;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    &-desc {
      flex-grow: 1;
      display: flex;
      box-sizing: border-box;
      padding: 30px;
      flex-direction: column;

      &-title {
        font-weight: 900;
        font-size: 20px;
        line-height: 28px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 20px;
          line-height: 28px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 20px;
            line-height: 28px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
      }

      &-text {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 10px;
      }

      &-button {
        align-self: flex-end;
      }

      &-spacer {
        flex-grow: 1;
      }

      &-date {
        align-self: flex-end;
        font-weight: 500;
        font-size: 16px;
        margin-top: 15px;
        color: rgba(0,0,0,0.5);
        line-height: 16px;
      }

      &-more {
        align-self: center;
        font-weight: 500;
        font-size: 16px;
        position: absolute;
        bottom: 0;
        margin-bottom: 40px;
        color: rgba(127,126,186,255);

        @media (max-width: $breakpoint-desktop) {
          padding-top: 20px;
          margin-bottom: -15px;
          position: sticky;

          @media (max-width: $breakpoint-tablet-v) {
            margin-bottom: 15px;
            position: absolute;

            @media (max-width: $breakpoint-mobile) {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
}
</style>
