<template>
  <div id="webinar">
    <div class="demo-main-photo" :style="{background: `url('${webinarImage}') 50% 50%`, backgroundSize: 'cover',}">
      <div class="demo-main-photo-title">
        {{ getTranslateFromItem(main.translation, 'title') }}
        <div class="demo-main-photo-title-date">
          {{ getTranslateFromItem(main.translation, 'date') }}
        </div>
      </div>
      <div class="page-section-reg-wrapper">
        <a href="#regSection">
          <BaseButton filled>Register</BaseButton>
        </a>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslateFromItem(description.translation, 'title') }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0px',
            whiteSpace: 'pre-wrap',
          }"
        >
          <div v-html="descriptionBefore"></div>
          <ul>
            <li v-for="e in description.list">{{ getTranslateFromItem(e.translate, 'text') }}</li>
          </ul>
          <div>{{ getTranslateFromItem(description.translation, 'after') }}</div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslateFromItem(program.translation, 'title') }}</div>
        <div v-for="e in program.days" class="programme-item">
          <div class="programme-item-day">{{ getTranslateFromItem(e.translation, 'title') }}</div>
          <div class="programme-item-content">
            <ol
                type="1"
            >
              <li
                v-for="(item, i) in e.programme"
                class="programme-item-content-item"
              >
                <div class="programme-item-content-item-title">{{ getTranslateFromItem(item.translation, 'title') }}</div>
                <div class="programme-item-content-item-speaker" v-if="item.general.speaker">{{ getTranslateFromItem(item.translation, 'speaker') }}</div>
                <div class="programme-item-content-item-modules">{{ getTranslateFromItem(item.translation, 'modules') }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="e in targets"
        class="page-section-wrapper marketing-section-analytics-wrapper"
        :class="{
          'marketing-section-analytics-wrapper__reverse': e.general.reverse,
        }"
    >
      <div
          class="page-section-inner marketing-section-analytics"
      >
        <div class="marketing-section-analytics-image">
          <img :src="e.general.photo" alt="">
        </div>
        <div class="marketing-section-analytics-info">
          <div class="marketing-section-analytics-info-title">
            {{ getTranslateFromItem(e.translation, 'title') }}
          </div>
          <div class="marketing-section-analytics-info-description" v-html="getTranslateFromItem(e.translation, 'description')"></div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarSpeakersTitle') }}</div>
        <CarouselWebinar :speakers="speakers"></CarouselWebinar>
      </div>
    </div>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('webinarPartnersTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item" v-for="e in partners">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
      </div>
    </div>
    <div id="regSection" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{"Register"/* getTranslate('webinarFormTitle') */}}</div>
        <div id="hubspotForm" ref="hubspot-form1"></div>
      </div>
    </div>
  </div>
</template>

<script>

import webinarImage from '@/assets/images/webinar/webinar.jpg';
import seoImage from '@/assets/images/webinar/feb22.png';
import speakerBitkashImage from '@/assets/images/speakers/bitkash.png';
import speakerNikolaosImage from '@/assets/images/speakers/nikolaos.png';
import speakerOtochkinaImage from '@/assets/images/speakers/otochkina.png';
import speakerAnatoliyImage from '@/assets/images/speakers/anatoliy2.jpg';
import speakerMikeladzeImage from '@/assets/images/speakers/mikeladze.png';
import travellineImage from '@/assets/images/partners/travelline.png';
import otaImage from '@/assets/images/partners/ota.png';
import hrsImage from '@/assets/images/partners/hrs.png';
import horekaImage from '@/assets/images/partners/horeka.png';
import hlImage from '@/assets/images/partners/hl.png';
import dgstayImage from '@/assets/images/partners/dgstay.png';
import {mapGetters} from "vuex";
import CarouselWebinar from "@/components/CarouselWebinar";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Webinar",
  computed: {
    ...mapGetters([
        'getTranslate',
        'getTranslateFromItem',
        'lang',
        'integrations',
    ]),
    descriptionBefore() {
      return this.getTranslateFromItem(this.description.translation, 'before');
    },
  },
  watch: {
    lang() {
      this.appendForm();
    },
  },
  mounted() {
    this.appendForm();
  },
  methods: {
    appendForm() {
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "461d2b97-646e-49e0-8970-e548a4f78044",
            target: '#hubspotForm',
          });
        }
      })
    },
  },
  components: {
    CarouselWebinar,
    BaseButton
  },
  data: () => ({
    webinarImage,
    main: {
      translation: {
        ru: {
          title: 'Revenue Workshop Tbilisi',
          date: 'For 20+ rooms hotels \n The Biltmore Tbilisi Hotel | 30 March | starts at 03:00 PM'
        },
        en: {
          title: 'Revenue Workshop Tbilisi',
          date: 'For 20+ rooms hotels \n The Biltmore Tbilisi Hotel | 30 March | starts at 03:00 PM'
        },
      },
    },
    description: {
      list: [
      ],
      translation: {
        ru: {
          title: 'Workshop description',
          before: '<p>On March 30th, the team of hotellab, together with international partners such as OTA Insight and TravelLine, will gather Georgian hoteliers who want to learn techniques of the modern standards of Revenue Management. The workshop will be hosted by the finest The Biltmore Tbilisi hotel, allowing participants to enjoy networking with colleagues in a professional atmosphere.</p>' +
          '<p>What will participants learn during the workshop?</p>' +
          '<ul><li>Distribution and sales trends</li>' +
          '<li>Implications of changes in demand</li>' +
          '<li>New revenue opportunities</li>' +
          '<li>How to stay productive and proactive in period of uncertainty</li>' +
          '<li>Practical tools to help hotel grow, not to survive</li></ul>' +
          '<p>The workshop will be organized in an interactive format, allowing participants to focus on learning new techniques and trends rather than just studying speakers\' presentations. The unique part of this workshop will be step-by-step guidance on impelling dynamic pricing and yield management to create the most effective Revenue Management strategies.</p>' +
          '<p>All participants will receive a certificate of participation.</p>' +
          '<p>The event is free for hoteliers with 20 rooms or more (not more than 2 persons per hotel).</p>' +
          '<p>Organizers: hotellab.io, TravelLine, OTA Insight. With the support of HRS Hospitality & Retail Systems and Hotel And Restaurant Federation Of Georgia.</p>',
          after: ''
        },
        en: {
          title: 'Workshop description',
          before: '<p>On March 30th, the team of hotellab, together with international partners such as OTA Insight and TravelLine, will gather Georgian hoteliers who want to learn techniques of the modern standards of Revenue Management. The workshop will be hosted by the finest The Biltmore Tbilisi hotel, allowing participants to enjoy networking with colleagues in a professional atmosphere.</p>' +
          '<p>What will participants learn during the workshop?</p>' +
          '<ul><li>Distribution and sales trends</li>' +
          '<li>Implications of changes in demand</li>' +
          '<li>New revenue opportunities</li>' +
          '<li>How to stay productive and proactive in period of uncertainty</li>' +
          '<li>Practical tools to help hotel grow, not to survive</li></ul>' +
          '<p>The workshop will be organized in an interactive format, allowing participants to focus on learning new techniques and trends rather than just studying speakers\' presentations. The unique part of this workshop will be step-by-step guidance on impelling dynamic pricing and yield management to create the most effective Revenue Management strategies.</p>' +
          '<p>All participants will receive a certificate of participation.</p>' +
          '<p>The event is free for hoteliers with 20 rooms or more (not more than 2 persons per hotel).</p>' +
          '<p>Organizers: hotellab.io, TravelLine, OTA Insight. With the support of HRS Hospitality & Retail Systems and Hotel And Restaurant Federation Of Georgia.</p>',
          after: ''
        },
      },
    },
    program: {
      days: [
        {
          general: {
            id: 0,
          },
          programme: [
            {
              general: {
                id: 0,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '3:00 - 3:30 pm: Welcome coffee',
                  speaker: '',
                  modules: 'Great opportunity to come earlier and meet fellow hoteliers and discuss current trends'
                },
                en: {
                  title: '3:00 - 3:30 pm: Welcome coffee',
                  speaker: '',
                  modules: 'Great opportunity to come earlier and meet fellow hoteliers and discuss current trends'
                },
              },
            },
            {
              general: {
                id: 1,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '3:30 - 3:45 pm: Welcome speeches',
                  speaker: '',
                  modules: 'Introduction to the workshop by organizers and partners'
                },
                en: {
                  title: '3:30 - 3:45 pm: Welcome speeches',
                  speaker: '',
                  modules: 'Introduction to the workshop by organizers and partners'
                },
              },
            },
            {
              general: {
                id: 2,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '3:45 - 5:45 pm: Workshop program',
                  speaker: '',
                  modules: 'Step-by-step guidance on implementing Dynamic pricing, Yield management, and new Revenue Management techniques. \n' +
                  'Participants will be introduced to the following topics: \n' +
                  '- Setting up productive distribution channels \n' +
                  '- Importance of choosing the right Competition set \n' +
                  '- How to implement dynamic pricing, with having different guests segments (business mix) \n' +
                  '- How to predict demand \n' +
                  '- Key metrics to measure your hotel performance \n' +
                  '- How to work with guests\' loyalty \n' +
                  '- Ways to increase revenue from additional services'
                },
                en: {
                  title: '3:45 - 5:45 pm: Workshop program',
                  speaker: '',
                  modules: 'Step-by-step guidance on implementing Dynamic pricing, Yield management, and new Revenue Management techniques. \n' +
                  'Participants will be introduced to the following topics: \n' +
                  '- Setting up productive distribution channels \n' +
                  '- Importance of choosing the right Competition set \n' +
                  '- How to implement dynamic pricing, with having different guests segments (business mix) \n' +
                  '- How to predict demand \n' +
                  '- Key metrics to measure your hotel performance \n' +
                  '- How to work with guests\' loyalty \n' +
                  '- Ways to increase revenue from additional services'
                },
              },
            },
            {
              general: {
                id: 3,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '5:45 - 6:00 pm: Interactive game with prizes',
                  speaker: '',
                  modules: 'Brainstorming game with prizes for the best revenue ideas.'
                },
                en: {
                  title: '5:45 - 6:00 pm: Interactive game with prizes',
                  speaker: '',
                  modules: 'Brainstorming game with prizes for the best revenue ideas.'
                },
              },
            },
            {
              general: {
                id: 5,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '6:00 - 7:00 pm: Cocktail reception',
                  speaker: '',
                  modules: 'Great opportunity, to sum up learned materials and for networking',
                },
                en: {
                  title: '6:00 - 7:00 pm: Cocktail reception',
                  speaker: '',
                  modules: 'Great opportunity, to sum up learned materials and for networking',
                },
              },
            },
          ],
          translation: {
            ru: {
              title: '',
            },
            en: {
              title: '',
            },
          },
        },
      ],
      translation: {
        ru: {
          title: 'Workshop program:',
          description: '',
        },
        en: {
          title: 'Workshop program:',
          description: '',
        },
      },
    },
    partners: [
      {
        "id": 0,
        "generic": {
          "photo": travellineImage,
          "url": "https://www.travelline.pro/",
        },
      },
      {
        "id": 1,
        "generic": {
          "photo": otaImage,
          "url": "https://www.otainsight.com/",
        },
      },
      {
        "id": 2,
        "generic": {
          "photo": hrsImage,
          "url": "https://hrs.ru/en/",
        },
      },
      {
        "id": 4,
        "generic": {
          "photo": hlImage,
          "url": "https://hotellab.io/en/",
        },
      },
      {
        "id": 5,
        "generic": {
          "photo": dgstayImage,
          "url": "https://dgstay.com/",
        },
      },
    ],
    targets: [
      {
        general: {
          id: 0,
          photo: 'https://hotellab.io/media/webinarForWho.webp',
        },
        translation: {
          ru: {
            title: 'Who should attend',
            description: '- Hotel’ Owners \n' +
            '- General Managers \n' +
            '- Directors of Sales \n' +
            '- Revenue Managers \n' +
            '- Sales & Marketing Managers \n' +
            'For those who want to be always financially successful and not depend on periods of uncertainty. \n',
          },
          en: {
            title: 'Who should attend',
            description: '- Hotel’ Owners \n' +
            '- General Managers \n' +
            '- Directors of Sales \n' +
            '- Revenue Managers \n' +
            '- Sales & Marketing Managers \n' +
            'For those who want to be always financially successful and not depend on periods of uncertainty. \n',
          },
        },
      },
      {
        general: {
          reverse: true,
          id: 1,
          photo: 'https://hotellab.io/media/webinarForWhy.jpg',
        },
        translation: {
          ru: {
            title: 'What will participants receive',
            description: '<ul><li>Understanding the current situation in the hospitality industry based on deep analytical data and detailed comparative analytics</li>' +
            '<li>IT-based tools for the sustainable operation of the hotel, which will allow you to get maximum profit in any situation</li>' +
            '<li>Opportunities to stay ahead of the competition</li>' +
            '<li>Great networking</li>' +
            '<li>Digital certificate of participation in the Revenue Workshop</li></ul>'
          },
          en: {
            title: 'What will participants receive',
            description: '<ul><li>Understanding the current situation in the hospitality industry based on deep analytical data and detailed comparative analytics</li>' +
            '<li>IT-based tools for the sustainable operation of the hotel, which will allow you to get maximum profit in any situation</li>' +
            '<li>Opportunities to stay ahead of the competition</li>' +
            '<li>Great networking</li>' +
            '<li>Digital certificate of participation in the Revenue Workshop</li></ul>'
          },
        },
      },
    ],
    speakers: [
      {
        general: {
          photo: speakerAnatoliyImage,
        },
        translation: {
          ru: {
            name: 'Anatoly Burdakov',
            description: 'Founder of hotellab.io',
          },
          en: {
            name: 'Anatoly Burdakov',
            description: 'Founder of hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerNikolaosImage,
        },
        translation: {
          ru: {
            name: 'Nikolaos Tsitsoulis',
            description: 'Senior Development Manager, OTA Insight',
          },
          en: {
            name: 'Nikolaos Tsitsoulis',
            description: 'Senior Development Manager, OTA Insight',
          },
        },
      },
      {
        general: {
          photo: speakerBitkashImage,
        },
        translation: {
          ru: {
            name: 'Nina Bitkash',
            description: 'Regional Managing Director, TravelLine',
          },
          en: {
            name: 'Nina Bitkash',
            description: 'Regional Managing Director, TravelLine',
          },
        },
      },
      {
        general: {
          photo: speakerMikeladzeImage,
        },
        translation: {
          ru: {
            name: 'Nunu Mikeladze',
            description: 'Executive Assistant Manager, The Biltmore Hotel Tbilisi',
          },
          en: {
            name: 'Nunu Mikeladze',
            description: 'Executive Assistant Manager, The Biltmore Hotel Tbilisi',
          },
        },
      },
      {
        general: {
          photo: speakerOtochkinaImage,
        },
        translation: {
          ru: {
            name: 'Otochkina Sofya',
            description: 'Account Executive, DGstay',
          },
          en: {
            name: 'Otochkina Sofya',
            description: 'Account Executive, DGstay',
          },
        },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
@media (max-width: $breakpoint-mobile) {
  .marketing-section-analytics-info-description {
    text-align: left;
  }
}

.page-section-wrapper.pt-0 {
  padding-top: 0;
}

.page-section-reg-wrapper {
  padding: 30px;
}

.page-section-reg-wrapper span {
  margin-right: 20px;
}

.demo-main-photo-title-date {
  white-space: pre-wrap;
}

.programme-item {
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  background-color: #F9FAFF;

  .page-section-reg-wrapper {
    padding: 30px;
  }

  .page-section-reg-wrapper span {
    margin-right: 20px;
  }

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }


  &-day {
    font-weight: 900;
    font-size: 30px;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    background-color: #5d5ca8;
    color: white;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      text-align: center;
      padding: 25px;
      font-size: 24px;
    }
  }

  &-content {
    white-space: pre-wrap;
    font-size: 21px;
    line-height: 30px;
    padding: 40px;
    flex-grow: 1;

    @media (max-width: $breakpoint-tablet) {
      padding: 25px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }

    ol {
      margin: 0;
    }

    &-item {
      margin-bottom: 20px;

      &-title {
        font-weight: 900;
      }

      &-speaker {
        font-size: 0.9em;
        font-style: italic;
        opacity: .7;
      }

      &-modules {
        margin-top: 10px;
      }

      &::marker {
        font-weight: 900;
      }
    }
  }
}
.demo-main-photo {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(50vh - 80px);
    }
  }

  &-title {
    font-size: 60px;
    line-height: 72px;
    max-width: 90%;
    width: 1000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    color: white;
    padding: 20px 70px;
    background-color: #5d5ca8;

    &-date {
      font-size: 30px;
      line-height: 36px;
      margin-top: 10px;


      @media (max-width: $breakpoint-desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 10px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 22px;
            line-height: 28px;
            margin-top: 5px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
      }
    }


    @media (max-width: $breakpoint-desktop) {
      font-size: 50px;
      line-height: 60px;
      max-width: 700px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 36px;
        line-height: 44px;
        padding: 20px 40px;
        max-width: 500px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 28px;
          line-height: 34px;
          width: 90%;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.webinar-target {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column !important;
  }

  &-title {
    width: 50%;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 900;
    font-size: 60px;
    text-align: left;
    display: flex;
    justify-content: flex-start;

    &-inner {
      background-color: #5d5ca8;
      color: white;
      padding: 10px 30px;
    }
  }

  &-description {
    width: 50%;
    font-size: 25px;
    box-sizing: border-box;
    padding: 40px;
    text-align: right;
    line-height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
  }
}

.webinar-target__active {
  flex-direction: row;

  .webinar-target-title {
    text-align: right;
    justify-content: flex-end;
  }

  .webinar-target-description {
    text-align: left;
  }
}

.marketing-section-analytics-wrapper__reverse {
  background-color: transparent !important;
  .marketing-section-analytics {
    flex-direction: row-reverse;
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }

    &-title {
    }
  }
}

.marketing-section-analytics-wrapper {
  background-color: #F9FAFF;

  .marketing-section-analytics {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: column;
    }

    &-image {
      width: 50%;
      box-sizing: border-box;
      padding-right: 32px;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 20px;
      }

      @media (max-width: $breakpoint-desktop) {
        padding-right: 24px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            width: 70%;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }

    &-info {
      flex: 1;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      flex-shrink: 0;
      font-weight: 900;
      font-size: 40px;
      padding-left: 32px;
      line-height: 52px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        font-size: 34px;
        line-height: 40px;
        padding-left: 24px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 36px;
          padding-left: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;
          margin-bottom: 12px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }

      &-description {
        margin-bottom: 24px;
        font-size: 21px;
        line-height: 30px;
        font-weight: 400;
        width: 80%;
        white-space: pre-wrap;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }
  }
}
</style>
