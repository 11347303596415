<template>
  <div
    class="header-link-wrapper"
    @mouseover="showDropdown = true"
    @mouseleave="showDropdown = false"
  >
    <div class="header-link-inner">
      <router-link
          class="header-link"
          :to="to"
      >
        <slot></slot>
        <img
            v-show="sublinks && sublinks.length > 0"
            src="@/assets/chevron-down.svg"
            class="header-link-chevron"
            alt=""
        >
      </router-link>
      <transition
          v-if="sublinks && sublinks.length > 0"
          name="fade"
      >
        <div
            class="header-link-dropdown-wrapper"
            v-show="showDropdown"
        >
          <div class="header-link-dropdown">
            <template v-for="e in sublinks">
              <router-link
                  v-if="getTranslate(e.translateId) !== '{{hidden}}'"
                  class="header-link-dropdown-item"
                  :to="e.to"
                  :key="e.text"
              >
                {{getTranslate(e.translateId)}}
              </router-link>
            </template>
          </div>

        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HeaderLink",
  computed: {
    ...mapGetters([
      'getTranslate',
    ]),
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
    colored: {
      type: Boolean,
      default: false,
    },
    sublinks: Array,
  },
  data: () => ({
    showDropdown: false,
  }),
}
</script>

<style lang="scss" scoped>
.header-link-wrapper {
  position: relative;

  .header-link-inner {
    .header-link-dropdown-wrapper {
      position: absolute;
      left: 0;
      padding-top: 10px;
      min-width: 100%;

      .header-link-dropdown {
        box-shadow: $shadow-default;
        position: absolute;
        background-color: white;
        min-width: 100%;
        width: max-content;
        padding: 10px 13px;
        box-sizing: border-box;

        .header-link-dropdown-item {
          display: block;
          font-size: 13px;
          font-weight: 400;
          line-height: 26px;
          color: black;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .header-link {
    color: black;
    padding: 4px 24px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;

    @media (max-width: $breakpoint-desktop) {
      padding: 4px 18px;
    }

    @media (max-width: $breakpoint-tablet) {
      padding: 4px 12px;
    }

    .header-link-chevron {
      margin-left: 8px;
      width: 12px;
    }

    &:hover {
      color: $primary;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
</style>